import { SET_GROUP, SET_GROUPS } from "../actions/types"

const INITIAL_STATE = {
    data: null,
    group: null
}

const groups_reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_GROUPS:
            return {
                ...state,
                data: action.data
            }
        case SET_GROUP:
            return {
                ...state,
                group: action.group
            }
        default:
            return state;
    }
}

export default groups_reducer;