import React, { useEffect, useState } from "react";
import Appbar from "./Appbar";
import { ReactComponent as UnBlockIcon } from '../../assets/images/unlock.svg';
import { toast } from "react-toastify";
import axios from "axios";
import { Link } from "react-router-dom";

const AdminGroups = () => {

    const [searchTxt, setSearchTxt] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [bannedUsers, setBannedUsers] = useState(null);

    const handleUnBlock = (id) => {
        const anwser = window.confirm('Are you sure ? This will unblock the user');
        if (anwser) {
            axios.delete(`/admin/ban/${id}`).then(_res => {
                const blockUsersArr = [...bannedUsers];
                const userIndex = blockUsersArr.findIndex((user) => user.id == id);
                if (userIndex !== -1) {
                    blockUsersArr.splice(userIndex, 1);
                    setBannedUsers([...blockUsersArr]);
                }
                toast.success('unblocked');
            }).catch(error => {
                if (error) {
                    if (error.response.status == 400) {
                        toast.warn('This user is already unblocked');
                    }
                } else {
                    toast.warn('User credentials error!');
                }
            });
        }
    }

    useEffect(() => {
        axios.get('/admin/ban').then(res => {
            setBannedUsers(res.data.users);
        })
    }, []);

    useEffect(() => {
        axios.get(`/admin/ban/${searchTxt}`).then(res => {
            setSearchResults(res.data.users);
        });
    }, [searchTxt]);

    return (
        <div className="admin-area">
            <Appbar />
            <div className="admin-groups">
                <form>
                    <div className="top">
                        <h4>Blocked users</h4>
                    </div>
                    <div className="users">
                        <div className="search">
                            <input onChange={(e) => setSearchTxt(e.target.value)} type="text" placeholder="Search users" />
                        </div>
                        <ul>
                            {bannedUsers !== null ? (
                                <>
                                    {searchResults && searchResults.length <= 0 && searchTxt.length > 0 ? (
                                        <h5 style={{ width: '100%', textAlign: 'center', marginTop: '10px', color: '#555' }}></h5>
                                    ) : (
                                        <>
                                            {searchResults && (searchResults.length > 0 ? searchResults : bannedUsers).map((user, key) => (
                                                <li key={key}>
                                                    <section>
                                                        <div className="img" style={{ backgroundImage: `url(${user.image})`, borderRadius: '50%' }}></div>
                                                        <h5 style={{ cursor: 'pointer' }}>
                                                            <Link style={{ color: '#444', textDecorationLine: 'none' }} to={`/groups/${user.id}`}>{user.username}</Link>
                                                        </h5>
                                                    </section>
                                                    <acronym title="unblock user">
                                                        <UnBlockIcon onClick={() => handleUnBlock(user.id)} />
                                                    </acronym>
                                                </li>
                                            ))}
                                        </>
                                    )}
                                </>
                            ) : (
                                <h4>Loading...</h4>
                            )}
                        </ul>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default AdminGroups;