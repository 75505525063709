import axios from "axios";
import { SET_NOTIFICATION } from "./types";
import { socket } from '../../App';

export const set_notification = (data) => ({
    type: SET_NOTIFICATION,
    data: data
});


export const send_notification = (socket, data) => {
    socket.emit('send-notification', data);
}

export const get_notifications = ()  => (dispatch) => {
    axios.get('/user/notifications').then(res => {
        dispatch(set_notification(res.data.notifi));
    });
}

export const read_notification = () => (dispatch) => {
    axios.get('/user/notifications/read').then(res => {
        dispatch(get_notifications());
    });
}

export const send_post_notifi = (data) => (dispatch) => {
    socket.emit('send-post-notifi', data);
}
