import React, { useEffect, useReducer, useRef, useState } from "react";
import CommentIcon from '../../assets/images/chat.png';
import ShareIcon from '../../assets/images/share.svg';
import { Link, useNavigate } from "react-router-dom";
import FaceImage from '../../assets/images/face.svg';
import SupportIcon from '../../assets/images/support.png';
import SupportBWIcon from '../../assets/images/support-nocolor.png';
import MenuDots from '../../assets/images/menu-dots.svg';
import TrashIcon from '../../assets/images/trash.svg';
import LinkIcon from '../../assets/images/link.svg';
import ReportIcon from '../../assets/images/remove-user.svg';
import { connect } from "react-redux";
import { add_comment, add_remove_support, add_reply, delete_post, remove_comment, remove_reply } from "../../store/actions/post";
import { toast } from "react-toastify";
import { format } from "timeago.js";
import { get_group_posts } from "../../store/actions/group";
import { socket } from "../../App";
import { get_profile, get_user } from "../../store/actions/users";
import Tag_people from "./tag_people";
import AddFriendIcon from '../../assets/images/add-friend.svg';
import ReportUser from "../ReportUser";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import AnonymousProfile from '../../assets/images/anonymous.svg';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import NewUpdates from "./NewUpdates";
import Content from "./Content";
import TextareaAutosize from 'react-textarea-autosize';
import axios from "axios";
import { v4 as UUID } from "uuid";
import Replies from "./Replies";
import { Spinner } from "loading-animations-react";
import ShareDialog from "./shareDialog";
import { filesUrl } from "../../App";

const Post = (props) => {

    const [open, setOpen] = useState(null);
    const [supported, setSupported] = useState(false);
    const [openmenu, setOpenmenu] = useState(false);
    const [comMenu, setComMenu] = useState(null);
    const [comment, setComment] = useState('');
    const [supports, setSupports] = useState(0);
    const [reply, setReply] = useState('');
    const [comments, setComments] = useState([]);
    const [comPublic, setComPublic] = useState(true);
    const [showPeople, setShowPeople] = useState({});
    const [animateIcon, setAnimateIcon] = useState(false);
    const [replyState, setReplyState] = useState(false);
    const [openReplyMenu, setOpenReplyMenu] = useState(null);
    const [openReportUser, setOpenReportUser] = useState(null);
    const [openTagMenu, setOpenTagMenu] = useState(false);
    const [openEmoji, setOpenEmoji] = useState(null);
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const [requestPendng, setRequestPendng] = useState(false);
    const [haveUpdates, setHaveUpdates] = useState(true);
    const [havePostId, setHavePostId] = useState(null);
    const [publicReply, setPublicReply] = useState(true);
    const [commentsLength, setCommentLength] = useState(0);
    const [openComReport, setOpenComReport] = useState(null);
    const [openReplyReport, setOpenReplyReport] = useState(null);
    const [alreadyFriend, setAlreadyFriends] = useState(null);
    const [openShare, setOpenShare] = useState(null);
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [update, setUpdate] = useState(false);
    const [reportUser, setReportUser] = useState(null);
    const [reportUserData, setReportuserData] = useState(null);
    const tagMenuRef = useRef();
    const menuRef = useRef();
    const comMenuRef = useRef();
    const replyMenuRef = useRef();
    const emojiRef = useRef();

    const navigate = useNavigate();

    const handleOpen = (id) => {
        if (open) setOpen(null);
        else setOpen(id);
    }

    const handleSupport = () => {
        if (props.user && props.user.id) {
            axios.put(`/posts/support/${props.post.id}`, { from_username: props.user.username, to_id: props.post.user.id }).then(res => {
                socket.emit('send-support', { post_id: props.post.id, user_id: props.user.id, notification: res.data.notification, added: supported ? false : true });
            }).catch(_error => {
                toast.warn('Request timed out!');
            });
        }
        if (supported) {
            setSupported(false);
            setSupports(supports - 1);
        }
        else {
            setSupported(true);
            setSupports(supports + 1);
        }
        setAnimateIcon(true);
        setTimeout(() => {
            setAnimateIcon(false);
        }, [200]);
    }

    useEffect(() => {
        socket.on('revice-support', (data) => {
            if (props.user && props.user.id) {
                if (data.post_id == props.post.id && data.user_id !== props.user.id) {
                    if (data.added && !supported) {
                        setSupports(prev => prev + 1);
                    } else {
                        setSupports(prev => prev - 1);
                    }
                }
            } else {
                if (props.user && props.user.id) {
                    if (data.post_id == props.post.id) {
                        if (data.added && !supported) {
                            setSupports(prev => prev + 1);
                        } else {
                            setSupports(prev => prev - 1);
                        }
                    }
                }
            }
        });
    }, []);


    const handleReplyMenu = (id) => {
        if (openReplyMenu) setOpenReplyMenu(null);
        else {
            setComMenu(null);
            setOpenmenu(false);
            setOpenReplyMenu(id);
        }
    }

    useEffect(() => {
        if (props.post) {
            setSupports(props.post.supports);
            setSupported(props.post.supported);
        }
    }, [props.post && props.post.supports]);

    const onEmojiClick = (_event, emojiObject) => {
        setChosenEmoji(emojiObject);
        setComment(comment + emojiObject.native)
    }

    const handleMenu = () => {
        if (openmenu) setOpenmenu(false);
        else {
            setComMenu(false);
            setOpenmenu(true);
        }
    }

    const handleComMenu = (id) => {
        if (comMenu) setComMenu(null);
        else {
            setOpenmenu(false);
            setComMenu(id);
        }
    }

    const line_none = {
        textDecorationLine: 'none',
        color: 'black',
        display: 'flex',
        alignItems: 'center',
        cursor: 'default'
    }

    const handleComment = () => {
        if (props.user && props.user.id && comment) {
            const tmpId = UUID();
            const comarr1 = [{ id: tmpId, comment, public: comPublic, user: { username: props.user.username, id: props.user.id, image: props.user.image }, posting: true, replies: [] }, ...props.post.comments];
            setComments([...comarr1]);
            setComment('');
            setComPublic(true);
            axios.post(`/posts/comment/${props.post.id}`, { comment, public: comPublic, postPublic: props.post.public, from_username: props.user.username, toId: props.post.user.id, postUsername: props.post.user.username }).then(res => {
                const data = res.data.comment;
                if (data.post_id == props.post.id) {
                    const comarr = [data, ...props.post.comments];
                    const tmpComIndex = comarr.findIndex((com) => com.id == tmpId);
                    if (tmpComIndex !== -1) comarr.splice(tmpComIndex, 1);
                    const comIndex = props.post.comments.findIndex((com) => com.id == data.id);
                    if (comIndex == -1) {
                        setComments([...comarr]);
                        props.post.comments.unshift(data)
                        setCommentLength(commentsLength + 1);
                    }
                    socket.emit('add-comment', { comment: res.data.comment, notification: res.data.notification })
                }
            }).catch(_error => {
                toast.warn('Request timed out!');
            });
        }
    }

    socket.on('recive-comment', (data) => {
        if ((data.post_id == props.post.id) && (props.user.id !== data.user.id)) {
            const comexits = props.post.comments.find((com) => com.id == data.id);
            if (!comexits) {
                const comarr = [data, ...props.post.comments];
                setComments([...comarr]);
                props.post.comments.unshift(data)
            }
        }
    });

    useEffect(() => {

        socket.on('recive-friend-notifi', (_) => {
            props.get_user();
            setRequestPendng(false)
        });

    }, []);

    socket.on('recive-reply', (data) => {
        if ((data.post_id == props.post.id) && (props.user.id !== data.user.id)) {
            const newComArr = [...comments];
            const comexits = newComArr.findIndex((com) => com.id == data.comment_id);
            if (newComArr[comexits]) {
                const replyExits = newComArr[comexits].replies.find((repl) => repl.id == data.id);
                if (!replyExits) {
                    newComArr[comexits].replies.unshift(data);
                    setComments([...newComArr]);
                }
            }
        }
    });

    const handleReply = (comment_id, comment_user, comment_public) => {
        if (props.user && props.user.id) {
            if (reply) {
                handleOpen(null);
                axios.post(`/posts/reply/${comment_id}`, { reply: reply, toId: comment_user.id, postPublic: props.post.public, postId: props.post.id, commentId: comment_id, public: publicReply, from_username: props.user.username, commentPublic: comment_public, commentUsername: comment_user.username }).then(res => {
                    const newComArr = [...comments];
                    const comIndex = newComArr.findIndex((com) => com.id == res.data.reply.comment_id);
                    if (comIndex !== -1) {
                        // newComArr[comIndex].replies_length++
                        // newComArr[comIndex].replies.unshift(res.data.reply);
                        newComArr[comIndex].replies = [res.data.reply, ...newComArr[comIndex].replies];
                        setComments([...newComArr]);
                    }
                    socket.emit('add-reply', res.data);
                    setPublicReply(true);
                    setReply('');
                    forceUpdate();
                    setUpdate(!update);
                }).catch(_error => {
                    toast.warn('Request timed out!');
                });
            }
        }
    }

    const handleRemoveComment = (comment_id) => {
        props.remove_comment({ commentId: comment_id });
        const comment_index = comments.findIndex((ele) => ele.id == comment_id);
        props.post.comments.splice(comment_index, 1);
        comments.splice(comment_index, 1);
        handleComMenu(comment_id);
        setCommentLength(commentsLength - 1);
    }

    const handleRemoveReply = (comment_id, reply_id) => {
        const commentIndex = comments.findIndex((element) => element.id == comment_id);
        if (commentIndex !== -1) {
            const replyIndex = comments[commentIndex].replies.findIndex((repl) => repl.id == reply_id);
            if (replyIndex !== -1) {
                comments[commentIndex].replies.splice(replyIndex, 1);
                props.post.comments[commentIndex].replies.splice(replyIndex, 1);
                setReplyState(!replyState);
                setReply('');
                props.remove_reply({ id: reply_id });
            }
        }
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) setOpenmenu(false);
            if (comMenuRef.current && !comMenuRef.current.contains(event.target)) setComMenu(null);
            if (replyMenuRef.current && !replyMenuRef.current.contains(event.target)) setOpenReplyMenu(null);
            if (tagMenuRef.current && !tagMenuRef.current.contains(event.target)) setOpenTagMenu(false);
            if (emojiRef.current && !emojiRef.current.contains(event.target)) setOpenEmoji(null);
        }

        document.body.addEventListener('mousedown', handleClickOutside);
        return () => document.body.removeEventListener('mousedown', handleClickOutside);
    }, []);

    useEffect(() => {
        setAlreadyFriends(null);
        setRequestPendng(false);
        setComments([]);
        if (props.post) setComments([...props.post.comments]);

        if (props.post.comments_length) {
            setCommentLength(props.post.comments_length);
        } else setCommentLength(0);
    }, [props.post]);

    const handlePostUrlCopy = (post_id) => {
        if ('clipboard' in navigator) {
            navigator.clipboard.writeText(`${window.location.origin}/post/${post_id}`);
        } else {
            document.execCommand('copy', true, `${window.location.origin}/post/${post_id}`)
        }
        toast.success('Link coppied');
    }

    const sharePost = () => {
        if (props.post && props.post.id) {
            // if (navigator.share) {
            const domain = new URL(document.URL);
            navigator.share({ url: `https://social.happyfacess.com/post/${props.post.id}`, title: 'Share', text: '' });
            // } else setOpenShare(props.post.id);
        }
    }

    const getMoreReplies = (replies, com_id) => {
        const newComArr = [...comments]
        const comIndex = comments.findIndex(com => com.id == com_id);
        if (comIndex !== -1) {
            newComArr[comIndex].replies = [...newComArr[comIndex].replies, ...replies];
            setComments([...newComArr]);
            forceUpdate();
            setUpdate(!update)
        }
    }

    const handleGoToChat = async (reply_user_id, reply) => {
        axios.get(`/chat/fri/${reply_user_id}`).then(res => {
            if (res.data.chat) {
                navigate(`/chat/${res.data.chat}?reply_urery=${reply.substr(0, 150)}&post_url=${`/post/${props.post.id}`}`);
            }
        })
    }

    useEffect(() => {
        window.addEventListener('resize', (_event) => {
            setPageWidth(window.innerWidth);
        });
    }, []);

    const handleAlreadyFriend = (id) => {
        if (props.user.id !== id) {
            setAlreadyFriends(null);
            axios.get(`/user/friends/${id}`).then(res => {
                if (res.data.friend) setAlreadyFriends(true);
                else if (res.data.pending) {
                    setAlreadyFriends(false);
                    setRequestPendng(true);
                }
                else setAlreadyFriends(false);
            }).catch(_error => {
                setAlreadyFriends(false);
            });
        } else {
            setAlreadyFriends(false);
            setRequestPendng(false);
        }
    }

    const add_friend_request = () => {
        if (props.post && props.user && props.user.id) {
            if (props.user) {
                axios.post(`/user/request/${props.post.user.id}`, { from_username: props.user.username }).then(res => {
                    setRequestPendng(true);
                    if (res.data.notification) {
                        socket.emit('friend-request-notification', res.data.notification);
                    }
                });
            }
        }
    }

    return (
        <div style={{ width: '100%' }}>
            {props.post && (
                <>
                    {openShare == props.post.id && <ShareDialog handleClose={() => setOpenShare(null)} postId={props.post.id} />}
                    {havePostId == props.post.id && (
                        <NewUpdates haveUpdates={haveUpdates} setHaveUpdates={(state) => setHaveUpdates(state)} />
                    )}
                    {reportUser == props.post.id && (
                        <ReportUser postUser={reportUserData} handleClose={() => setReportUser(null)} />
                    )}
                    <div className="post">
                        {props.post && props.post.tag_people > 0 && (
                            <>
                                {showPeople == props.post.id && (
                                    <Tag_people post_id={props.post.id} people={props.post.tag_people} open={showPeople} handleOpen={(id) => setShowPeople(id)} />
                                )}
                            </>
                        )}
                        <div className="head">
                            {props.post.public ? (
                                <section style={{ display: 'flex', alignItems: 'flex-start' }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Link style={{ textDecoration: 'none', display: 'flex', alignItems: 'center' }} to={props.post && `/user/${props.post.user.id}`}>
                                            <div className="img">
                                                <section style={{ backgroundImage: `url(${props.post && (props.post.public ? (props.post.user.image ? props.post.user.image : filesUrl + '/static/user/default.png') : filesUrl + '/static/user/default.png')})` }}></section>
                                            </div>
                                        </Link>
                                        <div className="text">
                                            <div style={{ width: 'fit-content', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '15px', marginBottom: '5px' }}>
                                                <h3 style={{ display: 'flex', alignItems: 'center' }}><Link style={{ textDecorationLine: 'none', color: '#444', "&:hover": { textDecorationLine: 'underline' }, width: 'fit-content' }} to={`/user/${props.post && props.post.user.id}`}>{props.post && (pageWidth > 350 ? props.post.user.name : (props.post.user.name.length > 7 ? `${props.post.user.name.substr(0, 7)}...` : props.post.user.name))}</Link> {props.post.tag_people > 0 && (
                                                    <>
                                                        <p style={{ marginLeft: '8px', marginRight: '8px' }} onClick={() => setShowPeople(props.post.id)}>is with</p> <strong className="underline">{props.post.tag_people}</strong><p onClick={() => setShowPeople(props.post.id)} style={{ marginLeft: '8px' }}>{pageWidth > 400 ? 'people' : '...'}</p>
                                                    </>
                                                )}
                                                </h3>
                                            </div>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div>
                                                    <h5 style={{ color: 'rgba(0,0,0,0.5)', fontSize: '0.8rem' }}>@{props.post.user.username.length > 15 ? props.post.user.username.substr(0, 15) : props.post.user.username}</h5>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        {!props.group && props.post.groupName && (
                                                            <Link style={{ textDecorationLine: 'none', color: '#444' }} to={props.post.groupId ? `/groups/${props.post.groupId}` : '#'}>
                                                                <p style={{ marginRight: '10px', color: '#444' }} className="underline-hover"><strong>{props.post.groupName && props.post.groupName.length > 20 ? `${props.post.groupName.substr(0, 20)}...` : props.post.groupName} .</strong></p>
                                                            </Link>
                                                        )}
                                                        <p>{format(props.post && props.post.created_at)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            ) : (
                                <section style={{ cursor: 'default' }}>
                                    <div style={{ cursor: 'default' }} className="img">
                                        <section style={{ backgroundImage: `url(${AnonymousProfile})` }}></section>
                                    </div>
                                    <div style={{ cursor: 'default' }} className="text">
                                        <h3 style={{ textDecorationLine: 'none' }}>Anonymous</h3>
                                        <p>{format(props.post && props.post.created_at)}</p>
                                    </div>
                                </section>
                            )}
                            <div className="menu-area">
                                <img onClick={() => {
                                    handleMenu()
                                    handleAlreadyFriend(props.post.user.id)
                                }} className="icon" src={MenuDots} alt="menu" />
                                <div ref={menuRef} className={openmenu ? 'menu show-menu' : 'menu'}>
                                    {alreadyFriend == null ? (
                                        <div className="center3">
                                            <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                                        </div>
                                    ) : (
                                        <section>
                                            <span onClick={() => {
                                                setOpenmenu(false);
                                                handlePostUrlCopy(props.post.id);
                                            }}>
                                                <img src={LinkIcon} alt="copy-link" />
                                                <h6>copy link</h6>
                                            </span>
                                            {props.user && (props.user.id !== (props.post && props.post.user.id)) && (
                                                <span onClick={() => {
                                                    setOpenmenu(false);
                                                    setReportUser(props.post.id)
                                                    setReportuserData(props.post.user);
                                                }}>
                                                    <img src={ReportIcon} alt="report" />
                                                    <h6>Report user</h6>
                                                </span>
                                            )}
                                            {alreadyFriend !== null && (
                                                <>
                                                    {props.user && props.user.id && (
                                                        <>
                                                            {props.user && props.post.public && alreadyFriend ? (
                                                                <></>
                                                            ) : (
                                                                <>
                                                                    {props.user && (props.user.id !== (props.post && props.post.user.id)) && props.post.public && (
                                                                        <>
                                                                            {props.user && props.post.public && requestPendng ? (
                                                                                <span>
                                                                                    <img src={AddFriendIcon} alt="add-frined" />
                                                                                    <h6>request pending</h6>
                                                                                </span>
                                                                            ) : (
                                                                                <>
                                                                                    {!alreadyFriend && (
                                                                                        <span onClick={() => add_friend_request()}>
                                                                                            <img src={AddFriendIcon} alt="add-frined" />
                                                                                            <h6>Add friend</h6>
                                                                                        </span>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </>
                                            )}
                                            {props.user && ((props.user.id == (props.post && props.post.user.id)) || props.user && props.user.is_admin) && (
                                                <span onClick={() => {
                                                    const anwser = window.confirm('Are you sure, This will remove the post');
                                                    if (anwser) {
                                                        if (props.group) {
                                                            props.remove_post({ postId: props.post.id, groupId: props.group })
                                                            props.handleRemovePost(props.post.id);
                                                        } else {
                                                            props.remove_post({ postId: props.post.id, groupId: null })
                                                            props.handleRemovePost(props.post.id);
                                                        }
                                                    }
                                                    handleMenu();
                                                }} style={{ color: '#f0112b' }}>
                                                    <img src={TrashIcon} alt="delete" />
                                                    <h6>Remove post</h6>
                                                </span>
                                            )}
                                        </section>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <Content>{props.post.content}</Content>
                            <div className="tags">
                                {props.post && props.post.tags && props.post.tags.map((tag, t_key) => (
                                    <Link key={t_key} style={line_none} to={`/tag/${tag}`}>
                                        <h5>{tag}</h5>
                                    </Link>
                                ))}
                            </div>
                        </div>
                        {props.post && props.post.image && (
                            <Link to={props.post && `/post/${props.post.id}`}>
                                <div className="post-img-div"></div>
                                <LazyLoadImage useIntersectionObserver={true} effect="blur" style={{ width: '100%' }} className="post-img" src={props.post.image} alt="post" />
                            </Link>
                        )}
                        <div className="action">
                            <section>
                                {props.user && props.user.id && props.user.active ? (
                                    <>
                                        <div className="support">
                                            {supported ? (
                                                <img style={{ marginTop: '5px' }} className={animateIcon ? "support-icon popup" : "support-icon"} onClick={handleSupport} src={SupportIcon} alt="support" />
                                            ) : (
                                                <img style={{ marginTop: '5px' }} className={animateIcon ? "support-icon popup" : "support-icon"} onClick={handleSupport} src={SupportBWIcon} alt="support" />
                                            )}
                                            <h4>support</h4>
                                        </div>
                                        {props.post && supports > 0 ? (
                                            <span>{supports}</span>
                                        ) : (
                                            <span></span>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className="support">
                                            <img style={{ marginTop: '5px', cursor: 'default' }} className={animateIcon ? "support-icon popup" : "support-icon"} src={SupportBWIcon} alt="support" />
                                            <h4>support</h4>
                                        </div>
                                        {props.post && supports > 0 ? (
                                            <span>{supports}</span>
                                        ) : (
                                            <span></span>
                                        )}
                                    </>
                                )}
                            </section>
                            <section>
                                <Link to={props.post && `/post/${props.post.id}`}>
                                    <img src={CommentIcon} alt="comment" />
                                </Link>
                                {props.post && commentsLength > 0 ? (
                                    <span>{commentsLength}</span>
                                ) : (
                                    <span></span>
                                )}
                            </section>
                            <section>
                                <img onClick={() => sharePost()} src={ShareIcon} alt="share" />
                            </section>
                        </div>
                        {props.user && props.user.id ? (
                            <>
                                {!props.user.active ? (
                                    <form onSubmit={(e) => e.preventDefault()} className="add-comment" style={{ position: 'relative' }}>
                                        <img style={{ width: '22px', filter: 'none', cursor: 'default' }} src={FaceImage} alt="emoji" />
                                        <TextareaAutosize disabled placeholder="Admin blocked you" />
                                    </form>
                                ) : (
                                    <form onSubmit={handleComment} className="add-comment" style={{ position: 'relative' }}>
                                        {openEmoji && (
                                            <div ref={emojiRef} style={{ position: 'absolute', bottom: '30px' }} >
                                                <Picker data={data} onEmojiSelect={(e) => onEmojiClick(null, e)} />
                                            </div>
                                        )}
                                        <img onClick={() => setOpenEmoji(!openEmoji)} style={{ width: '22px' }} src={FaceImage} alt="emoji" />
                                        <TextareaAutosize onChange={(e) => setComment(e.target.value)} value={comment} placeholder="Type here..." />
                                    </form>
                                )}
                            </>
                        ) : (
                            <form onSubmit={(e) => e.preventDefault()} className="add-comment" style={{ position: 'relative' }}>
                                <img style={{ width: '22px', filter: 'none', cursor: 'default' }} src={FaceImage} alt="emoji" />
                                <TextareaAutosize disabled placeholder="Please login to comment" />
                            </form>
                        )}
                        {props.user.active && (
                            <div className="comment-action">
                                {comment.length > 0 && (
                                    <>
                                        <div className="checkbox">
                                            <label htmlFor='anony'>
                                                <h5>comment Anonymously</h5>
                                            </label>
                                            <input onChange={(e) => e.target.checked ? setComPublic(false) : setComPublic(true)} id='anony' type="checkbox" />
                                        </div>
                                        <button onClick={() => handleComment()}>comment</button>
                                    </>
                                )}
                            </div>
                        )}
                        <div className="comments">
                            {props.post && comments && comments.map((com, index) => (
                                <div key={index}>
                                    {index < 1 && (
                                        <>
                                            {openComReport == com.id && (
                                                <ReportUser postUser={com.user} handleClose={() => setOpenComReport(null)} />
                                            )}
                                            <div className="user-com">
                                                {com.public ? (
                                                    <section>
                                                        <Link style={line_none} onClick={() => props.get_profile(com.user.id)} to={`/user/${com.user.id}`}>
                                                            <div className="img" style={{ backgroundImage: `url(${com.user.image ? com.user.image : filesUrl + '/static/user/default.png'})` }}></div>
                                                            <h5 className="underline-hover">&nbsp;&nbsp;&nbsp;{com.user.username}</h5>
                                                        </Link>
                                                    </section>
                                                ) : (
                                                    <section style={{ cursor: 'default' }}>
                                                        <Link style={line_none} to='#'>
                                                            <div className="img" style={{ backgroundImage: `url(${AnonymousProfile})` }}></div>
                                                            <h5>&nbsp;&nbsp;&nbsp;Anonymous</h5>
                                                        </Link>
                                                    </section>
                                                )}
                                                <div ref={comMenuRef} className="menu-area">
                                                    <img onClick={() => handleComMenu(com.id)} className="icon" src={MenuDots} alt="menu" />
                                                    <div className={comMenu == com.id ? 'menu show-menu' : 'menu'}>
                                                        <section>
                                                            {props.user && !(com.user.id == props.user.id) && (
                                                                <span onClick={() => {
                                                                    setOpenComReport(com.id);
                                                                    setReportuserData(com.user);
                                                                    setComMenu(null);
                                                                }}>
                                                                    <img src={ReportIcon} alt="report" />
                                                                    <h6>Report user</h6>
                                                                </span>
                                                            )}
                                                            {props.user && ((com.user.id == props.user.id) || props.user && props.user.is_admin) && (
                                                                <>
                                                                    <span onClick={() => {
                                                                        const anwser = window.confirm('Are you sure, This will remove the comment');
                                                                        if (anwser) {
                                                                            handleRemoveComment(com.id)
                                                                        }
                                                                    }}>
                                                                        <img src={TrashIcon} alt="delete" />
                                                                        <h6>Remove comment</h6>
                                                                    </span>
                                                                </>
                                                            )}
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                            <section style={{ width: 'fit-content' }} className="comment">
                                                <p className="whitespace-normal" style={{ marginTop: '-5px', marginLeft: '30px', fontWeight: '400', padding: '0px' }}>
                                                    <Content>{com.comment}</Content>
                                                </p>
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                    <span style={{ fontSize: '0.7rem', fontWeight: '400', marginLeft: '30px' }}>{format(com.created_at)}</span>
                                                    {com.posting ? (
                                                        <span style={{ fontSize: '0.7rem', marginLeft: '30px' }}>posting...</span>
                                                    ) : (
                                                        <button style={{ marginLeft: '10px' }} onClick={() => handleOpen(com.id)}>Reply</button>
                                                    )}
                                                </div>
                                                {open == com.id && (
                                                    <>
                                                        {props.user && props.user.id ? (
                                                            <>
                                                                {!props.user.active ? (
                                                                    <div style={{ width: 'calc(100% - 35px)' }} className="reply">
                                                                        <div className="add-reply">
                                                                            <form onSubmit={handleComment} className="add-comment" style={{ position: 'relative' }}>
                                                                                <div className="user-img" style={{ backgroundImage: `url(${(props.user && props.user.image) ? props.user.image : filesUrl + '/static/user/default.png'})` }}></div>
                                                                                <TextareaAutosize disabled placeholder="Account Temporarily Blocked" />
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div style={{ width: 'calc(100% - 35px)' }} className="reply">
                                                                        <div className="add-reply">
                                                                            <form onSubmit={handleComment} className="add-comment" style={{ position: 'relative' }}>
                                                                                <div className="user-img" style={{ backgroundImage: `url(${(props.user && props.user.image) ? props.user.image : filesUrl + '/static/user/default.png'})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                                                                <TextareaAutosize onChange={(e) => setReply(e.target.value)} value={reply} placeholder="Type here..." />
                                                                            </form>
                                                                            <div className="comment-action">
                                                                                {reply.length > 0 && (
                                                                                    <>
                                                                                        <div className="checkbox">
                                                                                            <label htmlFor='anony'>
                                                                                                <h5>reply Anonymously</h5>
                                                                                            </label>
                                                                                            <input onChange={(e) => setPublicReply(!e.target.checked)} id='anony' type="checkbox" />
                                                                                        </div>
                                                                                        <button onClick={() => handleReply(com.id, com.user, com.public)}>reply</button>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <div style={{ width: 'calc(100% - 35px)' }} className="reply">
                                                                <div className="add-reply">
                                                                    <form onSubmit={handleComment} className="add-comment" style={{ position: 'relative' }}>
                                                                        <div className="user-img" style={{ backgroundImage: `url(${(props.user && props.user.image) ? props.user.image : filesUrl + '/static/user/default.png'})` }}></div>
                                                                        <TextareaAutosize disabled placeholder="Please login to reply" />
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                                <Replies
                                                    post_id={props.post.id}
                                                    setOpenReplyMenu={setOpenReplyMenu}
                                                    com={com}
                                                    handleReplyMenu={handleReplyMenu}
                                                    commentId={com.id}
                                                    openReplyMenu={openReplyMenu}
                                                    handleRemoveReply={handleRemoveReply}
                                                    handleGoToChat={handleGoToChat}
                                                    get_profile={props.get_profile}
                                                    user={props.user}
                                                    repliesLength={com.replies_length}
                                                    replies={com.replies}
                                                    getMoreReplies={getMoreReplies}
                                                    setReportUser={(user) => setReportUser(user)}
                                                    setOpenReplyReport={() => setOpenReportUser(true)}
                                                    setReportuserData={setReportuserData}
                                                />
                                            </section>
                                        </>
                                    )}
                                </div>
                            ))}
                        </div>
                        {props.post && props.post.comments.length > 0 && (
                            <Link style={{ textDecorationLine: 'none' }} to={props.post && `/post/${props.post.id}`}>
                                <h3 className="more">show more...</h3>
                            </Link>
                        )}
                    </div>
                </>
            )}
        </div>
    )
}

const mapStateToprops = (state) => ({
    user: state.user.data,
    posts: state.posts.data,
    friends: state.friends.data
});

const mapDispatchToProps = (dispatch) => ({
    remove_post: (data) => dispatch(delete_post(data)),
    add_comment: (data) => dispatch(add_comment(data)),
    handle_support: (id) => dispatch(add_remove_support(id)),
    remove_comment: (id) => dispatch(remove_comment(id)),
    app_reply: (data) => dispatch(add_reply(data)),
    remove_reply: (data) => dispatch(remove_reply(data)),
    get_group_posts: (id) => dispatch(get_group_posts(id)),
    get_profile: (username) => dispatch(get_profile(username)),
    get_user: () => dispatch(get_user())
});

export default connect(mapStateToprops, mapDispatchToProps)(Post);