import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const FriendsLoading = () => {
    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
            {[1, 2, 3, 4].map((_, key) => (
                <section key={key} style={{ width: '150px', height: '250px', display: 'flex', flexDirection: 'column', marginLeft: '25px', alignItems: 'center', marginTop: '15px', boxShadow: '0px 5px 15px rgba(0,0,0,0.1)' }}>
                    <SkeletonTheme baseColor="#edebeb" highlightColor="#e0dede">
                        <Skeleton style={{ width: '150px', height: '150px' }} />
                        <Skeleton style={{ width: '100px', height: '10px', marginTop: '10px' }} />
                        <Skeleton style={{ width: '100px', height: '30px', marginTop: '10px' }} />
                    </SkeletonTheme>
                </section>
            ))}
        </div>
    )
}

export default FriendsLoading;