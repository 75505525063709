import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const FriendSideLoading = ({ enableAnimation }) => {
    return (
        <div>
            {[1, 2, 3, 4].map((_, key) => (
                <section key={key} style={{ width: '100%', maxWidth: '200px', display: 'flex', alignItems: 'center', marginLeft: '15px', marginTop: '0px', height: '50px', marginBottom: '10px' }}>
                    <SkeletonTheme enableAnimation={enableAnimation} baseColor="#edebeb" highlightColor="#e0dede">
                        <Skeleton circle style={{ width: '45px', height: '45px' }} />
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Skeleton style={{ width: '120px', height: '20px', marginLeft: '10px' }} />
                            <Skeleton style={{ width: '100px', height: '10px', marginLeft: '10px' }} />
                        </div>
                    </SkeletonTheme>
                </section>
            ))}
        </div>
    )
}

export default FriendSideLoading;