import React, { useState } from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Link, Navigate } from "react-router-dom";
import { set_title } from "../../assets/utils/title";
import { connect } from "react-redux";
import { set_admin_user } from "../../store/actions/admin";
import axios from "axios";

const Login = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    set_title('Admin');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!email) toast.warn('Email address is required!');
        else if (!password) toast.warn('Password is required!');
        else {
            axios.post('/admin/login', { email, password }).then(res => {
                props.set_admin(res.data.user);
                window.location.reload();
            }).catch(error => {
                if (error) {
                    if (error.response.status == 400) {
                        toast.warn(error.response.data.message);
                    }
                } else {
                    toast.warn('Login credentials error!');
                }
            });
        }
    }

    const clearSuperUserSession = () => {
        sessionStorage.removeItem('super_user');
    }

    return (
        <div>
            {props.admin_user !== null ? (
                <>
                    {props.admin_user.id ? (
                        <Navigate to="/admin" />
                    ) : (
                        <div className="form-area">
                            <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                                <h4>Admin</h4>
                                <section>
                                    <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Enter email address" />
                                    <span>Enter admin email address</span>
                                </section>
                                <section>
                                    <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Enter password" />
                                    <span>Enter admin password</span>
                                </section>
                                <div className="bottom">
                                    <p onClick={() => clearSuperUserSession()}><Link to='/signin' style={{ textDecoration: 'none', color: '#000' }} >Sign in as a normal user</Link></p>
                                </div>
                                <section>
                                    <button onClick={handleSubmit} type="submit">Login</button>
                                </section>
                            </form>
                        </div>
                    )}
                </>
            ) : (
                <h4>Loading...</h4>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    notify: state.notify.data,
    admin_user: state.admin_user.data
});

const mapDispatchToProps = (dispatch) => ({
    set_admin: (data) => dispatch(set_admin_user(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);