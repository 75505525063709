import React, { useEffect, useState } from "react";
import CloseIcon from '../../assets/images/plus.svg';
import PictureIcon from '../../assets/images/picture.svg';
import TagPeople from '../../assets/images/tags-people.svg';
import Tags from "./Tags";
import { create_post, create_post_func, get_tags, set_posts_func } from "../../store/actions/post";
import { connect } from "react-redux";
import { toast, Flip, ToastContainer } from "react-toastify";
import { create_group_post, get_group_posts, set_group_posts_func } from "../../store/actions/group";
import People from "./People";
import { set_success_func } from "../../store/actions/success";
import { socket } from "../../App";
import { upload_image } from "../../assets/utils/upload";
import axios from "axios";
import ImageUpload from "./ImageUpload";
import { Spinner } from "loading-animations-react";
import { filesUrl } from "../../App";

const CreatePost = (props) => {
    const [image, setImage] = useState(null);
    const [opentags, setOpentags] = useState(false);
    const [openPeople, setOpenPeople] = useState(false);
    const [selected, SetSelected] = useState([]);
    const [alltags, setAlltags] = useState([]);
    const [description, setDescription] = useState('');
    const [checked, setChecked] = useState(true);
    const [Loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [openImage, setOpenImage] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);

    const handleOpenTags = () => {
        if (opentags) setOpentags(false);
        else setOpentags(true);
    }

    const handleSelected = (item) => {
        SetSelected([...selected, item]);
    }

    const handleCroppedImage = (croppedImage) => {
        setImage(croppedImage);
        setImageLoading(false)
    }

    useEffect(() => {
        if (selected.length > 5) toast.warn('You can only add maximum 5 tags');
    }, [selected]);

    const addToTags = (item) => {
        setAlltags([...alltags, item]);
    }

    const handleImage = (up_image) => {
        if (up_image) {
            const mimeType = up_image.type.split('/')[0];
            if (mimeType !== 'image') toast.warn('You only can upload images');
            else if (up_image.size <= 8388608) {
                if ((up_image.size / 1024 / 1024) > 10) toast.warn('You can\'t upload large files than 10MB');
                else setImage(up_image);
            } else toast.warn('You only can upload maximum 8MB');
        }
    }

    useEffect(() => {
        props.get_tags();
    }, []);

    useEffect(() => {
        setAlltags(props.tags);
    }, [props.tags]);

    const removeFromSelected = (item) => {
        const newArray = [...selected];
        const index = newArray.findIndex((element) => element == item);
        if (index !== -1) {
            newArray.splice(index, 1)
            SetSelected(newArray);
        }
    }

    const handleRemoveUsers = (data) => {
        const user = users.findIndex((us) => us.id == data.id);
        const newArr = [...users];
        if (user !== -1) {
            newArr.splice(user, 1);
            setUsers([...newArr]);
        }
    }

    const handleAddUsers = (data) => {
        const user = users.find((user) => user.id == data.id);
        const newArray = [...users];
        // if (user) {
        //     const index = newArray.findIndex((data) => data.id == data.id);
        //     if (index !== -1) {
        //         newArray.splice(index, 1)
        //         setUsers(newArray);
        //     }
        // } else {
        newArray.push(data);
        setUsers(newArray);
        // }
    }

    const handle_create = async () => {
        if (!description) toast.warn("Please enter post description");
        else if (selected.length < 1) toast.warn("Please select at least one tag");
        else {
            setLoading(true);
            const created_status = await create_post_func({ token: props.user.token, content: description, image: image, public: checked, from_username: props.user.username, tags: selected.map(tag => tag.id), userId: props.user.id, tag_people: checked ? (users.length > 0 ? users.map(user => user.id) : null) : null }, props.posts);
            if (checked && users.length > 0) {
                socket.emit('send-tag-post-notifi', created_status.notifications);
            }
            props.handleOpen();
            setLoading(false);
            if (created_status.post) {
                props.resetPage();
                axios.get(`/posts?id=${props.user && props.user.id}`).then(res => {
                    props.set_posts([...res.data.posts]);
                });
            }
        }
    }

    const group_post_create = (uploadImage) => {
        axios.post(`/group/posts/${props.groupId}`, { content: description, image: uploadImage, groupName: props.groupName, tags: selected.map(tag => tag.id) }).then(res => {
            if (res.data.post) {
                socket.emit('send-group-post', res.data.post)
                props.resetPage();
                // axios.get(`/group/posts/${props.groupId}`).then(res => {
                //     props.set_group_posts(res.data.posts);
                // }).catch(error => {
                //     props.set_group_posts([]);
                // });
            }
            props.handleOpen();
            setLoading(true);
        }).catch(_error => {
            props.handleOpen();
            setLoading(true);
        });
    }

    const handle_create_group = async () => {
        if (!description) toast.warn("Please enter post description");
        else if (selected.length < 1) toast.warn("Please select at least one tag")
        else {
            if (props.groupId && props.groupName) {
                setLoading(true);
                props.resetPage();
                if (image) {
                    upload_image(image, 'post', props.user.token).then(response => {
                        group_post_create(response);
                    }).catch(_error => {
                        toast.warn('Invalid post data!');
                    });
                } else group_post_create(null);
                // props.create_group_post({ content: description, image: image, tags: selected.map(tag => tag.id), userId: props.user.id, groupId: props.groupId, groupName: props.groupName });
            }
        }
    }

    useEffect(() => {
        if (props.notify && props.notify.com == 'create') {
            if (props.notify.success) {
                setLoading(false);
            }
        }
    }, [props.notify]);

    return (
        <div className={props.open ? 'create-post-area create-active' : 'create-post-area'}>
            <div className="hide-lg">
                <ToastContainer
                    transition={Flip}
                    hideProgressBar={true}
                    style={{ width: 'calc(100% - 20px)', margin: '10px', marginLeft: '15px' }}
                />
            </div>
            <div className="overlay">
                <People user_id={props.user.id} users={users} handleAddUsers={handleAddUsers} handleRemoveUsers={handleRemoveUsers} open={openPeople} handleOpen={() => setOpenPeople(!openPeople)} />
                <Tags addToTags={addToTags} alltags={alltags} setAlltags={setAlltags} selected={selected} open={opentags} handleSelected={handleSelected} remobeFromSelected={removeFromSelected} handleOpen={handleOpenTags} />
                {openImage && <ImageUpload imageLoading={setImageLoading} handleCroppedImage={handleCroppedImage} open={openImage} handleOpen={() => setOpenImage(!openImage)} />}
                <div className="modal">
                    <div className="head">
                        <div className="user">
                            <div className="img" style={{ backgroundImage: `url(${props.user && props.user.image ? props.user.image : filesUrl + '/static/user/default.png'})` }}></div>
                        </div>
                        <img onClick={() => props.handleOpen()} src={CloseIcon} alt="close" />
                    </div>
                    <div className="body">
                        <textarea onChange={(e) => setDescription(e.target.value)} placeholder={`What's on your mind, ${props.user && props.user.username}?`}></textarea>
                        {imageLoading ? (
                            <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                            </div>
                        ) : (
                            <>
                                {image && (
                                    <div className="img-area">
                                        <img onClick={() => setImage(null)} className="icon" src={CloseIcon} alt="remove" />
                                        <div className="img">
                                            <img src={URL.createObjectURL(image)} alt="post" />
                                        </div>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    <div className="tags">
                        {selected.map((tag, key) => (
                            <h5 key={key} onClick={() => {
                                removeFromSelected(tag)
                                addToTags(tag)
                            }}>{tag.name} <img src={CloseIcon} alt="close" /></h5>
                        ))}
                        <h5 className="add" onClick={handleOpenTags}>Add tag <img className="add" src={CloseIcon} alt="close" /></h5>
                    </div>
                    <div className="bottom">
                        {!props.group && (
                            <div style={{ backgroundColor: !checked ? '#cacbcc' : '#e6e5e5' }} className="anony">
                                <input type="checkbox" id="anony" onChange={(e) => e.target.checked ? setChecked(false) : setChecked(true)} />
                                <label htmlFor="anony" className="cls-anony-text">
                                    Post Anonymously
                                </label>
                            </div>
                        )}
                        {Loading ? (
                            <button className="post-create-btn">Publishing...</button>
                        ) : (
                            <button onClick={props.group ? handle_create_group : handle_create} className="post-create-btn">Post</button>
                        )}
                        <input hidden type="file" id="imgUp" onChange={(e) => {
                            handleImage(e.target.files[0])
                        }} />
                        <label>
                            <img onClick={() => setOpenImage(true)} src={PictureIcon} alt="picture" />
                        </label>
                        {!props.group && (
                            <>
                                {checked && props.friends && props.friends.length > 0 && (
                                    <section style={{ display: 'flex', alignItems: 'center' }}>
                                        <img onClick={() => setOpenPeople(!openPeople)} src={TagPeople} alt="tags-people" className="tag-people" />
                                        {users.length > 0 && (
                                            <p style={{ fontWeight: '400', marginLeft: '7px' }}>{users.length}</p>
                                        )}
                                    </section>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    tags: state.tags.data,
    notify: state.notify.data,
    user: state.user.data,
    success: state.success.data,
    friends: state.friends.data,
    posts: state.posts.data,
    group_posts: state.group_posts.posts
});

const mapDispatchToProps = (dispatch) => ({
    get_tags: () => dispatch(get_tags()),
    create_post: (data, posts) => dispatch(create_post(data, posts)),
    create_group_post: (data) => dispatch(create_group_post(data)),
    get_group_posts: (id) => dispatch(get_group_posts(id)),
    set_success: (data) => dispatch(set_success_func(data)),
    set_posts: (data) => dispatch(set_posts_func(data)),
    set_group_posts: (data) => dispatch(set_group_posts_func(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(CreatePost);