import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import BackIcon from '../../assets/images/arrow-left.svg';
import { get_post, tag_filter } from "../../store/actions/post";
import PostsLoading from "../Loading/PostLoading";
import Posts from "./Posts";
import { Spinner } from "loading-animations-react";

const TagsPage = (props) => {

    const navigate = useNavigate();
    const { name } = useParams();

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [postsLoading, setPostsLoading] = useState(false);
    const [noMore, setNoMore] = useState(false);
    const scroll = useRef();

    const get_posts = () => {
        setPostsLoading(true);
        axios.get(`/posts/tags/${name}?page=${currentPage * 5}`).then(res => {
            if (res.data.number_of_posts_pages >= currentPage) {
                setTotalPages(res.data.number_of_posts_pages);
                setPosts([...posts, ...res.data.posts]);
                setLoading(false);
                setPostsLoading(false);
            } else {
                setNoMore(true);
                setPostsLoading(false);
                setLoading(false);
            }
        });
    }

    useEffect(() => {

        get_posts();

        const onScroll = (event) => {
            const { scrollHeight, scrollTop } = document.documentElement;
            if (!postsLoading && !noMore && (window.innerHeight + scrollTop + 1 >= scrollHeight)) {
                setCurrentPage(prev => prev + 1);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);

    }, []);

    useEffect(() => {
        get_posts();
    }, [currentPage]);

    const go_back = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }

    useEffect(() => {
        scroll.current?.scrollIntoView({ behavior: "smooth" });
        setNoMore(false);
        setTotalPages(0);
        setCurrentPage(0);
        setLoading(true);
        setPosts([]);
        get_posts();
    }, [name]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);



    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', overflowX: 'hidden' }}>
            <div className="tags-view-area">
                {!loading ? (
                    <>
                        {posts && posts.length > 0 ? (
                            <>
                                <div style={{ width: '100%', maxWidth: '600px' }}>
                                    <div className="head-top">
                                        <img src={BackIcon} onClick={go_back} alt="back" />
                                        <h4>{name}</h4>
                                    </div>
                                    <div ref={scroll} style={{ marginLeft: '-3px' }} className="tag_posts">
                                        <Posts posts={posts} />
                                    </div>
                                </div>
                                {postsLoading && (
                                    <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                                        <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                                    </div>
                                )}
                            </>
                        ) : (
                            <h4>Not found</h4>
                        )}
                    </>
                ) : (
                    <PostsLoading />
                )}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    get_posts: state.tag_filter.posts
});

const mapDispatchToProps = (dispatch) => ({
    tag_filter: (name) => dispatch(tag_filter(name))
});

export default connect(mapStateToProps, mapDispatchToProps)(TagsPage);