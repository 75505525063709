import React from "react";
import { ReactComponent as TrashIcon } from '../../../assets/images/trash.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/plus.svg';
import axios from "axios";
import { toast } from "react-toastify";

const FroClearData = (props) => {

    const handleClear = (id) => {
        axios.delete(`/admin/clear/${id}`).then(res => {
            toast.success(res.data.message);
            props.handleClose()
        }).catch(_error => {
            toast.success('User data error!');
        });
    }

    return (
        <div className="clear-data-permi-area">
            <div className="overlay"></div>
            <div className="modal">
                <div className="head">
                    <CloseIcon onClick={() => props.handleClose()} />
                </div>
                <div className="body">
                    <TrashIcon />
                    <p></p>
                    <button onClick={() => handleClear(props.user.id)}>clear data</button>
                </div>
            </div>
        </div>
    )
}

export default FroClearData;