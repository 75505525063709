import axios from "axios";
import React from "react";
import { connect } from "react-redux";
import { ReactComponent as RefreshIcon } from '../../assets/images/refresh.svg';
import { get_group_posts, set_group_posts_func } from "../../store/actions/group";
import { get_post, set_posts_func, set_post_func } from '../../store/actions/post';

const NewUpdates = (props) => {


    const get_posts = () => {
        axios.get(`/posts?id=${props.user && props.user.id}`).then(res => {
            props.set_posts([...res.data.posts]);
        });
    }

    const handleGetUpdates = () => {
        props.setHaveUpdates();
        props.resetPage();
        if (props.all) {
            get_posts();
        } else if (props.groupId) {
            axios.get(`/group/posts/${props.groupId}`).then(res => {
                props.set_group_posts(res.data.posts);
            }).catch(_error => {
                props.set_group_posts([]);
            });
        }
        else if (props.sub_replies) { }
        else {
            props.get_post(props.postId, props.user.id);
        }
        if (!props.donotLoad) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }

    return (
        <div onClick={() => props.setHaveUpdates()} className={props.haveUpdates ? 'new-updates-area active' : 'new-updates-area'}>
            <div onClick={() => handleGetUpdates()} className="updates-icon">
                <RefreshIcon />
                <h5>see new updates</h5>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    posts: state.posts.data,
    user: state.user.data
});

const mapDispatchToProps = (dispatch) => ({
    set_posts: (data) => dispatch(set_posts_func(data)),
    get_post: (id, user_id) => dispatch(get_post(id, user_id)),
    set_post_func: (data) => dispatch(set_post_func(data)),
    get_group_posts: (id) => dispatch(get_group_posts(id)),
    set_group_posts: (data) => dispatch(set_group_posts_func(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(NewUpdates);