import React, { useEffect, useRef, useState } from "react";
import Desc from "./Desc";
import Posts from "./Post/Posts";
import CreatePost from "./Post/create";
import { connect } from "react-redux";
import { socket } from "../App";
import { clear_profile } from "../store/actions/users";
import { set_posts_func } from "../store/actions/post";
import NewUpdates from "./Post/NewUpdates";
import PostsLoading from "./Loading/PostLoading";
import axios from "axios";
import { Spinner } from "loading-animations-react";
import { filesUrl } from "../App";
import { ReactComponent as PlusIcon } from '../assets/images/more-posts.svg';

const Home = (props) => {

    const [open, setOpen] = useState(false);
    const [haveUpdates, setHaveUpdates] = useState(false);
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [totalPosts, setTotalPosts] = useState(0);
    const [postsLoading, setPostsLoading] = useState(false);
    const [noMore, setNoMore] = useState(false);
    const scroll = useRef();

    const get_posts = () => {
        setPostsLoading(true);
        axios.get(`/posts?page=${currentPage}&&id=${props.user && (props.user.id ? props.user.id : null)}`).then(res => {
            if (res.data.number_of_posts_pages >= currentPage) {
                setTotalPages(res.data.number_of_posts_pages);
                props.set_posts([...posts, ...res.data.posts]);
                setLoading(false);
                setPostsLoading(false);
            } else {
                setNoMore(true);
                setPostsLoading(false);
                setLoading(false);
            }
        });
    }

    const resetPage = () => {
        setCurrentPage(0);
        scroll.current?.scrollIntoView({ behavior: "smooth" });
        setLoading(true);
    }

    useEffect(() => {
        get_posts()
    }, [currentPage]);

    useEffect(() => {
        setTotalPosts(posts.length);
    }, [posts]);

    useEffect(() => {

        get_posts();

        const onScroll = (event) => {
            const { scrollHeight, scrollTop } = document.documentElement;
            if (!postsLoading && !noMore && (window.innerHeight + scrollTop + 1 >= scrollHeight)) {
                setCurrentPage(prev => prev + 5);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);

    }, []);

    const handleOpen = () => {
        if (open) setOpen(false);
        else setOpen(true);
    }

    useEffect(() => {
        if (props.notify && props.notify.com == 'create') {
            if (props.notify.success) {
                setOpen(false);
            }
        }
    }, [props.notify]);

    useEffect(() => {
        socket.on('recive-post', async (new_post) => {
            if (props.user && props.user.id) {
                if (props.user.id !== new_post.user.id) {
                    const exitspost = await props.posts && props.posts.find((pst) => pst.id == new_post.id);
                    if (!exitspost) {
                        setHaveUpdates(true);
                    }
                }
            } else {
                const exitspost = await props.posts && props.posts.find((pst) => pst.id == new_post.id);
                if (!exitspost) {
                    setHaveUpdates(true);
                }
            }
        });
    }, []);

    useEffect(() => {
        props.clear_profile();
    }, []);

    useEffect(() => {
        if (props.posts) setPosts([...props.posts]);
        setLoading(false)
    }, [props.posts]);

    return (
        <div className="home">
            <NewUpdates resetPage={resetPage} haveUpdates={haveUpdates} setHaveUpdates={() => setHaveUpdates(false)} all={true} />
            {open && <CreatePost resetPage={resetPage} handleOpen={() => handleOpen()} setOpen={() => setOpen(!open)} open={open} />}
            <section style={{ marginTop: '21px' }} className="post-area">
                {props.user && props.user.id && props.user.active && (
                    <div styl className="create-top">
                        <div className="img" style={{ backgroundImage: `url(${props.user.image ? props.user.image : filesUrl + '/static/user/default.png'})` }}></div>
                        <h5 onClick={() => handleOpen()}>What's on your mind ?</h5>
                    </div>
                )}
                {loading ? (
                    <PostsLoading />
                ) : (
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: `${props.user && props.user.id && props.user.active ? '-8px' : '-20px'}` }} ref={scroll}>
                        <Posts posts={posts} />
                        {postsLoading ? (
                            <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                                <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                            </div>
                        ) : (
                            <>
                                {(posts.length > 5) && (totalPages > posts.length) && (
                                    <button onClick={() => setCurrentPage(totalPosts)} className="more-post-btn">
                                        <PlusIcon />
                                        <p>more</p>
                                    </button>
                                )}
                            </>
                        )}
                    </div>
                )}
            </section>
            <Desc user={props.user} />
        </div>
    )
}

const mapStateToProps = (state) => ({
    posts: state.posts.data,
    notify: state.notify.data,
    user: state.user.data,
});

const mapDispatchToProps = (dispatch) => ({
    clear_profile: () => dispatch(clear_profile()),
    set_posts: (data) => dispatch(set_posts_func(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);