import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import BackBtn from '../assets/images/angle-left.svg';
import { connect } from "react-redux";
import { get_user } from "../store/actions/users";
import { ReactComponent as PeopleIcon } from '../assets/images/people.svg';
import { ReactComponent as PlusIcon } from '../assets/images/plus.svg';
import axios from "axios";
import FriendsLoading from "./Loading/FriendsLoading";
import IsLoggedIn from "./IsLoggedIn";
import { filesUrl } from "../App";

const Friends = (props) => {
    const [friends, setFriends] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfFriends, setNumberOfFriends] = useState(0);

    const navigate = useNavigate();

    const go_back = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }

    const get_friends = () => {
        axios.get(`/user/friends?page=${currentPage}`).then(res => {
            setCurrentPage(prev => prev + 20);
            setNumberOfFriends(res.data.number_of_friends);
            if (friends !== null) setFriends([...friends, ...res.data.friends]);
            else setFriends([...res.data.friends]);
        });
    }

    const handleMessageUrl = (fri_id) => {
        axios.get(`/chat/fri/${fri_id}`).then(res => {
            if (res.data.chat) {
                return navigate(`/chat/${res.data.chat}`)
            }
        });
    }

    useEffect(() => {
        get_friends();
    }, []);

    return (
        <IsLoggedIn>
            <div className="requests-area">
                {props.user !== null ? (
                    <>
                        {(props.user && props.user.id) ? (
                            <>
                                <div onClick={go_back} className="back-btn">
                                    <img src={BackBtn} alt="back" />
                                </div>
                                {friends !== null ? (
                                    <>
                                        {friends.length > 0 ? (
                                            <div className="requests">
                                                <h4>Friends</h4>
                                                <div style={{ marginTop: '20px' }} className="users">
                                                    <ul>
                                                        {friends.map((user, key) => (
                                                            <li key={key} style={{ height: 'fit-content', border: '2px solid rgba(0,0,0,0.2)' }}>
                                                                <section>
                                                                    <div className="img" style={{ backgroundImage: `url(${user.image ? user.image : filesUrl + '/static/user/default.png'})` }}>
                                                                        <div style={{ cursor: 'default' }} className="overlay"></div>
                                                                    </div>
                                                                    <h5 style={{ width: '100%', textAlign: 'center', marginLeft: '0px' }}>{user.username}</h5>
                                                                </section>
                                                                <div className="bottom">
                                                                    <div style={{ marginTop: '10px' }} className="btns">
                                                                        <button onClick={() => handleMessageUrl(user.id)}>Message</button>
                                                                        <button onClick={() => navigate(`/user/${user.id}`)}>View</button>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                        {numberOfFriends > friends.length && (
                                                            <li style={{ height: 'calc(100% - 20px)', border: '2px solid rgba(0,0,0,0.2)', padding: '10px 0px', justifyContent: 'center' }}>
                                                                <div onClick={() => get_friends()} style={{ width: '50px', height: '50px', borderRadius: '50%', cursor: 'pointer', backgroundColor: 'rgba(0,0,0,0.1)', border: '1px solid rgba(0,0,0,0.2)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <PlusIcon style={{ width: '30px', height: '30px' }} />
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="center-requests">
                                                <PeopleIcon />
                                                <h5>No friends</h5>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <FriendsLoading />
                                )}
                            </>
                        ) : (
                            <Navigate to="/" />
                        )}
                    </>
                ) : (
                    <h5>Loading...</h5>
                )}
            </div>
        </IsLoggedIn>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    friends: state.friends.data
});

const mapDispatchToProps = (dispatch) => ({
    get_user: () => dispatch(get_user())
});

export default connect(mapStateToProps, mapDispatchToProps)(Friends);