import React, { useState } from "react";

const Content = ({ children, long, newStyle }) => {

    const [isMore, setIsMore] = useState(false);

    return (
        <div style={newStyle ? newStyle : {}} className="post-desc">
            {isMore ? (
                <p className="whitespace-normal">
                    {children}
                </p>
            ) : (
                <>
                    {long ? (
                        <>
                            {children.length > 300 ? (
                                <p className="whitespace-normal">
                                    {long ? children.substr(0, 300) : children.substr(0, 150)}...
                                    <button onClick={() => setIsMore(true)}>Read more</button>
                                </p>
                            ) : (
                                <p className="whitespace-normal">{children}</p>
                            )}
                        </>
                    ) : (
                        <>
                            {children.length > 150 ? (
                                <p className="whitespace-normal">
                                    {long ? children.substr(0, 300) : children.substr(0, 150)}...
                                    <button onClick={() => setIsMore(true)}>Read more</button>
                                </p>
                            ) : (
                                <p className="whitespace-normal">{children}</p>
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export default Content;