import { SEARCH_USERS_PAGE } from "../actions/types"

const INITIAL_STATE = {
    data: null,
}

const page_search_reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEARCH_USERS_PAGE:
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}

export default page_search_reducer;