import React, { useEffect, useState } from "react";
import Appbar from "./Appbar";
import { ReactComponent as TrashIcon } from '../../assets/images/trash.svg';
import { ReactComponent as BanIcon } from '../../assets/images/ban.svg';
import axios from "axios";
import { get_reports, set_reports_func } from "../../store/actions/admin";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { format } from "timeago.js";

const Admin = (props) => {

    const [overview, setOverview] = useState(null);
    const [users, setUsers] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [reports, setReports] = useState(null);

    const removeReport = (id) => {
        const anwser = window.confirm('Are you sure, This will delete the report');
        if (anwser) {
            axios.delete(`/admin/reports/${id}`).then(_res => {
                const newReportsArr = [...reports];
                const reportIndex = newReportsArr.findIndex((report) => report.id == id);
                newReportsArr.splice(reportIndex, 1);
                setReports([...newReportsArr]);
            }).catch(error => {
                toast.warn('Report remove error!');
            });
        }
    }

    const handleBlock = (id, username) => {
        const anwser = window.confirm('Are you sure, This will block the user');
        if (anwser) {
            axios.post(`/admin/ban/${id}`, { username }).then(_res => {
                toast.success('blocked');
            }).catch(error => {
                if (error) {
                    if (error.response.status == 400) {
                        toast.warn('This user is already bocked');
                    }
                } else {
                    toast.warn('User credentials error!');
                }
            });
        }
    }

    useEffect(() => {
        axios.get(`/admin/reports?name=${searchText}`).then(res => {
            setReports(res.data.reports);
        });
    }, [searchText]);

    useEffect(() => {
        axios.get(`/admin/overview?name=${searchText}`).then(res => {
            setOverview(res.data.details);
        });

        axios.get('/admin/all').then(res => {
            setUsers(res.data.users.filter(user => user.id !== props.user.id));
        });
    }, []);

    return (
        <div className="admin-area">
            <Appbar />
            <div className="overview">
                <div className="head">
                    <section>
                        <h4>Overview</h4>
                        {overview && (
                            <ul>
                                <li>
                                    <h5>Posts</h5>
                                    <p>{overview.posts}</p>
                                </li>
                                <li>
                                    <h5>Users</h5>
                                    <p>{overview.users}</p>
                                </li>
                                <li>
                                    <h5>Groups</h5>
                                    <p>{overview.groups}</p>
                                </li>
                            </ul>
                        )}
                    </section>
                    <section>
                        {users !== null ? (
                            <>
                                {users.length > 0 ? (
                                    <>
                                        <h4>Other Admins</h4>
                                        <ul>
                                            {users.map(user => (
                                                <li key={user.id}>
                                                    <h5>{user.username}</h5>
                                                </li>
                                            ))}
                                        </ul>
                                    </>
                                ) : (
                                    <div className="no-admins">
                                        <h3>No Other Admins</h3>
                                    </div>
                                )}
                            </>
                        ) : (
                            <h5>Loading...</h5>
                        )}
                    </section>
                    {/* <section></section> */}
                </div>
                <div className="reports">
                    <div className="top">
                        <h4>reports from users</h4>
                    </div>

                    <div className="search">
                        <input onChange={(e) => setSearchText(e.target.value)} type="text" placeholder="Search users" />
                    </div>
                    <ul>
                        {reports !== null ? (
                            <>
                                {reports.map(report => (
                                    <li key={report.id}>
                                        <section>
                                            <h5><strong>about:</strong> @{report.about_username}</h5>
                                            <p><strong>report:</strong> {report.report}</p>
                                            <p><strong>created_at:</strong> {format(report.created_at)}</p>
                                        </section>
                                        <div className="btns">
                                            <acronym title="remove report">
                                                <button onClick={() => removeReport(report.id)}><TrashIcon /></button>
                                            </acronym>
                                            <acronym title="block user">
                                                <button onClick={() => handleBlock(report.about_id, report.about_username)}><BanIcon /></button>
                                            </acronym>
                                        </div>
                                    </li>
                                ))}
                            </>
                        ) : (
                            <h4>Loading...</h4>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    reports: state.admin.reports,
    user: state.user.data
});

const mapDispatchToProps = (dispatch) => ({
    get_reports: () => dispatch(get_reports()),
    set_reports: (data) => dispatch(set_reports_func(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);