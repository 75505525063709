import React from "react";

const ShareDialog = ({ handleClose, postId }) => {
    return (
        <div className="share-dialog">
            <div className="overlay" onClick={handleClose}></div>
            <div className="model">
                <h6>Copy Link</h6>
                <input autoFocus value={`http://54.91.203.2:8000/posts/${postId}`} />
            </div>
        </div>
    )
}

export default ShareDialog;