import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const SearchPageLoading = () => {
    return (
        <div>
            {[1,2,3,4].map((_, key) => (
                <section key={key} style={{ width: '100%', maxWidth: '200px', display: 'flex', alignItems: 'center', marginLeft: '15px' }}>
                    <SkeletonTheme baseColor="#edebeb" highlightColor="#e0dede">
                        <Skeleton circle style={{ width: '60px', height: '60px' }} />
                        <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                            <Skeleton style={{ width: '120px', height: '15px', marginLeft: '-5px' }} />
                            <Skeleton style={{ width: '80px', height: '7px', marginLeft: '-5px' }} />
                        </section>
                    </SkeletonTheme>
                </section>
            ))}
        </div>
    )
}

export default SearchPageLoading;