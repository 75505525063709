import React, { useEffect, useRef, useState } from "react";
import People from "./Peoples";
import BackBtn from '../assets/images/angle-left.svg';
import { Link, useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { clear_profile, search_users, search_users_page } from "../store/actions/users";
import SearchPageLoading from "./Loading/SearchPageLoading";
import { filesUrl } from "../App";

const SearchPage = (props) => {

    const [searchtxt, setSearchtxt] = useState('');
    const [openres, setOpenres] = useState(false);
    const [results, setResults] = useState([]);
    const resultsRef = useRef();

    const navigate = useNavigate();
    const { name } = useParams();

    const handleSearch = (event) => {
        event.preventDefault();
        if (searchtxt) props.search_page_func(searchtxt);
    }

    useEffect(() => {
        props.search_users_func(searchtxt);
        if (searchtxt.length > 0) setOpenres(true);
    }, [searchtxt]);

    useEffect(() => {

        props.clear_profile()

        if (name) props.search_page_func(name);

        const handleClickOutside = (event) => {
            if (!resultsRef.current.contains(event.target)) setOpenres(false);
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);

    }, []);

    useEffect(() => {
        if (results == null && results.length <= 0) {
            setResults(props.search);
        }
    }, [props.search]);

    const go_back = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }

    return (
        <div className="search-area">
            <div onClick={go_back} className="back-btn">
                <img src={BackBtn} alt="back" />
            </div>
            <form onSubmit={handleSearch} className="head">
                <input onFocus={() => setOpenres(true)} onChange={(e) => setSearchtxt(e.target.value)} type="text" placeholder="search..." />
                <div ref={resultsRef} className={openres ? 'results-bar active' : 'results-bar'}>
                    <p>Type enter to search or click on the results</p>
                    <ul>
                        {props.search !== null ? (
                            <>
                                {props.search && props.search.length > 0 ? (
                                    <>
                                        {props.search.map((user, key) => (
                                            <Link key={key} style={{ color: '#444', textDecorationLine: 'none' }} to={`/user/${user.id}`}>
                                                <li>
                                                    <section>
                                                        <div className="img" style={{ backgroundImage: `url(${user.image ? user.image : filesUrl + '/static/user/default.png'})` }}></div>
                                                        <h5>{user.username}</h5>
                                                    </section>
                                                </li>
                                            </Link>
                                        ))}
                                    </>
                                ) : (
                                    <>
                                        {searchtxt.length > 0 ? (
                                            <span></span>
                                        ) : (
                                            <span>search users by username</span>
                                        )}
                                    </>
                                )}
                            </>
                        ) : (
                            <span>Loading...</span>
                        )}
                    </ul>
                </div>
            </form>
            <div className="results">
                <h5>People</h5>
                <div className="people">
                    {props.search_page !== null ? (
                        <>
                            {props.search_page && props.search_page.length > 0 ? (
                                <>
                                    {props.search_page && props.search_page.map((user, key) => (
                                        <section key={key}>
                                            <div className="user">
                                                <div className="img" style={{ backgroundImage: `url(${user.image ? user.image : filesUrl + '/static/user/default.png'})` }}></div>
                                                <div className="text">
                                                    <h3>{user.name.length > 16 ? `${user.name.substr(0, 16)}...` : user.name}</h3>
                                                    <span>@{user.username.length > 16 ? `${user.username.substr(0, 16)}...` : user.username}</span>
                                                </div>
                                            </div>
                                            <Link style={{ textDecorationLine: 'none' }} to={`/user/${user.id}`}>
                                                <button>view</button>
                                            </Link>
                                        </section>
                                    ))}
                                </>
                            ) : (
                                <div>
                                    {searchtxt.length > 0 ? (
                                        <h4>Not user found</h4>
                                    ) : (
                                        <h4>You are not searching any user</h4>
                                    )}
                                </div>
                            )}
                        </>
                    ) : (
                        <div>
                            <SearchPageLoading />
                        </div>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    search: state.search.data,
    search_page: state.search_page.data
});

const mapDispatchToProps = (dispatch) => ({
    search_users_func: (username) => dispatch(search_users(username)),
    clear_profile: () => dispatch(clear_profile()),
    search_page_func: (username) => dispatch(search_users_page(username))
});


export default connect(mapStateToProps, mapDispatchToProps)(SearchPage);