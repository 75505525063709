import React, { useState } from "react";
import CloseIcon from '../../assets/images/plus.svg';

const Tags = (props) => {

    const [searchtxt, setSearchtxt] = useState('');

    const [alltags, setAlltags] = useState(props.tags);

    const removeFromTags = (item) => {
        const newArray = [...props.alltags];
        const index = newArray.findIndex((element) => element == item);
        if (index !== -1) {
            newArray.splice(index, 1)
            props.setAlltags(newArray);
        }
    }

    return (
        <div className={props.open ? 'tags-area tags-active' : 'tags-area'}>
            <div className="head">
                <h3 onClick={props.handleOpen} style={{ marginRight: '10px' }}>Done</h3>
                <p style={{ textAlign: 'center' }}>You can choose a maximum of 5 tags</p>
                <p></p>
            </div>
            <div className="search">
                <input type="text" onChange={(e) => setSearchtxt(e.target.value)} placeholder="Search tag" />
            </div>
            <span>Selected tags</span>
            <div className="selected">
                {props.selected.map((tag, key) => (
                    <h5 key={key} onClick={() => {
                        props.remobeFromSelected(tag)
                        props.addToTags(tag)
                    }}>{tag.name} <img src={CloseIcon} alt="delete" /></h5>
                ))}
            </div>
            <div className="body">
                {props.alltags && props.alltags.filter(val => {
                    if (searchtxt == '') return val.name
                    else if (val.name.toLocaleLowerCase().includes(searchtxt.toLocaleLowerCase())) return val.name
                }).map((tag, key) => (
                    <h5 onClick={() => {
                        if (props.selected.length < 5) {
                            props.handleSelected(tag);
                            removeFromTags(tag)
                        }
                    }} key={key}>{tag.name}</h5>
                ))}
            </div>
        </div>
    )
}

export default Tags;