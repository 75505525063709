import React, { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from '../../assets/images/plus.svg';
import { connect } from "react-redux";
import axios from "axios";
import { ReactComponent as AngleIcon } from '../../assets/images/angle-down.svg';
import { ReactComponent as CorretIcon } from '../../assets/images/check-mark.svg';
import { filesUrl } from "../../App";

const People = (props) => {

    const [name, setName] = useState('');
    const [friendsCount, setFriendsCount] = useState(0);
    const [friends, setFriends] = useState([]);

    const getFriends = () => {
        axios.get(`/user/tag/friends/${props.user_id}?name=${name}`).then(res => {
            setFriends([...res.data.users]);
        });
    }

    useEffect(() => {
        getFriends();
    }, [name]);

    return (
        <div className={props.open ? "people-area active" : "people-area"}>
            <div className="overlay"></div>
            <div className="people-modal">
                <div className="head">
                    <h5>select friends to tag</h5>
                    <CloseIcon onClick={props.handleOpen} />
                </div>
                <div className="body">
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <input onChange={(e) => setName(e.target.value)} type="text" style={{ width: 'calc(100% - 15px)', padding: '7px', outline: 'none', border: '1px solid #c4c4c4', borderRadius: '5px', fontFamily: '"Poppins", sans-serif', background: 'transparent' }} placeholder="Find friends" />
                    </div>
                    {friends.length > 0 ? (
                        <ul>
                            {friends.map((fri, key) => (
                                <div style={{ width: '100%' }} key={key}>
                                    {props.users.find(user => user.id == fri.id) ? (
                                        <li style={{ backgroundColor: 'rgba(0,0,0,0.05)', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} onClick={() => props.handleRemoveUsers(fri)}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <div className="img" style={{ backgroundImage: `url(${fri.image})` }}></div>
                                                <h5>{fri.username}</h5>
                                            </div>
                                            <CorretIcon style={{ width: '20px', height: '20px', marginRight: '15px' }} />
                                        </li>
                                    ) : (
                                        <li onClick={() => props.handleAddUsers(fri)}>
                                            <div className="img" style={{ backgroundImage: `url(${fri.image ? fri.image : filesUrl + '/static/user/default.png'})` }}></div>
                                            <h5>{fri.username}</h5>
                                        </li>
                                    )}
                                    {friendsCount > friends.length && (
                                        <li onClick={() => getFriends()}>
                                            <AngleIcon style={{ width: '20px', fill: '#777', height: '20px', marginLeft: '10px', paddingTop: '8px', paddingBottom: '8px', marginRight: '5px' }} />
                                            <h5>see more</h5>
                                        </li>
                                    )}
                                </div>
                            ))}
                        </ul>
                    ) : (
                        <h4 style={{ fontSize: '0.8rem', color: '#666', width: '100%', textAlign: 'center', marginTop: '15px' }}>Results not found!</h4>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    friends: state.friends.data
});

export default connect(mapStateToProps)(People);