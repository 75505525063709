const FooterLinks = [
    {
        name: 'Terms & Conditions',
        url: 'https://happyfacess.com/terms_conditions'
    },
    {
        name: 'Privacy Policy',
        url: 'https://happyfacess.com/privacy_policy'
    },
    {
        name: 'Contact Us',
        url: 'https://happyfacess.com/user_contact'
    },
    {
        name: 'Feedback',
        url: 'https://happyfacess.com/feedback'
    },
    {
        name: 'About Us',
        url: 'https://happyfacess.com/about_us'
    }
]

export default FooterLinks;