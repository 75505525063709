import React, { useEffect, useState, useRef } from "react";
import ProfileImage from '../assets/images/profile2.jpg';
import FaceIcon from '../assets/images/face.svg';
import BackIcon from '../assets/images/angle-left.svg';
import { Link, Navigate, useParams, useNavigate, useSearchParams, useLocation } from "react-router-dom";
import CloseIcon from '../assets/images/plus.svg';
import ChatAnimation from '../assets/images/chat.gif';
import { connect } from "react-redux";
import AddIcon from '../assets/images/plus.svg';
import ChatFrieds from "./ChatFriends";
import { create_message, get_chat, get_chats, create_chat, get_muted_chats, set_muted_chat_func } from "../store/actions/chat";
import { filesUrl, socket } from "../App";
import { send_notification } from "../store/actions/notification";
import { clear_profile, get_user, remove_unread_messages } from "../store/actions/users";
import TestImage from '../assets/images/test.jpg';
import { upload_image } from "../assets/utils/upload";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ImagePlaceholder from "./ImagePlaceholder";
import BackBtn from '../assets/images/angle-left.svg';
import SendIcon from '../assets/images/paper-plane.svg';
import PictureIcon from '../assets/images/picture.svg';
import ChatImagePopup from "./ChatImagePopup";
import MenuDots from '../assets/images/menu-dots.svg';
import { toast } from "react-toastify";
import ImagePreview from "./ImagePreview";
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { ReactComponent as MuteIcon } from '../assets/images/mute.svg';
import { ReactComponent as WarnIcon } from '../assets/images/warn.svg';
import { ReactComponent as CloseIcon2 } from '../assets/images/plus.svg';
import axios from "axios";
import FeedGroupsLoading from "./Loading/FeedGroupsLoading";
import { Spinner } from "loading-animations-react";
import IsLoggedIn from "./IsLoggedIn";
import { ReactComponent as AngleIcon } from '../assets/images/angle-down.svg';
import _ from "lodash";
import { play_audio } from "../assets/utils/notify_sound";
import { ReactComponent as PlusIcon } from '../assets/images/more-posts.svg';

const Chat = (props) => {

    const [open, setOpen] = useState(false);
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const [message, setMessage] = useState('');
    const [openEmoji, setOpenEmoji] = useState(false);
    const [friendsOpen, setFriendsOpen] = useState(false);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [messages, setMessages] = useState(null);
    const [reciveMessage, setReciveMessage] = useState({});
    const [searchtxt, setSearchtxt] = useState('');
    const [image, setImage] = useState(null);
    const [imgPopup, setImgPopup] = useState(false);
    const [uploadImage, setUploadImage] = useState(null);
    const [openMenu, setOpenMenu] = useState(false);
    const [tempNotifi, setTempNotifi] = useState([]);
    const [previewImage, setPreviwImage] = useState(null);
    const [chatUrl, setChatUrl] = useState(null);
    const [replyBox, setReplyBox] = useState(false);
    const [currentChatBlocked, setCurrentChatBlocked] = useState({ blocked_by: false });
    const [taggedMessage, setTaggedMessage] = useState(null);
    const [taggedMessageAuthor, setTaggedMessageAuthor] = useState(null);
    const [currentMessages, setCurrentMesssages] = useState(0);
    const [messageLoading, setMessageLoading] = useState(false);
    const [allMessages, setAllMessages] = useState(0);
    const [doneFirst, setDoneFirst] = useState(false);
    const [currentChat, setCurrentChat] = useState(null);
    const [numberOfChats, setNumberOfChats] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);
    const [chats, setChats] = useState(null);
    const [unread_messages, set_unread_messages] = useState([]);
    const [loaded, setLoaded] = useState(false);
    const scroll = useRef();
    const menuRef = useRef();
    const emojiRef = useRef();
    const chatBody = useRef();


    const { id } = useParams();

    useEffect(() => {
        if (taggedMessage) setReplyBox(true);
        else setReplyBox(false);
    }, [taggedMessage]);

    const getMessages = () => {
        setMessageLoading(true);
        // const uniqChat = _.uniqBy(messages, 'id');
        // setMessages(uniqChat)
        // if (loaded) {
        //     scroll.current?.scrollIntoView({ behavior: "smooth" });
        // } else {
        //     scroll.current?.scrollIntoView();
        // }
        axios.get(`/chat/${id}?message=${currentMessages}`).then(res => {
            setCurrentMesssages(prev => prev + 20);
            if (res.data.chat) {
                if (!messages) setMessages([...res.data.chat]);
                else {
                    setMessages([...res.data.chat, ...messages]);
                }

                setAllMessages(res.data.number_of_messages);
                setMessageLoading(false);
            }
            // if (!doneFirst) {
            //     setCurrentMesssages(prev => prev + 20);
            // }
            // setMessageLoading(false);
            // if (res.data.chat) {
            //     if (messages !== null && res.data.number_of_messages > 20) {
            //         setMessages([...res.data.chat, ...messages]);
            //     }
            //     else setMessages([...res.data.chat]);
            // }
            // if (currentMessages == 0) {
            //     setDoneFirst(true);
            // }
        }).catch(error => {
            setMessages(undefined);
        });
    };

    const onScroll = (event) => {
        const { scrollTop } = event.target;
        if (scrollTop <= 20 && !messageLoading && currentMessages <= allMessages && allMessages > 20 && loaded) {
            // setCurrentMesssages(prev => prev + 20);

            // const uniqChat = _.uniqBy(messages, 'id');
            // setMessages(uniqChat)
        }
        // onScrollToBottom();
    }

    useEffect(() => {
        if (currentMessages < 41) {
            // scroll.current?.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages, currentMessages]);

    const navigate = useNavigate();

    const go_back = () => {
        // if (window.history.state && window.history.state.idx > 0) {
        //     navigate(-1);
        // } else {
        // }
        navigate('/', { replace: true });
    }

    const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(emojiObject);
        setMessage(message + emojiObject.native)
    }

    const handleMute = (reciver_id) => {
        setOpenMenu(false);
        if (reciver_id) socket.emit('mute-chat', { chat_id: id, user_id: props.user.id, reciver_id: reciver_id, from_username: props.user.username });
    }

    const handleUnMute = (reciver_id) => {
        setOpenMenu(false);
        socket.emit('unmute-chat', { chat_id: id, user_id: props.user.id, reciver_id: reciver_id, from_username: props.user.username });
    }

    const handleOpen = () => {
        if (open) setOpen(false);
        else setOpen(true);
    }

    const handleMessageChange = (event) => {
        setMessage(event.target.value);
    }

    const mark_as_read_messages = (chat_id) => {
        // props.remove_unread_messages(chatId);
        axios.delete(`/user/unread/${chat_id}`).then(res => {
            const filtered = unread_messages.filter(item => item.chat_id == chat_id);
            filtered.map((_, index) => {
                const newArr = [...unread_messages];
                const itemIndex = newArr.findIndex(item => item.chat_id == filtered[index].chat_id);
                if (itemIndex !== -1) {
                    newArr.splice(itemIndex, 1);
                }
                set_unread_messages([...newArr]);
            });
        }).catch(_error => { });
    }

    const getChat = () => {
        setCurrentChat(null);
        axios.get(`/chat/details/${id}`).then(res => {
            setCurrentChat(res.data.chat);
            scroll.current?.scrollIntoView({ behavior: "smooth" });
        }).catch(_error => {
            setCurrentChat(undefined);
        });
    }

    const get_chat_count = () => {
        axios.get('/chat/count').then(res => {
            setNumberOfChats(res.data.chats);
        });
    }

    useEffect(() => {
        if (currentChat) getMessages();
    }, [currentChat])

    useEffect(() => {
        setMessages(null);
        setCurrentMesssages(0);
        setCurrentChatBlocked(null);
        if (props.user && props.user.id) {
            mark_as_read_messages(id);
        }
        if (id) {
            getChat();
            checkChatBlock();
        }
    }, [id]);

    useEffect(() => {
        setChats(props.chats);
        get_chat_count();
    }, [props.chats]);

    useEffect(() => {
        if (currentChat) {
            setMessages(null);
            setCurrentMesssages(0);
            setAllMessages(0);
        }
    }, [currentChat]);

    const handleMessageWithImage = async (event) => {
        scroll.current?.scrollIntoView();
        event.preventDefault();
        if (event.target.files[0]) {
            const type = event.target.files[0].type.split('/')[0];
            if (type == 'image') {
                upload_image(event.target.files[0], 'chat', props.user.token).then(uploaded_image => {
                    const chat_user = props.chats.find((cht) => cht.id == id);
                    if (uploaded_image) {
                        props.create_message({ chat_id: id, text: message, reciver_id: chat_user.reciver_id, image: `${filesUrl}/static/chat/${uploaded_image}` });
                        const newMessage = { id: id, text: null, author: props.user.id, reciver_id: chat_user.reciver_id, from_username: props.user.username, image: `${filesUrl}/static/chat/${uploaded_image}` }
                        socket.emit('send-message', newMessage);
                    }
                    const newMessageWithImage = { id: id, text: null, author: props.user.id, reciver_id: chat_user.reciver_id, image: URL.createObjectURL(event.target.files[0]) }
                    setMessages([...messages, newMessageWithImage]);
                    setMessage('');
                    send_notification(socket, { message: true, from_id: props.user.id, to_id: chat_user.reciver_id, from_username: props.user.username, notification: `You have message ${props.user.name}` });
                }).catch(_error => {
                    toast.warn('Image upload error!');
                });
            } else toast.warn('Invalid file type!');
        }
    }

    const handleUploadImage = (event) => {
        if (event.target.files[0]) {
            const type = event.target.files[0].type.split('/')[0];
            if (type == 'image') {
                if (event.target.files[0].size <= 8388608) {
                    setImgPopup(true);
                    setImage(URL.createObjectURL(event.target.files[0]));
                    setUploadImage(event);
                } else toast.warn('Image too large!');
            } else {
                toast.warn('Invalid file type!');
                setImgPopup(false);
            };
        }
    }

    const sendImage = () => {
        if (uploadImage) {
            handleMessageWithImage(uploadImage);
            setImgPopup(false);
        }
    }

    const handleMessage = (event) => {
        scroll.current?.scrollIntoView();
        if (event) event.preventDefault();
        if (message.length > 0) {
            const chat_user = props.chats.find((cht) => cht.id == id);
            props.create_message({ chat_id: id, text: message, reciver_id: chat_user.reciver_id, image: null, tag_message: replyBox ? taggedMessage : null, tag_message_author: taggedMessageAuthor });
            const newMessage = { id: id, text: message, author: props.user.id, tag_message: replyBox ? taggedMessage : null, from_id: props.user.id, to_id: chat_user.reciver_id, reciver_id: chat_user.reciver_id, image: null, from_username: props.user.username, chat_id: id, user_id: props.user.id, tag_message_author: taggedMessageAuthor }
            if (messages) {
                setMessages([...messages, newMessage]);
            } else {
                setMessages([newMessage]);
            }
            setMessage('');
            socket.emit('send-message', newMessage);
            send_notification(socket, { message: true, from_id: props.user.id, to_id: chat_user.reciver_id, from_username: props.user.username, notification: `You have message ${props.user.name}` });
            setReplyBox(false);
            setTaggedMessage(null);
            setTaggedMessageAuthor(null);
        }
    }

    useEffect(() => {

        if (!id) {
            setOpen(true);
        }

        props.get_chats();
        socket.on('recive-message', (reciveMessage) => {
            setReciveMessage(reciveMessage);
            scroll.current?.scrollIntoView();
        });
        props.clear_profile();

        if (props.user && props.user.id) {
            socket.emit('add-chat-user', props.user.id);
        }

        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) setOpenMenu(false);
            if (emojiRef.current && !emojiRef.current.contains(event.target)) setOpenEmoji(false);
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);

    }, []);

    socket.on('recive-mute-chat', (data) => {
        if (!data.success) {
            toast.warn(data.message);
        } else {
            if (data.add) {
                data = data.result;
                props.set_muted_chat_func([...props.muted_chats, { id: data.id, chat_id: data.chat_id, user_id: data.user_id, reciver_id: data.reciver_id }]);
            }
            else {
                const newMutedChats = [...props.muted_chats];
                const chatIndex = newMutedChats.findIndex((chat) => chat.id == data.id);
                newMutedChats.splice(chatIndex, 1);
                props.set_muted_chat_func([...newMutedChats]);
            }
        }
    });

    useEffect(() => {

        if (props.user && props.user.id && id) {
        }

    }, [props.user]);

    useEffect(() => {
        if (props.user && props.user.id) {
            socket.emit('add-new-user', (props.user.id));
        }
        socket.on('get-users', (users) => {
            setOnlineUsers(users);
        });
    }, []);

    useEffect(() => {
        if (messages !== null) {
            if (reciveMessage.id == id) {
                if (messages) setMessages([...messages, reciveMessage]);
                else {
                    setMessages([reciveMessage]);
                }
                scroll.current?.scrollIntoView();
            }
        }
    }, [reciveMessage]);

    useEffect(() => {
        setLoaded(false);
        setAllMessages(0);
        setCurrentMesssages(0);
        setDoneFirst(false);
        // if (id) scroll.current?.scrollIntoView({ behavior: "smooth" });
    }, [id]);

    useEffect(() => {
        if (messages && messages.length > 0) {
            setTimeout(() => {
                setLoaded(true);
            }, [3000]);
        }
        if (currentMessages < 21) {
            scroll.current?.scrollIntoView({ behavior: "smooth" });
        }
        // if (!loaded) scroll.current?.scrollIntoView();
    }, [messages]);



    const handleMessageUrl = (fri_id) => {
        axios.get(`/chat/fri/${props.profile.id}`).then(res => {
            if (res.data.chat) {
                setChatUrl({ fri_id, url: res.data.chat });
            }
        })
    }

    const checkChatBlock = () => {
        axios.get(`/chat/block/${id}`).then(res => {
            setCurrentChatBlocked(res.data);
        }).catch(_error => {
            toast.warn('Your details error !');
        });
    }

    const handleBlockUser = (fri_id) => {
        setCurrentChatBlocked(null);
        axios.post(`/chat/block/${fri_id}/${id}`, { from_username: props.user.username }).then(res => {
            setCurrentChatBlocked({ blocked_by: true, blocked: true });
            socket.emit('block-user', { notifi: res.data.notification, data: { blocked_by: false, blocked: true } });
        }).catch(_error => {
            toast.warn('Your details error!');
        });
    }

    const handleUnBlockUser = (fri_id) => {
        setCurrentChatBlocked(null);
        axios.put(`/chat/block/${fri_id}`, { from_username: props.user.username }).then(res => {
            setCurrentChatBlocked({ blocked_by: false, blocked: false });
            socket.emit('unblock-user', { notifi: res.data.notification, data: { blocked_by: false, blocked: false } });
        }).catch(_error => {
            toast.warn('Your details error!');
        });
    }

    const block_user = (fri_id) => {
        socket.emit('block-user', { user_id: props.user.id, fri_id: fri_id, from_username: props.user.username });
    }

    const unblock_user = (fri_id) => {
        socket.emit('unblock-user', { user_id: props.user.id, fri_id: fri_id, from_username: props.user.username });
    }

    const handleGetMoreChats = () => {
        axios.get(`/chat?page=${currentPage}`).then(res => {
            setCurrentPage(prev => prev + 20);
            if (res.data.chats.length > 0) setChats([...chats, ...res.data.chats]);
        }).catch(_error => {
            toast.warn('Your chat error!');
        });
    }

    const create_unread_messages = (data) => {
        if (window.location.pathname == '/chat') {
            axios.post('/chat/unread', data).then(res => {
                set_unread_messages(prev => [...prev, data]);
            }).catch(error => {
            });
        }
    }

    useEffect(() => {

        socket.on('recive-block-user', (data) => {
            setCurrentChatBlocked(data.data);
        });

        socket.on('recive-unblock-user', (data) => {
            setCurrentChatBlocked(data.data);
        });

        socket.on('recive-chat-notifi', (data) => {
            if (id !== data.chat_id) {
                if (!unread_messages.some((msg) => msg.chat_id == data.chat_id)) {
                    create_unread_messages(data);
                    // play_audio();
                }
            }
        });

    }, []);

    // useEffect(() => {
    //     const handleUrlChange = () => {
    //         // setCurrentLocation(window.location.pathname);
    //     };
    //     window.addEventListener('popstate', handleUrlChange);
    //     return () => {
    //         window.removeEventListener('popstate', handleUrlChange);
    //     };
    // }, [])


    const get_unread_messages = () => {
        if (props.user && props.user.id) {
            axios.get(`/chat/unread/${props.user.id}`).then(res => {
                set_unread_messages(res.data.messages);
            });
        }
    }

    window.onpopstate = (event) => {
        if (document.location.pathname == '/chat' || document.location.pathname == '/chat/') setOpen(true);
    }

    useEffect(() => {
        if (document.location.pathname == '/chat' || document.location.pathname == '/chat/') setOpen(true);
    }, [document.URL])

    useEffect(() => {
        if (document.location.pathname == '/chat' || document.location.pathname == '/chat/') setOpen(true);
    }, [document.location.pathname]);

    useEffect(() => {
        get_unread_messages();
    }, []);

    return (
        <IsLoggedIn>
            <div style={{ overflowX: 'hidden' }} className="chat-area">
                {imgPopup && (
                    <ChatImagePopup handleSendImage={() => sendImage()} image={image} handleClose={() => setImgPopup(!imgPopup)} />
                )}
                {previewImage && (
                    <ImagePreview image={previewImage} handleClose={() => setPreviwImage(null)} />
                )}
                {props.user !== null ? (
                    <>
                        {props.user && props.user.id ? (
                            <>
                                {props.user && props.user.active ? (
                                    <div className="chat-main">
                                        <div className={open ? 'left left-active' : 'left'}>
                                            <div className="top">
                                                <div className="user">
                                                    <img src={BackBtn} onClick={go_back} style={{ width: '20px', marginRight: '8px', cursor: 'pointer' }} alt="go-home" />
                                                    <div className="img" style={{ backgroundImage: `url(${props.user.image ? props.user.image : filesUrl + '/static/user/default.png'})` }}></div>
                                                    <section>
                                                        <h5>{props.user.name}</h5>
                                                        <p>@{props.user.username}</p>
                                                    </section>
                                                </div>
                                                {/* <img onClick={handleOpen} src={CloseIcon} alt="close" className="close-icon" /> */}
                                            </div>
                                            {props.chats !== null ? (
                                                <ul className="user-chats">
                                                    {chats && chats.length > 0 ? (
                                                        <>
                                                            {chats && chats.map((chat, key) => (
                                                                <Link style={{ textDecorationLine: 'none', color: '#000' }} to={`/chat/${chat.id}`}>
                                                                    <li key={key} className={id && id == chat.id && 'gray'} onClick={handleOpen}>
                                                                        <section>
                                                                            <div className="img" style={{ backgroundImage: `url(${chat.image ? chat.image : filesUrl + '/static/user/default.png'})` }}></div>
                                                                            <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                                <h4>@{chat.username}</h4>
                                                                                {chat.last_message && (
                                                                                    <p style={{ fontSize: '0.7rem', fontWeight: '400', marginLeft: '15px', color: '#999' }}>{chat.last_message && chat.last_message.text.length > 9 ? `${chat.last_message.text.substr(0, 10)}...` : chat.last_message.text}</p>
                                                                                )}
                                                                            </section>
                                                                        </section>
                                                                        <div className="icons">
                                                                            {props.user && unread_messages.some(unmsg => unmsg.chat_id == chat.id) && (
                                                                                <span style={{ width: '3px', height: '3px', backgroundColor: '#87a8fb', borderRadius: '50%' }}></span>
                                                                            )}
                                                                            {props.muted_chats && props.muted_chats.some((mchat) => mchat.reciver_id == chat.reciver_id) && (
                                                                                <MuteIcon />
                                                                            )}
                                                                        </div>
                                                                    </li>
                                                                </Link>
                                                            ))}
                                                            {props.chats.length < numberOfChats && (
                                                                <Link onClick={handleGetMoreChats} style={{ textDecorationLine: 'none', color: '#000' }} to='#'>
                                                                    <li className="gray" onClick={handleOpen}>
                                                                        <section>
                                                                            <section style={{ display: 'flex', justifyContent: 'flex-start' }}>
                                                                                <div style={{ width: '45px', height: '45px', borderRadius: '50%', backgroundColor: 'rgba(0,0,0,0.1)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                                    <AngleIcon style={{ width: '15px', fill: '#777', height: 'fit-content' }} />
                                                                                </div>
                                                                                <h4>see more</h4>
                                                                            </section>
                                                                        </section>
                                                                    </li>
                                                                </Link>
                                                            )}
                                                        </>
                                                    ) : (
                                                        <FeedGroupsLoading enableAnimation={false} />
                                                    )}
                                                </ul>
                                            ) : (
                                                <div style={{ marginTop: '10px' }}>
                                                    {/* <h4 className="not-found1">Not chats found</h4> */}
                                                    <FeedGroupsLoading enableAnimation={true} />
                                                </div>
                                            )}
                                        </div>
                                        {id ? (
                                            <>
                                                {currentChat !== null ? (
                                                    <div className="right">
                                                        {currentChat == undefined ? (
                                                            <Navigate to='/chat' />
                                                        ) : (
                                                            <>
                                                                <div className="top">
                                                                    <div className="user">
                                                                        <section>
                                                                            <img onClick={handleOpen} src={BackIcon} alt="back" className="back-icon" />
                                                                            <Link style={{ textDecorationLine: 'none', color: '#000', display: 'flex', alignItems: 'center' }} to={`/user/${currentChat.user.id}`}>
                                                                                <div className="img" style={{ backgroundImage: `url(${currentChat.image})` }}></div>
                                                                                <section style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                                                                    <h5>@{currentChat.user.username}</h5>
                                                                                    {/* {onlineUsers.map(oluser => (
                                                                                        <> */}
                                                                                    {onlineUsers.some(user => user.userId == currentChat.user.id) && (
                                                                                        <p style={{ fontSize: '0.7rem', fontWeight: '400', color: 'blue', marginLeft: '15px' }}>online</p>
                                                                                    )}
                                                                                    {/* </>
                                                                                    ))} */}
                                                                                </section>
                                                                            </Link>
                                                                        </section>
                                                                        {currentChatBlocked !== null && (
                                                                            <div className="menu-icon">
                                                                                <img onClick={() => setOpenMenu(openMenu ? false : true)} style={{ width: '20px', marginRight: '15px', transform: 'rotate(90deg)' }} src={MenuDots} alt="menu" />
                                                                                <div ref={menuRef} handleClose={() => setOpenMenu(false)} className={openMenu ? 'menu active' : 'menu'}>
                                                                                    <ul>
                                                                                        {currentChatBlocked && currentChatBlocked.blocked && currentChatBlocked.blocked_by ? (
                                                                                            <li onClick={() => {
                                                                                                setOpenMenu(false)
                                                                                                handleUnBlockUser(props.chats && props.chats.find(chat => chat.id == id) && props.chats.find(chat => chat.id == id).reciver_id)
                                                                                            }}>Unblock user</li>
                                                                                        ) : (
                                                                                            <>
                                                                                                {currentChatBlocked.blocked ? (
                                                                                                    <>
                                                                                                        {currentChatBlocked.blocked_by && (
                                                                                                            <li onClick={() => {
                                                                                                                setOpenMenu(false)
                                                                                                                handleBlockUser(props.chats && props.chats.find(chat => chat.id == id) && props.chats.find(chat => chat.id == id).reciver_id)
                                                                                                            }}>Block user</li>
                                                                                                        )}
                                                                                                    </>
                                                                                                ) : (
                                                                                                    <li onClick={() => {
                                                                                                        setOpenMenu(false)
                                                                                                        handleBlockUser(props.chats && props.chats.find(chat => chat.id == id) && props.chats.find(chat => chat.id == id).reciver_id)
                                                                                                    }}>Block user</li>
                                                                                                )}
                                                                                            </>
                                                                                        )}
                                                                                        {props.muted_chats.find((chat) => chat.chat_id == id && chat.user_id == props.user.id) ? (
                                                                                            <li onClick={() => handleUnMute(props.chats && props.chats.find(chat => chat.id == id).reciver_id)}>Unmute</li>
                                                                                        ) : (
                                                                                            <li onClick={() => handleMute(props.chats && props.chats.find(chat => chat.id == id).reciver_id)}>Mute</li>
                                                                                        )}
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                                <div ref={chatBody} onScroll={onScroll} className="body">
                                                                    {allMessages > currentMessages && (
                                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                                            <button style={{ marginTop: '-5px' }} onClick={getMessages} className="more-post-btn">
                                                                                <PlusIcon />
                                                                                <p>more</p>
                                                                            </button>
                                                                        </div>
                                                                    )}
                                                                    {messages !== null ? (
                                                                        <>
                                                                            {messages && messages.length > 0 ? (
                                                                                <>
                                                                                    {messages.map((msg, index) => (
                                                                                        <div key={index}>
                                                                                            {msg.author == props.user.id ? (
                                                                                                <>
                                                                                                    {msg.text && (
                                                                                                        <>
                                                                                                            <div ref={scroll} className="message my_message">
                                                                                                                <section style={msg.tag_message ? { borderRadius: '15px 15px 0px 15px', margin: '8px 5px', backgroundColor: 'rgba(0,0,0,0.1)' } : {}}>
                                                                                                                    {msg.tag_message && (
                                                                                                                        <div className="taged_message">
                                                                                                                            <p style={{ backgroundColor: msg.tag_message_author == props.user.id ? 'rgba(0, 0, 0, 0.1)' : 'rgb(0, 123, 255)', color: msg.tag_message_author == props.user.id ? '#000' : '#fff', borderRadius: '15px 15px 15px 0px' }}>{msg.tag_message.length > 145 ? `${msg.tag_message.substr(0, 145)}...` : msg.tag_message}</p>
                                                                                                                        </div>
                                                                                                                    )}
                                                                                                                    <p
                                                                                                                        style={{ cursor: 'pointer' }}
                                                                                                                        onClick={() => {
                                                                                                                            setTaggedMessage(msg.text)
                                                                                                                            setTaggedMessageAuthor(msg.author)
                                                                                                                        }}>
                                                                                                                        {msg.text}
                                                                                                                    </p>
                                                                                                                </section>
                                                                                                            </div>
                                                                                                        </>
                                                                                                    )}
                                                                                                    {msg.image && (
                                                                                                        <div ref={scroll} className="message my_message">
                                                                                                            <section>
                                                                                                                <LazyLoadImage onClick={() => setPreviwImage(msg.image)} effect="blur" placeholderSrc={process.env.PUBLIC_URL + 'image-placeholder.jpg'} ref={scroll} style={{ width: '100%', maxWidth: '300px', height: 'fit-content', maxHeight: '400px', borderRadius: '5px' }} src={msg.image} alt="chat" />
                                                                                                            </section>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (
                                                                                                <>
                                                                                                    {msg.text && (
                                                                                                        <div ref={scroll} className="message frined_message">
                                                                                                            <section style={msg.tag_message ? { borderRadius: '0px 15px 15px 15px', margin: '8px', backgroundColor: 'rgba(0,0,0,0.1)' } : {}}>
                                                                                                                {msg.tag_message && (
                                                                                                                    <div className="taged_message">
                                                                                                                        <p style={{ backgroundColor: msg.tag_message_author == props.user.id ? 'rgba(0, 0, 0, 0.1)' : 'rgb(0, 123, 255)', color: msg.tag_message_author == props.user.id ? '#000' : '#fff', borderRadius: '15px 15px 0px 15px' }}>{msg.tag_message.length > 145 ? `${msg.tag_message.substr(0, 145)}...` : msg.tag_message}</p>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                                <p
                                                                                                                    style={{ cursor: 'pointer' }}
                                                                                                                    onClick={() => {
                                                                                                                        setTaggedMessageAuthor(msg.author)
                                                                                                                        setTaggedMessage(msg.text)
                                                                                                                    }}>
                                                                                                                    {msg.text}
                                                                                                                </p>
                                                                                                            </section>
                                                                                                        </div>
                                                                                                    )}
                                                                                                    {msg.image && (
                                                                                                        <div ref={scroll} className="message frined_message">
                                                                                                            <section>
                                                                                                                <LazyLoadImage placeholderSrc={process.env.PUBLIC_URL + 'image-placeholder.jpg'} onClick={() => setPreviwImage(msg.image)} useIntersectionObserver={true} delayTime={300} effect="blur" ref={scroll} style={{ width: '100%', maxWidth: '300px', height: 'fit-content', borderRadius: '5px', maxHeight: '400px' }} src={msg.image} alt="chat" />
                                                                                                            </section>
                                                                                                        </div>
                                                                                                    )}
                                                                                                </>
                                                                                            )}
                                                                                        </div>
                                                                                    ))}
                                                                                </>
                                                                            ) : (
                                                                                <div className="not-found2">
                                                                                    {/* <h4 style={{ backgroundColor: '#f5f5f5', width: 'fit-content', padding: '10px', borderRadius: '8px' }}>You already haven't chat with this person</h4> */}
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {currentMessages < allMessages && (
                                                                                <>
                                                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '15px', marginBottom: '15px' }}>
                                                                                        <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                                                                                    </div>
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className="chat-bottom-area">
                                                                    {currentChatBlocked !== null ? (
                                                                        <>
                                                                            {!currentChatBlocked.blocked_by ? (
                                                                                <>
                                                                                    {currentChatBlocked.blocked ? (
                                                                                        <div style={{ width: '100%', padding: '10px', display: 'flex', justifyContent: 'center' }}>
                                                                                            <h4 style={{ width: 'fit-content', padding: '10px', textAlign: 'center', color: '#444', backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: '8px', fontSize: '0.8rem' }}>Blocked you</h4>
                                                                                        </div>
                                                                                    ) : (
                                                                                        <>
                                                                                            {replyBox && (
                                                                                                <div className="taged_reply">
                                                                                                    <p>
                                                                                                        <CloseIcon2 onClick={() => {
                                                                                                            setReplyBox(false)
                                                                                                            setTaggedMessage(null)
                                                                                                            setTaggedMessageAuthor(null)
                                                                                                        }} />
                                                                                                        {taggedMessage && taggedMessage.length > 149 ? `${taggedMessage.substr(0, 148)}...` : taggedMessage}
                                                                                                    </p>
                                                                                                </div>
                                                                                            )}
                                                                                            <form onSubmit={handleMessage} className="bottom">
                                                                                                <div style={{ position: 'relative' }}>
                                                                                                    {openEmoji && (
                                                                                                        <div ref={emojiRef} style={{ position: 'absolute', bottom: '30px' }} >
                                                                                                            <Picker data={data} onEmojiSelect={(e) => onEmojiClick(null, e)} />
                                                                                                        </div>
                                                                                                    )}
                                                                                                    <img style={{ marginLeft: '10px' }} onClick={() => openEmoji ? setOpenEmoji(false) : setOpenEmoji(true)} src={FaceIcon} alt="emojies" />
                                                                                                </div>
                                                                                                <input hidden id="img" type="file" accept="image/*" onChange={(e) => handleUploadImage(e)} />
                                                                                                <input onChange={handleMessageChange} value={message} placeholder="Type here..." />
                                                                                                {message.length > 0 ? (
                                                                                                    <div onClick={handleMessage} className="send-icon svg">
                                                                                                        <img src={SendIcon} alt="send" />
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    <label className="svg" style={{ display: 'flex', height: 'fit-content' }} for="img">
                                                                                                        <img src={PictureIcon} style={{ width: '20px', height: 'fit-content' }} />
                                                                                                    </label>
                                                                                                )}
                                                                                            </form>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <div style={{ width: '100%', padding: '10px', display: 'flex', justifyContent: 'center' }}>
                                                                                    <h4 style={{ width: 'fit-content', padding: '10px', textAlign: 'center', color: '#444', backgroundColor: 'rgba(0,0,0,0.1)', borderRadius: '8px', fontSize: '0.8rem' }}>You blocked this user</h4>
                                                                                </div>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '15px', marginBottom: '15px' }}>
                                                                            <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', marginTop: '15px', marginBottom: '15px' }}>
                                                        <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div className="without-messages">
                                                <img src={ChatAnimation} alt="chat" />
                                                {/* <h4>Not friend selected</h4> */}
                                                {/* <button onClick={handleOpen}>start</button> */}
                                            </div>
                                        )}
                                    </div>
                                ) : (
                                    <h4 style={{ color: '#cc3300', fontSize: '0.8rem', display: 'flex', alignItems: 'center' }}><WarnIcon style={{ fill: '#cc3300', width: '25px', height: 'fit-content' }} />&nbsp; You blocked by admin</h4>
                                )}
                            </>
                        ) : (<Navigate to='/' />)}
                    </>
                ) : (
                    <h4>Loading...</h4>
                )}
            </div>
        </IsLoggedIn>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    chats: state.chats.chats,
    messages: state.message.data,
    muted_chats: state.muted_chats.data,
    friends: state.friends.data
});

const mapDispatchToProps = (dispatch) => ({
    get_chats: () => dispatch(get_chats()),
    get_chat: (id) => dispatch(get_chat(id)),
    create_message: (data) => dispatch(create_message(data)),
    clear_profile: () => dispatch(clear_profile()),
    create_chat: (id) => dispatch(create_chat(id)),
    get_user: () => dispatch(get_user()),
    remove_unread_messages: (chatId) => dispatch(remove_unread_messages(chatId)),
    get_muted_chats: () => dispatch(get_muted_chats()),
    set_muted_chat_func: (data) => dispatch(set_muted_chat_func(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Chat);