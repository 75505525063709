import React, { useEffect, useState } from "react";
import Appbar from "./Appbar";
import { ReactComponent as CreateIcon } from '../../assets/images/plus.svg';
import { ReactComponent as DeleteIcon } from '../../assets/images/trash.svg';
import { get_groups, set_groups_func } from "../../store/actions/admin";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { upload_image } from "../../assets/utils/upload";
import axios from "axios";
import { Link } from "react-router-dom";
import { search_groups } from "../../store/actions/group";

const AdminGroups = (props) => {

    const [image, setImage] = useState(null);
    const [height, setHeight] = useState(70);
    const [openCreate, setOpenCreate] = useState(false);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [tags, setTags] = useState('');
    const [looadingBtn, setLoadingBtn] = useState(false);
    const [searchTxt, setSearchTxt] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const group_create = async (data) => {
        if (data.image.type.split('/')[0] == 'image') {
            if (data.image.size < 8388608) {
                upload_image(data.image, 'group', props.user.token).then(uploadImage => {
                    data.image = uploadImage;
                    axios.post('/admin/groups', data).then(res => {
                        if (res.data.group) props.set_groups([res.data.group, ...props.groups]);
                        setLoadingBtn(false);
                        setOpenCreate(false);
                    }).catch(_error => {
                        setLoadingBtn(false);
                        setOpenCreate(false);
                        toast.warn('Group details error!');
                    });
                }).catch(error => {
                    toast.warn('Image uploading error!');
                });
            }
        } else toast.warn('Invalid file type!');
    }

    const handleCreate = () => {
        if (name && tags && image && description) {
            setLoadingBtn(true);
            group_create({ name, tags, image, about: description });
        } else toast.warn("Please provide all fields");
    }

    const handleRemove = (id) => {
        const anwser = window.confirm('Are you sure ? This will delete group');
        if (anwser) {
            axios.delete(`/admin/groups/${id}`).then(_res => {
                const newGroupsArr = [...props.groups];
                const groupIndex = newGroupsArr.findIndex((group) => group.id == id);
                if (groupIndex !== -1) {
                    newGroupsArr.splice(groupIndex, 1);
                    props.set_groups([...newGroupsArr]);
                }
            }).catch(_error => {
                toast.warn('Group remove error!');
            });
        }
    }

    useEffect(() => {
        props.get_groups();
    }, []);

    useEffect(() => {
        props.search_groups(searchTxt);
    }, [searchTxt]);

    useEffect(() => {
        setSearchResults(props.search);
    }, [props.search]);

    return (
        <div className="admin-area">
            <Appbar />
            <div className="admin-groups">
                <form>
                    <div className="top">
                        <h4>Groups</h4>
                        <CreateIcon style={{ fill: 'rgba(255,255,255,0.8)' }} onClick={() => setOpenCreate(true)} />
                    </div>
                    <div className="users">
                        <div className="search">
                            <input onChange={(e) => setSearchTxt(e.target.value)} type="text" placeholder="Search users" />
                        </div>
                        <ul>
                            {props.groups !== null ? (
                                <>
                                    {searchResults && searchResults.length <= 0 && searchTxt.length > 0 ? (
                                        <h5 style={{ width: '100%', textAlign: 'center', marginTop: '10px', color: '#555' }}></h5>
                                    ) : (
                                        <>
                                            {searchResults && (searchResults.length > 0 ? searchResults : props.groups).map((group, key) => (
                                                <li key={key}>
                                                    <section>
                                                        <div className="img" style={{ backgroundImage: `url(${group.image})` }}></div>
                                                        <h5 style={{ cursor: 'pointer' }}>
                                                            <Link style={{ color: '#444', textDecorationLine: 'none' }} to={`/admin/groups/${group.id}`}>{group.name}</Link>
                                                        </h5>
                                                    </section>
                                                    <DeleteIcon onClick={() => handleRemove(group.id)} />
                                                </li>
                                            ))}
                                        </>
                                    )}
                                </>
                            ) : (
                                <h4>Loading...</h4>
                            )}
                        </ul>
                    </div>
                </form>
            </div>
            {openCreate && (
                <div className="create-group">
                    <div className="overlay"></div>
                    <div className="modal">
                        <div className="head">
                            <h5>create group</h5>
                            <CreateIcon onClick={() => setOpenCreate(false)} />
                        </div>
                        <div className="body">
                            <input onChange={(e) => setName(e.target.value)} className="el" type="text" placeholder="Enter group name" />
                            <textarea onChange={(e) => setDescription(e.target.value)} style={{ height: `${height}px` }} className="el" placeholder="Enter group description"></textarea>
                            <input id="image" type="file" hidden onChange={(event) => setImage(event.target.files[0])} />
                            {image ? (
                                <label htmlFor="image">
                                    <section for="image" className="el" style={{ backgroundImage: `url(${URL.createObjectURL(image)})` }}></section>
                                </label>
                            ) : (
                                <label htmlFor="image">
                                    <h5>Upload group image</h5>
                                </label>
                            )}
                            <div className="add-tags">
                                <input onChange={(e) => setTags(e.target.value)} className="el" type="text" placeholder="Enter group tags separated by comma" />
                            </div>
                            {looadingBtn ? (
                                <button>Loading...</button>
                            ) : (
                                <button onClick={() => handleCreate()}>create group</button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    groups: state.admin.groups,
    search: state.search.search_groups
});

const mapDispatchToProps = (dispatch) => ({
    get_groups: () => dispatch(get_groups()),
    set_groups: (data) => dispatch(set_groups_func(data)),
    search_groups: (text) => dispatch(search_groups(text))
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminGroups);