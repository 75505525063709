import React, { useEffect, useState } from "react";
import Appbar from "../Appbar";
import { connect } from "react-redux";
import axios from "axios";
import User from "./User";

const AllUsers = (props) => {

    const [searchTxt, setSearchTxt] = useState('');
    const [searchResults, setSearchResults] = useState(null);
    const [users, setUsers] = useState(null);

    useEffect(() => {
        axios.get('/admin/users').then(res => {
            setUsers(res.data.users);
        });
    }, []);

    useEffect(() => {
        axios.get(`/admin/users/${searchTxt}`).then(res => {
            setSearchResults(res.data.users);
        })
    }, [searchTxt]);

    useEffect(() => {
        setSearchResults(props.search);
    }, [props.search]);

    return (
        <div className="admin-area">
            <Appbar />
            <div className="admin-groups">
                <form onSubmit={(e) => e.preventDefault()}>
                    <div className="top">
                        <h4>Users</h4>
                    </div>
                    <div className="users">
                        <div className="search">
                            <input onChange={(e) => setSearchTxt(e.target.value)} type="text" placeholder="Search users" />
                        </div>
                        <ul>
                            {users !== null ? (
                                <>
                                    {searchResults && searchResults.length <= 0 && searchTxt.length > 0 ? (
                                        <h5 style={{ width: '100%', textAlign: 'center', marginTop: '10px', color: '#555' }}></h5>
                                    ) : (
                                        <>
                                            {searchResults ? (
                                                <>
                                                    {searchResults.filter(user => user.id !== props.user.id).map((user, key) => (
                                                        <User key={key} user={user} admin_user={props.admin_user} />
                                                    ))}
                                                </>
                                            ) : (
                                                <>
                                                    {users.filter(user => user.id !== props.user.id).map((user, key) => (

                                                        <User key={key} user={user} admin_user={props.admin_user} />
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <h4>Loading...</h4>
                            )}
                        </ul>
                    </div>
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    admin_user: state.admin_user.data
});

export default connect(mapStateToProps)(AllUsers);