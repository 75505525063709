import React, { useEffect, useState } from "react";
import axios from "axios";
import { ReactComponent as CreateIcon } from '../../../assets/images/plus.svg';
import { ReactComponent as BanIcon } from '../../../assets/images/ban.svg';
import { ReactComponent as UnBlockIcon } from '../../../assets/images/unlock.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/images/trash.svg';
import { toast } from "react-toastify";
import FroClearData from "./ForClearData";
import UserView from "../UserView";

const User = (props) => {

    const [popUpUser, setPopUpUser] = useState(null);
    const [open, setOpen] = useState(false);
    const [hideAdd, setHideAdd] = useState(null);
    const [popup, setPopup] = useState(null);
    const [openUserView, setOpenUserView] = useState(null);
    const [isBlocked, setIsBlocked] = useState(null);

    const handleClear = (id) => {
        const anwser = window.confirm('Are you sure, This will clear all user data');
        if (anwser) {
            axios.delete(`/admin/clear/${id}`).then(res => {
                toast.success(res.data.message);
            }).catch(_error => {
                toast.success('User data error!');
            });
        }
    }

    const handleGetUser = (id) => {
        axios.get(`/admin/user/${id}`).then(res => {
            setPopUpUser(res.data.user);
        }).catch(_error => {
            setPopUpUser({});
        });
    }

    const handleUserModal = (id) => {
        if (openUserView) {
            setOpen(null);
            setPopUpUser(null);
        } else {
            handleGetUser(id);
            setOpen(id);
        }
    }

    const handleBlock = (id, username) => {
        const anwser = window.confirm('Are you sure, This will block the user');
        if (anwser) {
            axios.post(`/admin/ban/${id}`, { username }).then(_res => {
                toast.success('blocked');
                setIsBlocked(id);
            }).catch(error => {
                if (error) {
                    if (error.response.status == 400) {
                        toast.warn('This user is already bocked');
                    }
                } else {
                    toast.warn('User credentials error!');
                }
            });
        }
    }

    const createAdmin = (id, username) => {
        const anwser = window.confirm('Are you sure, This will convert this user to admin');
        if (anwser) {
            axios.post(`/admin/user/${id}`, { username }).then(_res => {
                toast.success('Admin created');
                setHideAdd(id);
            }).catch(error => {
                setHideAdd(null)
                if (error.response.status == 400) {
                    toast.warn('This user is already bocked');
                } else {
                    toast.warn('User credentials error!');
                }
            });
        }
    }

    const handleUnBlock = (id) => {
        const anwser = window.confirm('Are you sure, This will unblock the user');
        if (anwser) {
            axios.delete(`/admin/ban/${id}`).then(_res => {
                toast.success('unblocked');
                if (isBlocked == id) setIsBlocked(null);
            }).catch(error => {
                if (error) {
                    if (error.response.status == 400) {
                        toast.warn('This user is already unblocked');
                    }
                } else {
                    toast.warn('User credentials error!');
                }
            })
        }
    }

    useEffect(() => {
        if (props.user && props.user.id) setIsBlocked(!props.user.active)
    }, [props.user]);

    return (
        <div>
            {popup == props.user.id && (
                <FroClearData handleClose={() => setPopup(false)} user={props.user} />
            )}
            {open == props.user.id && (
                <>
                    <UserView open={open} handleOpen={handleUserModal} user={popUpUser} />
                </>
            )}
            <li>
                <section>
                    <div className="img" style={{ backgroundImage: `url(${props.user.image})` }}></div>
                    <h5 onClick={() => handleUserModal(props.user.id)} style={{ cursor: 'pointer' }}>
                        {props.user.username}
                    </h5>
                </section>
                <div className="btns">
                    {hideAdd !== props.user.id && (
                        <>
                            {props.admin_user && props.admin_user.id && (
                                <>
                                    {!props.user.is_admin && (
                                        <acronym title="create admin">
                                            <button style={{ border: 'none', marginRight: '15px', background: 'transparent' }} onClick={() => createAdmin(props.user.id, props.user.username)}><CreateIcon style={{ fill: '#777', height: '20px' }} /></button>
                                        </acronym>
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {props.admin_user && props.admin_user.id && (
                        <>
                            {!props.user.is_admin && (
                                <acronym title="clear user data">
                                    <button style={{ border: 'none', marginRight: '15px', background: 'transparent' }} onClick={() => handleClear(props.user.id)} ><DeleteIcon style={{ width: '20px', height: '20px' }} /></button>
                                </acronym>
                            )}
                        </>
                    )}
                    {isBlocked ? (
                        <acronym title="unblock user">
                            <button style={{ border: 'none' }} onClick={() => handleUnBlock(props.user.id)}><UnBlockIcon style={{ fill: '#555', width: '20px', height: '20px' }} /></button>
                        </acronym>
                    ) : (
                        <acronym title="block user">
                            <button style={{ border: 'none' }} onClick={() => handleBlock(props.user.id, props.user.username)}><BanIcon style={{ fill: '#555', width: '20px', height: '20px' }} /></button>
                        </acronym>
                    )}
                </div>
            </li>
        </div>
    )
}



export default User;