import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { socket } from "../../App";
import Post from "./Post";

const Posts = (props) => {

    const [posts, setPosts] = useState(props.posts);

    socket.on('recive-post', async (new_post) => {
        const exitspost = await props.posts && props.posts.find((pst) => pst.id == new_post.id);
        if (!exitspost) {
            if (!props.profile) {
            } else {
                if (props.user && props.user.id) {
                    if (new_post.user.id == props.user.id) {
                    }
                }
            }
        }
    });

    const handleRemovePost = (data) => {
        const post_index = props.posts.findIndex((post) => post.id == data);
        const newPosts = props.posts.splice(post_index, 1);
        setPosts([...props.posts]);
    }

    useEffect(() => {
        if (props.posts) {
            setPosts([...props.posts]);
        }
    }, [props.posts]);



    return (
        <div className="posts">
            {posts && posts.map((post, index) => (
                <Post group={props.group} not_accept={props.not_accept} handleRemovePost={handleRemovePost} key={index} post={post} />
            ))}
        </div>
    )
}

const mapStateToprops = (state) => ({
    user: state.user.data
});

export default connect(mapStateToprops)(Posts);