import { socket } from "../../App";
import { SET_POSTS } from "../actions/types"

const INITIAL_STATE = {
    posts: null
}

const post_reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_POSTS:
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}

export default post_reducer;