import { combineReducers } from "redux";
import admin_reducer from "./admin";
import admin_user_reducer from "./admin_user";
import chat_reducer from "./chats";
import feed_reducer from "./feed";
import friends_reducer from "./friends";
import friend_requests_reducer from "./friend_requests";
import groups_reducer from "./groups";
import group_post_reducer from "./group_posts";
import message_reducer from "./messages";
import muted_reducer from "./MutedChats";
import notify_reducer from "./notifications";
import one_post_reducer from "./one_post";
import post_reducer from "./posts";
import profile_reducer from "./profile";
import search_reducer from "./search";
import page_search_reducer from "./search_page";
import success_reducer from "./success";
import tags_reducer from "./tags_reducer";
import tag_posts from "./tag_posts";
import user_reducer from "./users";
import user_posts_reducer from "./user_posts";

const root_reducer = combineReducers({
    posts: post_reducer,
    notify: notify_reducer,
    user: user_reducer,
    profile: profile_reducer,
    user_posts: user_posts_reducer,
    tags: tags_reducer,
    one_post: one_post_reducer,
    tag_filter: tag_posts,
    groups: groups_reducer,
    group_posts: group_post_reducer,
    search: search_reducer,
    chats: chat_reducer,
    message: message_reducer,
    success: success_reducer,
    group_feed: feed_reducer,
    search_page: page_search_reducer,
    friends: friends_reducer,
    friend_requests: friend_requests_reducer,
    muted_chats: muted_reducer,
    admin_user: admin_user_reducer,
    admin: admin_reducer
});

export default root_reducer;