import React from "react";
import { Link } from "react-router-dom";
import HumanWorking from "../assets/images/human-working.svg";

const NotFound = () => {

    return (
        <div className="not-found-page">
            <img src={HumanWorking} alt="human" />
            {/* <h3>oops!</h3> */}
            <h1 style={{ marginBottom: '10px', fontSize: '0.9rem', color: 'rgba(0,0,0,0.7)' }}>Sorry, this page isn't available.</h1>
            <Link to="/" style={{ textDecorationLine: 'none' }}>
                <button>Back to home</button>
            </Link>
        </div>
    )
}

export default NotFound;