const { SET_REPORTS, SET_ADMIN_GROUPS } = require("../actions/types")

const INITIAL_STATE = {
    reports: null,
    groups: null
}

const admin_reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_REPORTS:
            return {
                ...state,
                reports: action.reports
            }
        case SET_ADMIN_GROUPS:
            return {
                ...state,
                groups: action.groups
            }
        default:
            return state
    }
}

export default admin_reducer;