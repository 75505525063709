import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { filesUrl } from "../../App";
import { ReactComponent as CloseIcon } from '../../assets/images/plus.svg';

const Tag_people = (props) => {
    const [people, setPeople] = useState(null);

    const getUsers = () => {
        axios.get(`/posts/tags/user/${props.post_id}`).then(res => {
            setPeople(res.data.users);
        });
    }

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div className="tag_post_people_area">
            <div className="overlay"></div>
            <div className="modal">
                <div className="head">
                    <h5>People</h5>
                    <CloseIcon onClick={() => props.handleOpen({})} />
                </div>
                <div className="body">
                    <ul>
                        {people !== null ? (
                            <>
                                {people.map((user, key) => (
                                    <Link key={key} style={{ textDecorationLine: 'none', color: '#444' }} to={`/user/${user.id}`}>
                                        <li>
                                            <div className="img" style={{ backgroundImage: `url(${user.image ? user.image : filesUrl + '/static/user/default.png'})` }}></div>
                                            <h5>{user.username}</h5>
                                        </li>
                                    </Link>
                                ))}
                            </>
                        ) : (
                            <></>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Tag_people;