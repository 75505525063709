import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuDots from "../../assets/images/menu-dots.svg";
import TrashIcon from "../../assets/images/trash.svg";
import ReportIcon from "../../assets/images/remove-user.svg";
import AnonymousProfile from "../../assets/images/anonymous.svg";
import axios from "axios";
import { format } from "timeago.js";
import TextareaAutosize from "react-textarea-autosize";
import Content from "./Content";
import { socket } from "../../App";
import { toast } from "react-toastify";
import { filesUrl } from "../../App";
import { set_post_func } from "../../store/actions/post";
import NewUpdates from "./NewUpdates";
import { connect } from "react-redux";
// import _ from "lodash";

const SubReplies = (props) => {
    const [replies, setReplies] = useState([]);
    const [openReply, setOpenReply] = useState(true);
    const [subReply, setSubReply] = useState("");
    const [publicReply, setPublicReply] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [replies_length, set_replies_length] = useState(0);
    const [haveUpdates, setHaveUpdates] = useState(false);
    const [pageWidth, setPageWidth] = useState(window.innerWidth);

    const line_none = {
        textDecorationLine: "none",
        color: "black",
        display: "flex",
        alignItems: "center",
    };

    const LoadMoreReply = () => {
        let page = currentPage;
        // page = currentPage.length == 1 ? 1 : page * 4;
        page = replies.length == 0 ? 0 : (replies.length == 1 ? 1 : page)
        axios.get(`/posts/sub-replies/${props.reply_id}?page=${page}`).then((res) => {
            // page = page + 3;
            // const uniqueArray = _.uniq([...replies, ...res.data.replies]);
            setReplies([...replies, ...res.data.replies]);
            set_replies_length(res.data.number_of_replies);
            setCurrentPage(page + 3);
            props.getMoreSubReplies([...replies, ...res.data.replies], props.reply_id);
        });
    };

    useEffect(() => {
        socket.on("recive-sub-reply", (data) => {
            if (props.user && props.user.id) {
                if (props.post_id == data.post_id && props.comment_id && props.reply_id == data.reply_id && props.user.id !== data.user.id) {
                    const newReplyArr = [...replies];
                    if (!newReplyArr.some((repl) => repl.id == data.reply_id)) {
                        if (window.location.pathname == `/post/${props.post_id}`) setHaveUpdates(true);
                        else {
                            newReplyArr.unshift(data);
                            setReplies([...newReplyArr]);
                        }
                    }
                    // setCurrentPage(1);
                    // set_replies_length(prev => prev + 1);
                }
            } else {
                if (props.post_id == data.post_id && props.comment_id && props.reply_id == data.reply_id) {
                    const newReplyArr = [...replies];
                    if (!newReplyArr.some((repl) => repl.id == data.reply_id)) {
                        if (window.location.pathname == `/post/${props.post_id}`) setHaveUpdates(true);
                        else {
                            newReplyArr.unshift(data);
                            setReplies([...newReplyArr]);
                        }
                        // setCurrentPage(1);
                        // set_replies_length(prev => prev + 1);
                    }
                }
            }
        });
    }, []);

    const handleSubReply = (reply_id, reply_user, reply_username, reply_public) => {
        if (subReply.length > 0 && props.user && props.user.id) {
            axios.post(`/posts/sub-replies/${props.reply_id}`, {
                post_id: props.post_id,
                comment_id: props.comment_id,
                reply: subReply,
                public: publicReply,
                from_username: props.user.username,
                reply_user,
                reply_username,
                reply_public,
            })
                .then((res) => {
                    setOpenReply(null);
                    setSubReply("");
                    if (res.data.reply) {
                        const newReplyArr = [...replies];
                        const replyIndex = newReplyArr.find((repl_index) => repl_index.id == reply_id);
                        if (replyIndex) {
                            newReplyArr.unshift(res.data.reply);
                            setReplies([...newReplyArr]);
                        }
                        socket.emit("send-sub-reply", {
                            notification: res.data.notification,
                            reply: res.data.reply,
                        });
                    }
                    setPublicReply(true);
                })
                .catch((error) => {
                    setPublicReply(true);
                    toast.warn("Request timed out!");
                });
        }
    };

    const removeReply = (reply_id, reply_user) => {
        axios.delete(`/posts/sub-replies/${reply_id}/${reply_user}`).then((res) => {
            const newReplyArr = [...replies];
            const replyIndex = newReplyArr.findIndex((repl) => repl.id == reply_id);
            if (replyIndex !== -1) {
                set_replies_length(prev => prev - 1);
                props.handleRemoveReply(props.reply_id, reply_id);
                newReplyArr.splice(replyIndex, 1);
                setReplies([...newReplyArr]);
            }
        }).catch((error) => {
            toast.warn("Reply data error!");
        });
    };

    const resetPage = () => {
        // props.set_post(null);
        // setCurrentPage(1);
        set_replies_length(0);
        // LoadMoreReply();
        axios.get(`/posts/sub-replies/${props.reply_id}`).then((res) => {
            // page = page + 3;
            // setReplies([]);
            // props.getMoreSubReplies(res.data.replies, props.reply_id);
            setReplies([...res.data.replies]);
            // const currentPageValue = currentPage >= replies.length ? res.data.number_of_replies : ;
            // if (replies.length == 3) {
            setCurrentPage(3);
            // }
            set_replies_length(res.data.number_of_replies);
        });
    }

    useEffect(() => {
        if (props.replies) setReplies(props.replies);
    }, [props.replies]);

    useEffect(() => {
        set_replies_length(props.replies_length);
    }, [props.replies_length]);

    useEffect(() => {
        window.addEventListener('resize', (event) => {
            setPageWidth(window.innerWidth);
        });
    }, []);


    return (
        <div>
            <div className="sub-replies">
                <NewUpdates resetPage={resetPage} sub_replies={true} haveUpdates={haveUpdates} donotLoad={true} setHaveUpdates={() => setHaveUpdates(false)} all={false} postId={props.post_id} />
                {replies &&
                    replies.map((reply, index) => (
                        <div key={index} style={{ marginLeft: "15px", marginTop: "15px", paddingLeft: '10px', borderLeft: '1px solid #c4c4c4' }} className="repl">
                            <div style={{ marginBottom: "10px", marginTop: '-10px' }} className="user">
                                {reply.public ? (
                                    <section onClick={() => props.get_profile(reply.user.id)}>
                                        <Link style={line_none} to={`/user/${reply.user.id}`}>
                                            <div className="img" style={{ backgroundImage: `url(${reply.user.image ? reply.user.image : filesUrl + "/static/user/default.png"})`, }} ></div>
                                            <h5 className="underline-hover">
                                                &nbsp;&nbsp;&nbsp;
                                                {pageWidth < 400 ? (reply.user.username.length > 8 ? `${reply.user.username.substr(0, 8)}...` : reply.user.username) : reply.user.username}
                                            </h5>
                                        </Link>
                                    </section>
                                ) : (
                                    <section>
                                        <Link style={{ ...line_none, cursor: "default", }} to="#">
                                            <div className="img" style={{ backgroundImage: `url(${AnonymousProfile})`, }}></div>
                                            <h5 style={{ textDecorationLine: "none", }}>
                                                &nbsp;&nbsp;&nbsp;Anonymous
                                            </h5>
                                        </Link>
                                    </section>
                                )}
                                <div className="menu-area">
                                    <img
                                        onClick={() => props.handleReplyMenu(reply.id)}
                                        className="icon"
                                        src={MenuDots}
                                        alt="menu"
                                    />
                                    <div className={props.openReplyMenu == reply.id ? "menu show-menu" : "menu"}>
                                        <section>
                                            {props.user && reply.user.id !== props.user.id && (
                                                <span style={{ padding: "10px", }} onClick={() => {
                                                    props.setOpenReplyReport();
                                                    props.setReportUser(props.post_id);
                                                    props.setReportUserDate(reply.user);
                                                    props.setOpenReplyMenu(null);
                                                }}>
                                                    <img src={ReportIcon} alt="report" />
                                                    <h6>Report user</h6>
                                                </span>
                                            )}
                                            {props.user && (reply.user.id == props.user.id || props.user.is_admin) && (
                                                <>
                                                    <span style={{ padding: "10px", }} onClick={() => {
                                                        const anwser = window.confirm('Are you sure, This will remove the reply');
                                                        if (anwser) {
                                                            removeReply(reply.id, reply.user.id)
                                                        }
                                                    }}>
                                                        <img src={TrashIcon} alt="delete" />
                                                        <h6 style={{ textDecorationLine: 'none' }}>
                                                            Remove reply
                                                        </h6>
                                                    </span>
                                                </>
                                            )}
                                        </section>
                                    </div>
                                </div>
                            </div>
                            <div style={{ width: "calc(100% - 10px)", display: "flex", flexDirection: "column", alignItems: "flex-start" }} className="repl-text">
                                <Content reply_username="nirmalsalinda">
                                    {reply.reply_username && (
                                        <strong>
                                            {reply.reply_user_public ? `@${reply.reply_username}` : '@Anonymous'}&nbsp;
                                        </strong>
                                    )}
                                    {reply.reply}
                                </Content>
                                <div style={{ display: "flex" }}>
                                    <span style={{ fontSize: "0.7rem", fontWeight: "400", }}>
                                        {format(reply.created_at)}
                                    </span>
                                    {props.user && props.user.id && (
                                        <>
                                            {reply.posting ? (
                                                <span style={{ fontSize: "0.7rem", }}>
                                                    posting...
                                                </span>
                                            ) : (
                                                <>
                                                    <h6 onClick={() => setOpenReply(reply.id)}>
                                                        Reply
                                                    </h6>
                                                </>
                                            )}
                                        </>
                                    )}
                                </div>
                                {openReply == reply.id && (
                                    <>
                                        {props.user && props.user.id ? (
                                            <>
                                                {!props.user.active ? (
                                                    <div style={{ width: "calc(100% - 35px)", }} className="reply" >
                                                        <div className="add-reply">
                                                            <form onSubmit={(event) => event.preventDefault()} className="add-comment" style={{ position: "relative", }}>
                                                                <div className="user-img" style={{ backgroundImage: `url(${props.user && props.user.image ? props.user.image : filesUrl + "/static/user/default.png"})`, }}></div>
                                                                <TextareaAutosize disabled placeholder="Account Temporarily Blocked" />
                                                            </form>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div style={{ width: "calc(100% - 35px)", }} className="reply" >
                                                        <div className="add-reply">
                                                            <form onSubmit={(event) => event.preventDefault()} className="add-comment" style={{ position: "relative", }}>
                                                                <div className="user-img" style={{ backgroundImage: `url(${props.user && props.user.image ? props.user.image : filesUrl + "/static/user/default.png"})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", }}></div>
                                                                <TextareaAutosize onChange={(e) => setSubReply(e.target.value)} value={subReply} placeholder="Type here..." />
                                                            </form>
                                                            <div className="comment-action">
                                                                {subReply.length > 0 && (
                                                                    <>
                                                                        <div className="checkbox">
                                                                            <label htmlFor="anony">
                                                                                <h5>reply Anonymously</h5>
                                                                            </label>
                                                                            <input onChange={(e) => setPublicReply(!e.target.checked)} id="anony" type="checkbox" />
                                                                        </div>
                                                                        <button onClick={() => handleSubReply(reply.id, reply.user.id, reply.user.username, reply.public)}>
                                                                            reply
                                                                        </button>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            <div style={{ width: "calc(100% - 35px)", }} className="reply">
                                                <div className="add-reply">
                                                    <form onSubmit={(event) => event.preventDefault()} className="add-comment" style={{ position: "relative", }}>
                                                        <div className="user-img" style={{ backgroundImage: `url(${props.user && props.user.image ? props.user.image : filesUrl + "/static/user/default.png"})`, }}></div>
                                                        <TextareaAutosize disabled placeholder="Please login to reply" />
                                                    </form>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    ))}
                {replies && replies_length > replies.length && replies.length !== replies_length && (
                    <h6 onClick={() => LoadMoreReply()} style={{ fontSize: "0.8rem", color: "#171616", fontWeight: "600", cursor: "pointer", marginTop: "10px" }} className="underline-hover">
                        more replies
                    </h6>
                )}
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch) => ({
    set_post: (data) => dispatch(set_post_func(data))
});

export default connect(null, mapDispatchToProps)(SubReplies);
