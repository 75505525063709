import React, { useState } from "react";
import ProfileImage from '../assets/images/profile.jpg';
import CloseIcon from '../assets/images/plus.svg';
import EducationIcon from '../assets/images/graduation-cap.svg';
import JobsIcon from '../assets/images/briefcase.svg';
import LocationIcon from '../assets/images/location.svg';
import PencilIcon from '../assets/images/pencil.svg';
import CorrectIcon from '../assets/images/check.svg';
import { change_about } from "../store/actions/users";
import { connect } from "react-redux";

const EditeProfile = (props) => {

    const [description, setDescription] = useState(props.user.about);
    const [hobbies, setHobbies] = useState(props.user.hobbies);
    const [education, setEduaction] = useState(props.user.education);
    const [job, setJob] = useState(props.user.job);
    const [location, setLocation] = useState(props.user.location);

    const handleUpdate = () => {
        props.update({ data: { about: description, hobbies, education, job, location }, id: props.user.id });
    }

    return (
        <div className={props.open ? 'edite-profile' : 'none'}>
            <div className="overlay">
                <div className="modal">
                    <div className="head">
                        <div className="head-user">
                            <div className="img" style={{ backgroundImage: `url(${props.user.image})` }}></div>
                            <h5>Edite profile</h5>
                        </div>
                        <section>
                            <img onClick={() => {
                                handleUpdate()
                                props.handleOpen()
                            }} src={CorrectIcon} alt="done" />
                            <img onClick={props.handleOpen} src={CloseIcon} alt="close" />
                        </section>
                    </div>
                    <div className="description">
                        <h4>Description: </h4>
                        <textarea placeholder="Enter about your self" type="text" defaultValue={props.user.about} onChange={(e) => setDescription(e.target.value)}></textarea>
                    </div>
                    <div className="more">
                        <p>
                            <img src={EducationIcon} alt="education" />
                            <div className="section">
                                <strong>Education: </strong>
                                <input placeholder="Education" onChange={(e) => setEduaction(e.target.value)} id="edu" defaultValue={props.user.education && props.user.education} />
                                <label htmlFor="edu">
                                    <img src={PencilIcon} alt="edite" />
                                </label>
                            </div>
                        </p>
                        <p>
                            <img src={JobsIcon} alt="jobs" />
                            <div className="section">
                                <strong>Job: </strong>
                                <input placeholder="current job" id="jobs" onChange={(e) => setJob(e.target.value)} defaultValue={props.user.job && props.user.job} />
                                <label htmlFor="jobs">
                                    <img src={PencilIcon} alt="edite" />
                                </label>
                            </div>
                        </p>
                        <p>
                            <img src={LocationIcon} alt="location" />
                            <div className="section">
                                <strong>Location: </strong>
                                <input placeholder="current location" id="loc" onChange={(e) => setLocation(e.target.value)} defaultValue={location} />
                                <label htmlFor="loc">
                                    <img src={PencilIcon} alt="edite" />
                                </label>
                            </div>
                        </p>
                    </div>
                    <div className="hobbies">
                        <div className="add">
                            <input type="text" onChange={(e) => setHobbies(e.target.value)} placeholder="Press enter to add hobby" />
                            <p style={{ fontSize: '0.8rem', marginLeft: '10px', marginTop: '5px', fontWeight: '400' }} >You can enter hobbies separated by comma.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToprops = (state) => ({
    user: state.user.data
});

const mapDispatchToProps = (dispatch) => ({
    update: (data) => dispatch(change_about(data))
});

export default connect(mapStateToprops, mapDispatchToProps)(EditeProfile);