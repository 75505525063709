import React from "react";
import { Spinner } from "loading-animations-react";
import { filesUrl } from "../../App";
import { Link } from "react-router-dom";

const AcceptPosts = ({ posts, handleAcceptPost, handleRemovePost }) => {

    return (
        <div style={{ width: 'calc(100% - 15px)', marginLeft: '-20px', maxWidth: '600px' }}>
            {posts !== null ? (
                <>
                    {posts.length > 0 && (
                        <div className="accept-posts">
                            {posts.map((post, index) => (
                                <div style={{ marginTop: '10px' }} key={index}>
                                    <section>
                                        <div className="image" style={{ backgroundImage: `url(${post.user.image ? post.user.image : filesUrl + '/static/user/default.png'})`, border: '1px solid #000' }}></div>
                                        <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                                            <h3 style={{ margin: '0' }}>{post.user.username}</h3>
                                            <h6 style={{ margin: '0', color: 'rgba(0,0,0,0.7)' }}>Action pending</h6>
                                        </div>
                                    </section>
                                    <p style={{ textDecorationLine: 'none', cursor: 'default' }}>{post.content.length > 100 ? post.content.substr(0, 100) + '. . .' : post.content}</p>
                                    <section>
                                        <button onClick={() => handleAcceptPost(post.id)} className="btn-primary">Add</button>
                                        <button onClick={() => handleRemovePost(post.id)} className="btn-gray">Remove</button>
                                    </section>
                                </div>
                            ))}
                        </div>
                    )}
                </>
            ) : (
                <div style={{ marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                </div>
            )}
        </div>
    )
}

export default AcceptPosts;