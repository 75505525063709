import React from "react";
import TestImage from '../assets/images/test.jpg';

const ChatImagePopup = (props) => {
    return (
        <div className="image-popup">
            <div className="overlay"></div>
            <div className="modal">
                <img src={props.image} />
                <div className="btns">
                    <button onClick={() => props.handleSendImage()}>send image</button>
                    <button onClick={() => props.handleClose()}>cancel</button>
                </div>
            </div>
        </div>
    )
}

export default ChatImagePopup;