import { SET_GROUP_POSTS } from "../actions/types"

const INTIIAL_STATE = {
    posts: null
}

const group_post_reducer = (state = INTIIAL_STATE, action) => {
    switch (action.type) {
        case SET_GROUP_POSTS:
            return {
                ...state,
                posts: action.data
            }
        default:
            return state
    }
}

export default group_post_reducer;