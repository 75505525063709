import axios from "axios";
import { SET_GROUP, SET_GROUPS, SET_GROUPS_SEARCH, SET_GROUP_FEED, SET_GROUP_POSTS } from "./types";
import { upload_image } from "../../assets/utils/upload";
import { socket } from "../../App";

export const get_groups = () => (dispatch) => {
    axios.get('/group').then(res => {
        dispatch({ type: SET_GROUPS, data: res.data.results })
    })
}

export const set_group = (data) => (dispatch) => {
    dispatch({ type: SET_GROUP, group: data });
}

export const clear_group_state = () => (dispatch) => {
    dispatch({ type: SET_GROUP, group: null });
}

export const create_group_post = (data) => async (dispatch) => {
    if (data.image) {
        const uploadImage = await upload_image(data.image, 'posts');
        data.image = uploadImage;
    }
    socket.emit('create-group-post', data);
}

const set_group_posts = (data) => ({
    type: SET_GROUP_POSTS,
    data: data
});

export const get_group_posts = (id) => (dispatch) => {
    axios.get(`/group/posts/${id}`).then(res => {
        dispatch(set_group_posts([...res.data.posts]));
    }).catch(error => {
        dispatch(set_group_posts([]));
    });
}

export const set_group_posts_func = (data) => (dispatch) => {
    dispatch(set_group_posts(data));
}

export const get_group_feeds = (user_id) => (dispatch) => {
    axios.get(`/group/feed?id=${user_id}`).then(res => {
        dispatch({ type: SET_GROUP_FEED, data: res.data.posts });
    }).catch(error => {
    });
}

export const set_group_feed = (data) => (dispatch) => {
    dispatch({ type: SET_GROUP_FEED, data: data });
}

export const search_groups = (text) => (dispatch) => {
    axios.get(`/group/search?text=${text}`).then(res => {
        dispatch({
            type: SET_GROUPS_SEARCH,
            search_groups: res.data.groups
        });
    }).catch(error => {
    });
}
