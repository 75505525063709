export const SET_POSTS = 'SET_POSTS';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';
export const SET_USER = 'SET_USER';
export const SET_PROFILE = 'SET_PROFILE';
export const SET_USER_POSTS = 'SET_USER_POSTS';
export const SET_TAGS = 'SET_TAGS';
export const SET_POST = 'SET_POST';
export const SET_TAG_POSTS = 'SET_TAG_POSTS';
export const SET_GROUPS = 'SET_GROUPS';
export const SET_GROUP = 'SET_GROUP';
export const SET_GROUP_POSTS = 'SET_GROUP_POSTS';
export const SET_SEARCH = 'SET_SEARCH';
export const SET_CHATS = 'SET_CHATS';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_SUCCESS = 'SET_SUCCESS';
export const SET_GROUP_FEED = 'SET_GROUP_FEED';
export const SET_GROUPS_SEARCH = 'SET_GROUPS_SEARCH';
export const SEARCH_USERS_REALTIME = 'SEARCH_USERS_REALTIME';
export const SEARCH_USERS_PAGE = 'SEARCH_USERS_PAGE';
export const SET_FRIENDS = 'SET_FRIENDS';
export const SET_FRIEND_REQUESTS = 'SET_FRIEND_REQUESTS';
export const SET_MUTED_CHATS = 'SET_MUTED_CHATS';
export const SET_ADMIN_USER = 'SET_ADMIN_USER';
export const SET_REPORTS = 'SET_REPORTS';
export const SET_ADMIN_GROUPS = 'SET_ADMIN_GROUPS';
export const SET_REPLIES = 'SET_REPLIES';