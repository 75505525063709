import React, { useEffect, useRef, useState } from "react";
import Logo from '../assets/images/logo.png';
import { ReactComponent as BellIcon } from '../assets/images/bell.svg';
import LogoutIcon from '../assets/images/sign-out-alt.svg';
import { ReactComponent as DownIcon } from '../assets/images/sort-down.svg';
import CreatePost from './Post/create';
import { Link, useNavigate } from "react-router-dom";
import { get_friend_requests, get_profile, get_user, logout, remove_unread_messages } from '../store/actions/users';
import { connect } from "react-redux";
import { socket } from "../App";
import { format } from "timeago.js";
import { toast } from "react-toastify";
import { ReactComponent as ActiveNotificationsIcon } from '../assets/images/notifications_active.svg';
import { nav_links } from "../assets/data/navbar";
import { play_audio } from "../assets/utils/notify_sound";
import LoginPopUp from "./LoginPopUp";
import FooterLinks from "../assets/data/footer";
import { Spinner } from "loading-animations-react";
import axios from "axios";
import { filesUrl } from "../App";

const Navbar = (props) => {

    const [openCreate, setOpenCreate] = useState(false);
    const [profileOpen, setProfileOpen] = useState(false);
    const [notificationDropDown, setNotificationDropDown] = useState(false);
    const [openmobile, setOpenmobile] = useState(false);
    const [notifications, setNotifications] = useState(null);
    const [unreadMessaages, setUnreadMessages] = useState(0);
    const [unreadMessaagesArr, setUnreadMessagesArr] = useState([]);
    const [friendRequests, setFriendREquests] = useState(0);
    const [unreadNotifications, setUnreadNotifications] = useState(0);
    const [openNtifi, setOpenNotifi] = useState(false);
    const [openLogin, setOpenLogin] = useState(false);
    const [recivedNotifications, setRecivedNotifications] = useState([]);
    const profileBtnRef = useRef();
    const profileDropDownRef = useRef();
    const notificationDropDownRef = useRef();
    const mobilenavRef = useRef();
    const [tmpNotifi, setTmpNotifi] = useState([]);
    const [date, setDate] = useState(null);

    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        setDate(currentYear);
    }, []);

    const navigate = useNavigate();

    const handleOpen = () => {
        setOpenCreate(!openCreate);
    }

    const handleLogout = () => {
        props.logout();
        navigate('/');
    }

    const handleTmpNotifi = (id) => {
        setTmpNotifi(id);
    }

    const get_unread_notificatons = () => {
        axios.get('/user/notifications').then(res => {
            setUnreadNotifications(res.data.notifi.filter(notifi => notifi.read_notify == false).length);
        }).catch(error => {
            setUnreadNotifications(0);
        });
    }

    const read_notifications = () => {
        axios.get('/user/notifications/read').then(res => {
            setUnreadNotifications(0);
        });
    }

    const get_notifications = () => {
        axios.get('/user/notifications').then(res => {
            setNotifications(res.data.notifi);
        }).catch(error => {
            setNotifications([]);
        });
    }

    const get_unread_messages = () => {
        if (props.user && props.user.id) {
            axios.get(`/chat/unread/${props.user.id}`).then(res => {
                setUnreadMessages(res.data.messages.length);
            });
        }
    }


    const get_number_of_friend_requests = () => {
        if (props.user && props.user.id) {
            axios.get('/user/requests/count').then(res => {
                setFriendREquests(res.data.requests);
            });
        }
    }

    useEffect(() => {

        get_notifications()

        get_unread_notificatons();

        get_number_of_friend_requests();

        get_unread_messages();

        const login_popup = localStorage.getItem('login-popup');

        if (login_popup) setOpenLogin(true);

    }, []);


    useEffect(() => {

        socket.on('recive-comment-notifi', (data) => {
            const notificationArr = [...recivedNotifications];
            if (!notificationArr.some(notifi => notifi.id == data.id)) {
                notificationArr.unshift(data);
                setRecivedNotifications([...notificationArr]);
                setUnreadNotifications(prev => prev + 1);
            }
        });

        socket.on('recive-reply-notifi', (data) => {
            const notificationArr = [...recivedNotifications];
            if (!notificationArr.some(notifi => notifi.id == data.id)) {
                notificationArr.unshift(data);
                setRecivedNotifications([...notificationArr]);
                setUnreadNotifications(prev => prev + 1);
            }
        });

        socket.on('recive-tag-post-notifi', (data) => {
            const notificationArr = [...recivedNotifications];
            if (!notificationArr.some(notifi => notifi.id == data.id)) {
                notificationArr.unshift(data);
                setRecivedNotifications([...notificationArr]);
                setUnreadNotifications(prev => prev + 1);
            }
        });

        socket.on('recive-support-notification', (data) => {
            const notificationArr = [...recivedNotifications];
            if (!notificationArr.some(notifi => notifi.id == data.id)) {
                notificationArr.unshift(data);
                setRecivedNotifications([...notificationArr]);
                setUnreadNotifications(prev => prev + 1);
            }
        });

        socket.on('recive-sub-reply-notifi', (data) => {
            const notificationArr = [...recivedNotifications];
            if (!notificationArr.some(notifi => notifi.id == data.id)) {
                notificationArr.unshift(data);
                setRecivedNotifications([...notificationArr]);
                setUnreadNotifications(prev => prev + 1);
            }
        });

        socket.on('recive-block-notification', (data) => {
            const notificationArr = [...recivedNotifications];
            if (!notificationArr.some(notifi => notifi.id == data.id)) {
                notificationArr.unshift(data);
                setRecivedNotifications([...notificationArr]);
                setUnreadNotifications(prev => prev + 1);
            }
        });

        socket.on('recive-unblock-notification', (data) => {
            const notificationArr = [...recivedNotifications];
            if (!notificationArr.some(notifi => notifi.id == data.id)) {
                notificationArr.unshift(data);
                setRecivedNotifications([...notificationArr]);
                setUnreadNotifications(prev => prev + 1);
            }
        });

        socket.on('recive-friend-request', (data) => {
            const notificationArr = [...recivedNotifications];
            if (!notificationArr.some(notifi => notifi.id == data.id)) {
                notificationArr.unshift(data);
                setRecivedNotifications([...notificationArr]);
                setUnreadNotifications(prev => prev + 1);
                setFriendREquests(prev => prev + 1);
            }
        });

        socket.on('friend-request-accept-recive', (data) => {
            const notificationArr = [...recivedNotifications];
            if (!notificationArr.some(notifi => notifi.id == data.id)) {
                notificationArr.unshift(data);
                setRecivedNotifications([...notificationArr]);
                setUnreadNotifications(prev => prev + 1);
            }
        });

        socket.on('recive-notification', (data) => {
            if (!unreadMessaagesArr.some(msg => msg.id == data.result.id)) {
                setUnreadMessagesArr([...unreadMessaagesArr, data.result]);
                setUnreadMessages(prev => prev + 1);
            }
        });

    }, []);

    useEffect(() => {
        if (props.user && props.user.id) {
            // setUnreadMessages(props.user.unread_messages_count);
            // setFriendREquests(props.user.friend_requests);
        }
    }, [props.user]);

    useEffect(() => {
        setTimeout(() => {
            setTmpNotifi(null);
        }, [10000]);
    }, [tmpNotifi]);

    const PageURL = document.URL;

    const handleOpenLogin = () => {
        setOpenLogin(!openLogin);
        localStorage.removeItem('login-popup');

    }

    useEffect(() => {

        const login_popup = localStorage.getItem('login-popup');

        if (login_popup) setOpenLogin(true);

    }, [PageURL]);

    useEffect(() => {
        if (props.user && props.user.id) {
            if (!window.location.pathname.includes('/chat')) {
                socket.emit('remove-user-from-chat', props.user.id);
            }
        }
    }, [])

    useEffect(() => {

        const handleOutsideClose = (event) => {
            if (mobilenavRef.current && !mobilenavRef.current.contains(event.target)) setOpenmobile(false);
            if (!notificationDropDownRef.current.contains(event.target)) setOpenNotifi(false);
            if (!profileDropDownRef.current.contains(event.target)) setProfileOpen(false);
        }

        document.body.addEventListener("mousedown", handleOutsideClose);
        return () => document.body.removeEventListener("mousedown", handleOutsideClose);
    }, []);

    return (
        <nav className="navbar">
            {openCreate && <CreatePost open={openCreate} setOpen={setOpenCreate} handleOpen={handleOpen} />}
            {openLogin && <LoginPopUp open={openLogin} setOpen={setOpenLogin} handleOpen={() => handleOpenLogin()} />}
            <a href="https://happyfacess.com">
                <img className="logo" src={Logo} alt="logo" />
            </a>

            <ul className="nav_items">

                <li ref={mobilenavRef} onClick={() => setOpenmobile(!openmobile)} className="nav_item">
                    <div className="icon">
                        {(unreadMessaages > 0 || friendRequests > 0) && (
                            <>
                                {(unreadMessaages + friendRequests) > 9 ? (
                                    <span className="notify-badge">9+</span>
                                ) : (
                                    <span className="notify-badge">{(unreadMessaages + friendRequests)}</span>
                                )}
                            </>
                        )}
                        <DownIcon />
                    </div>
                    <div className={openmobile ? `mobile-nav menu menu-active` : 'mobile-nav menu'}>
                        <ul className="mobile-nav-list">
                            {nav_links.map((item, index) => (
                                <Link style={{ width: '100%', height: '40px', textDecorationLine: 'none', color: '#5e5c5c', padding: '0', margin: '0', marginTop: '8px', maxHeight: '40px' }} key={index} to={item.path}>
                                    <li style={{ height: '30px' }} onClick={() => {
                                        setOpenmobile(false)
                                        if (index == 3) setFriendREquests(0)
                                    }}>
                                        {item.image}
                                        <p>{item.name}</p>
                                        {index == 0 && (
                                            <>
                                                {unreadMessaages > 0 && (
                                                    <>
                                                        {unreadMessaages > 9 ? (
                                                            <span style={{ position: 'static', margin: '0', marginLeft: '10px' }} className="notify-badge">9+</span>
                                                        ) : (
                                                            <span style={{ position: 'static', margin: '0', marginLeft: '10px' }} className="notify-badge">{unreadMessaages}</span>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                        {index == 3 && (
                                            <>
                                                {friendRequests > 0 && (
                                                    <>
                                                        {friendRequests > 9 ? (
                                                            <span style={{ position: 'static', margin: '0', marginLeft: '10px' }} className="notify-badge">9+</span>
                                                        ) : (
                                                            <span style={{ position: 'static', margin: '0', marginLeft: '10px' }} className="notify-badge">{friendRequests}</span>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </li>
                                </Link>
                            ))}
                        </ul>
                    </div>
                </li>

                <li ref={notificationDropDownRef} className="nav_item">
                    <div className="icon">
                        <BellIcon onClick={() => {
                            setOpenNotifi(!openNtifi)
                            get_notifications()
                            read_notifications();
                        }} />

                        {unreadNotifications > 0 && (
                            <>
                                {unreadNotifications > 9 ? (
                                    <span className="notify-badge">9+</span>
                                ) : (
                                    <span className="notify-badge">{unreadNotifications}</span>
                                )}
                            </>
                        )}
                    </div>
                    <div className={openNtifi ? 'notifications-area menu menu-active' : 'notifications-area menu'}>
                        {notifications == null ? (
                            <div className="center3">
                                <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                            </div>
                        ) : (
                            <>
                                <ul className="notifications-list">
                                    {notifications.length > 0 ? (
                                        <>
                                            {notifications.map((no, key) => (
                                                <Link key={key} style={{ textDecorationLine: 'none', color: '#444', width: '100%' }} to={no.url ? no.url : '#'}>
                                                    <li className="notification" onClick={() => setOpenNotifi(false)}>
                                                        <p><strong>{no.from_username}</strong> {no.notification}</p>
                                                        <h6>{format(no.created_at)}</h6>
                                                    </li>
                                                </Link>
                                            ))}
                                        </>
                                    ) : (
                                        <div style={{ padding: '20px 0px', cursor: 'default' }} className="center-notify">
                                            <ActiveNotificationsIcon />
                                            <h5>No notifications</h5>
                                        </div>
                                    )}
                                </ul>
                            </>
                        )}
                    </div>
                </li>

                <li ref={profileDropDownRef} className="nav_item">
                    <div onClick={() => setProfileOpen(!profileOpen)} style={{ backgroundImage: `url(${(props.user && props.user.id) ? (props.user.image ? props.user.image : filesUrl + '/static/user/default.png') : filesUrl + '/static/user/default.png'})` }} className="profile-img-navbar"></div>
                    <div className={profileOpen ? 'profile-dropdown menu menu-active' : 'profile-dropdown menu'}>
                        {props.user && props.user.id ? (
                            <>
                                <Link onClick={() => {
                                    props.get_profile(props.user.id);
                                    setProfileOpen(false);
                                }} style={{ width: '100%', color: "#444", textDecorationLine: "none" }} to={`/user/${props.user.id}`}>
                                    <div className="profile-nav-link">
                                        <div style={{
                                            backgroundImage: `url(${props.user.image ? props.user.image : filesUrl + '/static/user/default.png'})`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            width: '40px',
                                            height: '40px',
                                            borderRadius: '50%',
                                            border: '1.5px solid #0245f5'
                                        }}></div>
                                        <h5 style={{ marginLeft: '10px' }}>{props.user.name}</h5>
                                    </div>
                                </Link>
                                <div onClick={() => handleLogout()} style={{ marginTop: '10px' }} className="profile-nav-link">
                                    <img style={{ width: '22px', marginLeft: '14px' }} src={LogoutIcon} />
                                    <h5 style={{ marginLeft: '15px' }}>Logout</h5>
                                </div>
                            </>
                        ) : (
                            <div onClick={() => handleOpenLogin()} className="profile-nav-link">
                                <img style={{ width: '20px' }} src={LogoutIcon} />
                                <h5 style={{ marginLeft: '15px' }}>signin</h5>
                            </div>
                        )}
                        <ul className="footer">
                            {FooterLinks.map((item, key) => (
                                <a key={key} target="_blank" style={{ textDecorationLine: 'none' }} href={item.url}>
                                    <li>{item.name} •</li>
                                </a>
                            ))}
                        </ul>
                        <p className="copyright">© Copyright {date ? date : 2023} Happy Faces. All rights reserved.</p>
                    </div>
                </li>

            </ul>
        </nav>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    notify: state.notify.data
});

const mapDispatchToProps = (dispatch) => ({
    get_user: () => dispatch(get_user()),
    logout: () => dispatch(logout()),
    remove_unread_messages: () => dispatch(remove_unread_messages()),
    get_friend_requests: () => dispatch(get_friend_requests()),
    get_profile: (username) => dispatch(get_profile(username))
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);