import React, { useEffect, useState } from "react";
import Appbar from "./Appbar";
import { ReactComponent as DeleteIcon } from '../../assets/images/trash.svg';
import { connect } from "react-redux";
import { toast } from "react-toastify";
import axios from "axios";
import { Navigate } from "react-router-dom";

const Admins = (props) => {

    const [users, setUsers] = useState(null);


    const handleRemove = (id) => {
        axios.delete(`/admin/user/${id}`).then(_res => {
            const newUserArr = [...users];
            const userIndex = newUserArr.findIndex((user) => user.id == id);
            if (userIndex !== -1) {
                newUserArr.splice(userIndex, 1);
                setUsers([...newUserArr]);
            }
        }).catch(_error => {
            toast.warn('Error deleting user!');
        });
    }

    useEffect(() => {
        axios.get('/admin/all').then(res => {
            setUsers(res.data.users);
        });
    }, []);

    return (
        <div className="admin-area">
            {props.admin_user !== null ? (
                <>
                    {props.admin_user && props.admin_user.id ? (
                        <>
                            <Appbar />
                            <div className="admin-groups">
                                <form onSubmit={(e) => e.preventDefault()}>
                                    <div className="top">
                                        <h4>Admins</h4>
                                    </div>
                                    <div className="users">
                                        <ul>
                                            {users !== null ? (
                                                <>
                                                    {users && users.map((user, key) => (
                                                        <li key={key}>
                                                            <section>
                                                                <div className="img" style={{ backgroundImage: `url(${user.image})` }}></div>
                                                                <h5 style={{ cursor: 'pointer' }}>{user.username}</h5>
                                                            </section>
                                                            {props.user && props.user.id !== user.id && (
                                                                <DeleteIcon onClick={() => handleRemove(user.id)} />
                                                            )}
                                                        </li>
                                                    ))}
                                                </>
                                            ) : (
                                                <h4>Loading...</h4>
                                            )}
                                        </ul>
                                    </div>
                                </form>
                            </div>
                        </>
                    ) : (
                        <Navigate to='/admin/login' />
                    )}
                </>
            ) : (
                <h5>Loading...</h5>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    admin_user: state.admin_user.data
});

export default connect(mapStateToProps)(Admins);