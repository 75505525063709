import axios from "axios";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Flip, toast, ToastContainer } from "react-toastify";
import CloseIcon from '../assets/images/plus.svg';
import { set_title } from "../assets/utils/title";
import { Link } from "react-router-dom";
import { set_user_func } from "../store/actions/users";
import { GoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login";
import jwt_decode from "jwt-decode";

const LoginPopUp = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!email) toast.warn('Email address is required!');
        else if (!password) toast.warn('Password is required!');
        else {
            axios.post('/user/login', { email, password }).then(res => {
                props.set_user(res.data.user);
                props.handleOpen();
                window.location.reload();
            }).catch(error => {
                toast.warn('Incorrect username or password!')
            });
        }
    }

    const handleGoogleLogin = (response) => {
        const GoogleUserObj = jwt_decode(response.credential);
        if (GoogleUserObj.sub && GoogleUserObj.name && GoogleUserObj.email) {
            axios.post('/user/google', { googleId: GoogleUserObj.sub, name: GoogleUserObj.name, email: GoogleUserObj.email }).then(res => {
                window.location.reload();
            }).catch(error => {
                if (error.response.data.errors) toast.warn(error.response.data.errors.message);
                else toast.warn('User credentials error!');
            });
        }
    }

    const handleFacebookLogin = (response) => {
        if (response.id && response.name && (response.email || response.phone)) {
            axios.post('/user/facebook', { FBID: response.id, name: response.name, email: response.email, number: response.phone }).then(res => {
                window.location.reload();
            }).catch(error => {
                if (error.response.data.errors) toast.warn(error.response.data.errors.message);
                else toast.warn('User credentials error');
            });
        }
    }

    const clearSuperUserSession = () => {
        sessionStorage.removeItem('super_user');
    }

    return (
        <div>
            <div className="hide-lg">
                <ToastContainer
                    transition={Flip}
                    // position={toast.POSITION.BOTTOM_LEFT}
                    hideProgressBar={true}
                    style={{ width: 'calc(100% - 20px)', margin: '10px' }}
                />
            </div>
            <div className="report-user">
                <div className="overlay" onClick={() => props.handleOpen()}></div>
                <div className="modal">
                    <div className="head">
                        <div className="user">
                            <h5>LOGIN</h5>
                        </div>
                        <img style={{ width: '15px' }} onClick={() => props.handleOpen()} src={CloseIcon} alt="close" />
                    </div>
                    <div className="body">
                        <div className="form-area-popup">
                            <form onSubmit={handleSubmit}>
                                <h4>SignIn Account</h4>
                                <section>
                                    <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Enter email address" />
                                    <span>Enter account email address</span>
                                </section>
                                <section>
                                    <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Enter password" />
                                    <span>Enter account password</span>
                                </section>
                                <div className="bottom">
                                    <p><a href='https://happyfacess.com/register' style={{ textDecoration: 'none', color: '#000' }} >Don't have an account | Sign up</a></p>
                                    <p><a href='https://happyfacess.com/forgot_password' style={{ textDecoration: 'none', color: '#000', color: 'blue' }} >&nbsp; | Forgot password</a></p>
                                </div>
                                <section>
                                    <button onClick={handleSubmit} type="submit">Login</button>
                                </section>
                                <h5 style={{ marginTop: '10px', marginBottom: '10px' }}>OR</h5>
                            </form>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div style={{ marginLeft: '-14px' }}>
                                    <GoogleLogin
                                        onSuccess={handleGoogleLogin}
                                    />
                                </div>
                                <FacebookLogin
                                    appId="471857798096333"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={handleFacebookLogin}
                                    buttonStyle={{ width: '250px', fontWeight: '400', fontSize: '0.9rem' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    set_user: (data) => dispatch(set_user_func(data))
});

export default connect(null, mapDispatchToProps)(LoginPopUp);