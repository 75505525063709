import { SET_TAG_POSTS } from "../actions/types"

const INTIAL_STATE = {
    posts: null
}

const tag_posts = (state = INTIAL_STATE, action) => {
    switch(action.type) {
        case SET_TAG_POSTS:
            return {
                ...state,
                posts: action.data
            }
        default:
            return state
    }
}

export default tag_posts;