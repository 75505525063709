import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";

const IsLoggedIn = ({ children, user }) => {

    const navigate = useNavigate();

    useEffect(() => {
        if (user !== null && user && !user.id) {
            localStorage.setItem('login-popup', 'true');
            return navigate('/');
        }
    }, [user]);

    return (
        <>
            {children}
        </>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data
});

export default connect(mapStateToProps)(IsLoggedIn);