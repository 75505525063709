import React, { useEffect, useState } from "react";
import Appbar from "./Appbar";
import { ReactComponent as CreateIcon } from '../../assets/images/plus.svg';
import { get_tags } from '../../store/actions/post';
import { connect } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import { set_tags_func } from "../../store/actions/admin";

const AllTags = (props) => {

    const [openCreate, setOpenCreate] = useState(false);
    const [name, setName] = useState('');
    const [loadingBtn, setLoadinBtn] = useState(false);

    const handleCreate = () => {
        setLoadinBtn(true);
        if (name.length > 0) {
            axios.post('/admin/tags', { name }).then(res => {
                props.set_tags([res.data.tag, ...props.tags]);
                setLoadinBtn(false);
                setOpenCreate(false);
            }).catch(_error => {
                toast.warn('Tags details error!');
            });
        }
    }

    const handleRemove = (id) => {
        axios.delete(`/admin/tags/${id}`).then(res => {
            if (res.data.success) {
                const newTagsArr = [...props.tags];
                const tagIndex = newTagsArr.findIndex((tag) => tag.id == id);
                if (tagIndex !== -1) {
                    newTagsArr.splice(tagIndex, 1);
                    props.set_tags([...newTagsArr]);
                }
            } else toast.warn('Tags details error!');
        }).catch(_error => {
            toast.warn('Tags details error');
        })
    }

    useEffect(() => {
        props.get_tags();
    }, []);

    return (
        <div className="admin-area">
            <Appbar />
            <div className="admin-groups">
                <form>
                    <div className="top">
                        <h4>Tags</h4>
                        <CreateIcon style={{ fill: 'rgba(255,255,255,0.8)' }} onClick={() => setOpenCreate(true)} />
                    </div>
                    <div className="users">
                        <ul className="admin-tags">
                            {props.tags !== null ? (
                                <>
                                    {props.tags.length > 0 ? (
                                        <>
                                            {props.tags.map(tag => (
                                                <li style={{ height: '15px' }} key={tag.id}>
                                                    <h6>{tag.name}</h6>
                                                    <CreateIcon onClick={() => handleRemove(tag.id)} style={{ width: '14px', fill: '#555', transform: 'rotate(45deg)', marginLeft: '5px' }} />
                                                </li>
                                            ))}
                                        </>
                                    ) : (
                                        <h5 style={{ width: '100%', textAlign: 'center', marginTop: '15px', color: '#555' }}></h5>
                                    )}
                                </>
                            ) : (
                                <h5>Loadin...</h5>
                            )}
                        </ul>
                    </div>
                </form>
            </div>
            {openCreate && (
                <div className="create-group">
                    <div className="overlay"></div>
                    <div className="modal">
                        <div className="head">
                            <h5>create tags</h5>
                            <CreateIcon onClick={() => setOpenCreate(false)} />
                        </div>
                        <div className="body">
                            <input onChange={(e) => setName(e.target.value)} className="el" type="text" placeholder="Enter tag name" />
                            {loadingBtn ? (
                                <button>Loading...</button>
                            ) : (
                                <button onClick={() => handleCreate()}>create tag</button>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    tags: state.tags.data
});

const mapDispatchToProps = (dispatch) => ({
    get_tags: () => dispatch(get_tags()),
    set_tags: (data) => dispatch(set_tags_func(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(AllTags);