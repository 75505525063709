import React from "react";
import PostLoading from "./Post";

const PostsLoading = ({ items }) => {
    return (
        <div className="posts-loading">
            {(items ? items : [1,2,3]).map((_, key) => (
                <PostLoading key={key} />
            ))}
        </div>
    )
}

export default PostsLoading;