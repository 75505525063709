import axios from "axios";
import { toast } from "react-toastify";
import { socket } from "../../App";
import { upload_image } from "../../assets/utils/upload";
import { set_notification } from "./notification";
import { set_success } from "./success";
import { SEARCH_USERS_PAGE, SEARCH_USERS_REALTIME, SET_FRIENDS, SET_FRIEND_REQUESTS, SET_PROFILE, SET_SEARCH, SET_USER, SET_USER_POSTS } from "./types";

export const set_user = (data) => ({
    type: SET_USER,
    data: data
});

const set_user_posts = (data) => ({
    type: SET_USER_POSTS,
    data: data
});

export const set_profile = (data) => ({
    type: SET_PROFILE,
    data: data
});

export const set_user_func = (data) => (dispatch) => {
    dispatch(set_user(data));
}

export const create_user = (data) => (dispatch) => {
    axios.post('/user/register', data).then(res => {
        if (res.data.success) {
            dispatch(set_notification({ success: true, component: 'register' }));
            dispatch(get_user());
        }
        else dispatch(set_user(res.data.user));
    })
        .catch(error => {
            dispatch(set_notification({ success: false, errors: error.response.data.errors[0], component: 'register' }));
        })
}

export const login_user = (data) => (dispatch) => {
    axios.post('/user/login', data).then(res => {
        if (res.data.success) {
            dispatch(set_notification({ success: true, component: 'login' }));
            dispatch(get_user());
        }
        else dispatch(set_user(res.data.user));
    })
        .catch(error => {
            dispatch(set_notification({ success: false, errors: error.response.data.errors[0], component: 'login' }));
        })
}

export const get_user = () => (dispatch) => {
    axios.get('/user').then(res => {
        if (res.data.success) dispatch(set_user(res.data.user));
        else dispatch(set_user({}));
    })
        .catch(error => {
            dispatch(set_user({}));
            // dispatch(set_notification({ success: false, message: 'Please login first' }));
        });
}

export const get_profile = (id) => (dispatch) => {
    axios.get(`/user/profile/${id}`).then(res => {
        if (res.data.success) dispatch(set_profile(res.data.user));
        else dispatch(set_profile(null));
    })
        .catch(error => {
            dispatch(set_profile(null));
        });
}

export const set_user_posts_func = (data) => (dispatch) => {
    dispatch(set_user_posts(data));
}

export const get_user_posts = (id) => (dispatch) => {
    axios.get(`/posts/user/${id}`).then(res => {
        dispatch(set_user_posts(res.data.posts));
    }).catch(error => {
        dispatch(set_user_posts(null));
    })
}

export const change_user_image = ({ image, uid }) => async (dispatch) => {
    const uploadImage = await upload_image(image, 'user');
    image = uploadImage;
    axios.put('/user/image', { image }).then(res => {
        if (res.data.success) {
            dispatch(set_notification({ message: 'Profile image has been changed!', success: true, com: 'profile_image' }));
            dispatch(get_profile(uid));
        }
        set_notification({ message: 'Please try again', success: false, com: 'profile_image' });
    }).catch(error => {
        dispatch(set_notification({ message: 'Please try again', success: false, com: 'profile_image' }));
    });
}

export const change_about = ({ data, id }) => (dispatch) => {
    axios.put('/user/about', data).then(res => {
        dispatch(get_profile(id))
    }).catch(error => {
    });
}

// export const add_remove_friend = (data) => (dispatch) => {
// if (data.follow) {
//     socket.emit('send-follow', { to_id: data.id, from_id: data.from_id, from_username: data.from_username, notification: 'Following you, Follow back' })
// }
//     axios.put(`/user/friends/${data.id}`).then(res => {
//         dispatch(get_user());
//         if (res.data.success) dispatch(set_success({ component: 'profile', success: true }));
//     }).catch(error => {
//     });
// }

export const search_users = (username) => (dispatch) => {
    axios.get(`/user/search/${username}`).then(res => {
        dispatch({ type: SET_SEARCH, data: res.data.users });
    }).catch(error => {
        dispatch({ type: SET_SEARCH, data: [] });
    });
}

export const search_users_page = (username) => (dispatch) => {
    axios.get(`/user/search/${username}`).then(res => {
        dispatch({ type: SEARCH_USERS_PAGE, data: res.data.users });
    }).catch(error => {
        dispatch({ type: SEARCH_USERS_PAGE, data: [] });
    });
}

export const update_cover = ({ image, id }) => async (dispatch) => {
    const uploadImage = await upload_image(image, 'user');
    image = uploadImage;
    axios.put('/user/cover', { image }).then(res => {
        dispatch(get_profile(id));
        dispatch(get_user());
    });
}

export const clear_profile = () => (dispatch) => {
    dispatch(set_profile({}));
}

export const logout = () => (dispatch) => {
    axios.get('/user/logout').then(res => {
        if (res.data.success) {
            window.location.reload();
            dispatch(get_user());
        }
        else toast.warn('Logout error');
    });
}

export const remove_unread_messages = (chat_id) => (dispatch) => {
    axios.delete(`/user/unread/${chat_id}`).then(res => {
        dispatch(get_user());
    });
}

export const set_friends = (data) => ({
    type: SET_FRIENDS,
    data: data
});

export const set_friends_func = (data) => (dispatch) => {
    dispatch(set_friends(data))
}

export const get_friends = () => (dispatch) => {
    axios.get('/user/friends').then(res => {
        dispatch(set_friends(res.data.friends));
    });
}

export const set_friend_requests = (data) => ({
    type: SET_FRIEND_REQUESTS,
    data: data
});

export const set_friend_requests_func = (data) => (dispatch) => {
    dispatch(set_friend_requests(data))
}

export const get_friend_requests = () => (dispatch) => {
    axios.get('/user/requests').then(res => {
        dispatch(set_friend_requests(res.data.requests));
    });
}

export const report_to_admin = (data) => (dispatch) => {
    axios.post('/user/report', data).then(res => {
        toast.success('Report sent!');
    });
}

// export const get_fri_chat = (data) => (dispatch) => {
//     axios.get(`/chat/fri?${data.fri_id}`).then(res => )
// }
