import React, { useEffect, useReducer, useRef, useState } from "react";
import LeftArrow from '../../assets/images/arrow-left.svg';
import ProfleImage from '../../assets/images/profile.jpg';
import TestImage from '../../assets/images/test.jpg';
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import MenuDots from '../../assets/images/menu-dots.svg';
import TrashIcon from '../../assets/images/trash.svg';
import LinkIcon from '../../assets/images/link.svg';
import ReportIcon from '../../assets/images/remove-user.svg';
import SupportIcon from '../../assets/images/support.png';
import SupportBWIcon from '../../assets/images/support-nocolor.png';
import CommentIcon from '../../assets/images/chat.png';
import ShareIcon from '../../assets/images/share.svg';
import { connect } from "react-redux";
import { add_comment, add_remove_support, add_reply, get_post, remove_comment, remove_reply, set_post_func } from "../../store/actions/post";
import FaceImage from '../../assets/images/face.svg';
import { format } from "timeago.js";
import { socket } from "../../App";
import Tag_people from "./tag_people";
import AddFriendIcon from '../../assets/images/add-friend.svg';
import { toast } from "react-toastify";
import Content from "./Content";
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import NewUpdates from "./NewUpdates";
import ReportUser from "../ReportUser";
import TextareaAutosize from 'react-textarea-autosize';
import AnonymousProfile from '../../assets/images/anonymous.svg';
import { v4 as UUID } from "uuid";
import axios from "axios";
import PostViewLoading from "../Loading/PostViewLoading";
import Replies from "./Replies";
import { Spinner } from "loading-animations-react";
import ShareDialog from "./shareDialog";
import NotFound from "../NotFound";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { filesUrl } from "../../App";

const PostView = (props) => {

    const [post, setPost] = useState(null);
    const [open, setOpen] = useState(null);
    const [comMenu, setComMenu] = useState(null);
    const [openmenu, setOpenmenu] = useState(false);
    const [supported, setSupported] = useState(false);
    const [comment, setComment] = useState('');
    const [supports, setSupports] = useState([]);
    const [reply, setReply] = useState('');
    const [comments, setComments] = useState([]);
    const [comPublic, setComPublic] = useState(true);
    const [showPeople, setShowPeople] = useState(false);
    const [animateIcon, setAnimateIcon] = useState(false);
    const [replyState, setReplyState] = useState(false);
    const [openReplyMenu, setOpenReplyMenu] = useState(null);
    const [openSubReplies, setOpenSubReplies] = useState(null);
    const [haveUpdates, setHaveUpdates] = useState(false);
    const [subReply, setSubReply] = useState('');
    const [openReportUser, setOpenReportUser] = useState(null);
    const [openEmoji, setOpenEmoji] = useState(null);
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const [publicReply, setPublicReply] = useState(true);
    const [commentsLength, setCommentLength] = useState(0);
    const [commentPageCount, setCommentPageCount] = useState(0);
    const [openComReport, setOpenComReport] = useState(null);
    const [openReplyReport, setOpenReplyReport] = useState(null);
    const [alreadyFriend, setAlreadyFriends] = useState(null);
    const [requestPendng, setRequestPendng] = useState(false);
    const [openShare, setOpenShare] = useState(false);
    const [pageWidth, setPageWidth] = useState(window.innerWidth);
    const [update, setUpdate] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [redirectToHome, setRedirectToHome] = useState(false);
    const [reportUser, setReportUser] = useState(null);
    const [comments_length, set_comments_length] = useState(0);
    const [currentPage, setCurrentPage] = useState(10);
    const replyMenuRef = useRef();
    const menuRef = useRef();
    const comMenuRef = useRef();
    const emojiRef = useRef();

    const navigate = useNavigate();

    const { id } = useParams();

    const handleOpen = (id) => {
        if (open) setOpen(null);
        else setOpen(id);
    }

    const handleMenu = () => {
        if (openmenu) setOpenmenu(false);
        else {
            setComMenu(false);
            setOpenmenu(true);
        }
    }

    const handleReplyMenu = (id) => {
        if (openReplyMenu) setOpenReplyMenu(null);
        else {
            setComMenu(null);
            setOpenmenu(false);
            setOpenReplyMenu(id);
        }
    }

    const handleSupport = () => {
        if (props.user && props.user.id) {
            axios.put(`/posts/support/${props.post.id}`, { from_username: props.user.username, to_id: props.post.user.id }).then(res => {
                socket.emit('send-support', { post_id: props.post.id, user_id: props.user.id, notification: res.data.notification, added: supported ? false : true });
            }).catch(error => {
                toast.warn('Request timed out!');
            });
        }
        if (supported) {
            setSupported(false);
            setSupports(supports - 1);
        }
        else {
            setSupported(true);
            setSupports(supports + 1);
        }
        // if (props.user && props.user.id) socket.emit('send-like', { user_id: props.user.id, post_id: props.post.id, add: supported ? false : true, post_user: props.post.user.id, from_username: props.user.username });
        setAnimateIcon(true);
        setTimeout(() => {
            setAnimateIcon(false);
        }, [200]);
    }

    socket.on('recive-like', (data) => {
        if (props.post) {
            if (data.post_id == props.post.id) {
                if (props.user) {
                    if (props.user.id !== data.user_id) {
                        if (!data.error) {
                            setSupports(data.add ? supports + 1 : supports - 1);
                            const supportIndex = props.post.supports.find((sup) => sup.user_id == data.user_id);
                            if (supportIndex !== -1) {
                                props.post.supports.splice(supportIndex, 1);
                            } else {
                                props.post.supprots.unshift(data);
                            }
                        }
                    }
                } else {
                    if (!data.error) {
                        setSupports(data.add ? supports + 1 : supports - 1);
                        const supportIndex = props.post.supports.find((sup) => sup.user_id == data.user_id);
                        if (supportIndex !== -1) {
                            props.post.supports.splice(supportIndex, 1);
                        } else {
                            props.post.supprots.unshift(data);
                        }
                    }
                }
            }
        }
    });

    const handleComMenu = (id) => {
        if (comMenu) setComMenu(null);
        else {
            setOpenmenu(false);
            setComMenu(id);
        }
    }

    const line_none = {
        textDecorationLine: 'none',
        color: 'black',
        display: 'flex',
        alignItems: 'center'
    }

    const clearState = () => {
        if (openmenu || comMenu) {
            setOpenmenu(false);
            setComMenu(false);
        }
    }

    const go_back = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            if (props.post) {
                if (props.post.groupId) {
                    navigate(`/groups/${props.post.groupId}`, { replace: true });
                } else navigate('/', { replace: true });
            }
        }
    }

    const handleComment = () => {
        if (props.user && props.user.id && comment) {
            const tmpId = UUID()
            const comarr1 = [{ id: tmpId, comment, public: comPublic, user: { username: props.user.username, id: props.user.id, image: props.user.image, postUsername: props.post.user.username }, posting: true, replies: [] }, ...props.post.comments];
            setComments([...comarr1]);
            setComment('');
            axios.post(`/posts/comment/${props.post.id}`, { comment, public: comPublic, postUsername: props.post.user.username, toId: props.post.user.id, from_username: props.user.username, postPublic: props.post.public }).then(res => {
                const data = res.data.comment;
                if (data.post_id == props.post.id) {
                    const comarr = [data, ...props.post.comments];
                    const tmpComIndex = comarr.findIndex((com) => com.id == tmpId);
                    if (tmpComIndex !== -1) comarr.splice(tmpComIndex, 1);
                    const comIndex = props.post.comments.findIndex((com) => com.id == data.id);
                    if (comIndex == -1) {
                        setComments([...comarr]);
                        props.post.comments.unshift(data)
                        set_comments_length(prev => prev + 1);
                    }
                    socket.emit('add-comment', { comment: res.data.comment, notification: res.data.notification })
                }
                setComPublic(true);
            })
                .catch(error => {
                    toast.warn('Request timed out!');
                });
        }
    }

    const handleReply = (comment_id, comment_user, comment_public) => {
        if (props.user && props.user.id) {
            if (reply) {
                handleOpen(null)
                axios.post(`/posts/reply/${comment_id}`, { reply: reply, toId: comment_user.id, postId: props.post.id, commentId: comment_id, postUsername: props.post.user.username, public: publicReply, commentPublic: comment_public, commentUsername: comment_user.username, from_username: props.user.username }).then(res => {
                    const newComArr = [...comments];
                    const comIndex = newComArr.findIndex((com) => com.id == res.data.reply.comment_id);
                    if (comIndex !== -1) {
                        res.data.reply.comment = res.data.reply.comment_id;
                        res.data.reply.public = Boolean(res.data.reply.public);
                        // newComArr[comIndex].replies_length++
                        newComArr[comIndex].replies = [res.data.reply, ...newComArr[comIndex].replies];
                        setComments([...newComArr]);
                    }
                    socket.emit('add-reply', res.data);
                    setPublicReply(true);
                    setReply('');
                    forceUpdate();
                    setUpdate(!update);
                }).catch(error => {
                    toast.warn('Request timed out!');
                });
            }
        }
    }

    const handleGetComments = () => {
        axios.get(`/posts/comments/${id}?page=${comments.length < 10 ? comments.length : currentPage}`).then(res => {
            setComments([...comments, ...res.data.comments]);
            // setCommentPageCount(commentPageCount + 10);
            setCurrentPage(prev => prev + 10);
        });
    }

    useEffect(() => {
        if (props.post && props.post.comments) {
            setComments([...props.post.comments]);
        }
    }, [props.post && props.post.comments]);

    useEffect(() => {
        if (props.post) setSupports(props.post.supports)
    }, [props.post && props.post.supports]);

    const handleRemoveComment = (comment_id) => {
        const commentIndex = comments.findIndex((element) => element.id == comment_id);
        const indexOfComment = props.post.comments.findIndex((element) => element.id == comment_id);
        const comarr = [...comments];
        if (commentIndex !== -1) {
            comarr.splice(commentIndex, 1);
            setComments([...comarr])
            props.remove_comment({ commentId: comment_id });
            props.post.comments.splice(indexOfComment, 1);
            set_comments_length(prev => prev - 1);
        }
    }

    const onEmojiClick = (event, emojiObject) => {
        setChosenEmoji(emojiObject);
        setComment(comment + emojiObject.native)
    }

    const handleRemoveReply = (comment_id, reply_id) => {
        const newComArr = [...props.post.comments];
        const commentIndex = newComArr.findIndex((element) => element.id == comment_id);
        if (commentIndex !== -1) {
            const replyIndex = newComArr[commentIndex].replies.findIndex((repl) => repl.id == reply_id);
            if (replyIndex !== -1) {
                newComArr[commentIndex].replies.splice(replyIndex, 1);
                setReplyState(!replyState);
                setComments([...newComArr]);
                props.post.comments[commentIndex].replies.splice(replyIndex, 1);
                props.remove_reply({ id: reply_id });
            }
        }
    }

    useEffect(() => {

        props.set_post(null);

        if (props.user !== null) {
            props.get_post(id, props.user.id);
        }

    }, []);

    useEffect(() => {
        if (props.post && props.post.comments) setComments([...props.post.comments]);
    }, [props.post && props.post.comments]);

    useEffect(() => {
        socket.on('recive-comment', (data) => {
            if (props.post && props.post.id == data.post_id) {
                if (props.user && data.user.id == props.user.id) {
                    if (props.post && props.post.comments) {
                        const comlength = [];
                        const comexits = props.post.comments.find((com) => data.id == com.id);
                        props.post.comments.map((com, index) => {
                            if (data.id == com.id) {
                                comlength.push(index);
                            }
                        });
                        if (comlength.length <= 0) setComments([data, ...props.post.comments]);
                        comlength.length = 0;
                    }
                    if (data.user.id !== props.user.id) setHaveUpdates(true);
                } else setHaveUpdates(true);
            }
        });
    }, []);

    socket.on('recive-reply', (data) => {
        if (props.post) {
            if (data.post_id == props.post.id) {
                if (props.user && props.user.id) {
                    if (props.user.id !== data.user.id) {
                        setHaveUpdates(true);
                    }
                } else setHaveUpdates(true);
            }
        }
    });

    socket.on('recive-comment', (data) => {
        if (props.post) {
            if (props.post.id == data.post_id) {
                if (props.user && props.user.id) {
                    if (props.user.id !== data.user.id) {
                        setHaveUpdates(true);
                    }
                } else {
                    setHaveUpdates(true);
                }
            }
        }
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) setOpenmenu(false);
            if (comMenuRef.current && !comMenuRef.current.contains(event.target)) setComMenu(null);
            if (replyMenuRef.current && !replyMenuRef.current.contains(event.target)) setOpenReplyMenu(null);
            if (emojiRef.current && !emojiRef.current.contains(event.target)) setOpenEmoji(false);
        }

        document.body.addEventListener('mousedown', handleClickOutside);
        return () => document.body.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleGoToChat = async (reply_user_id, reply) => {
        axios.get(`/chat/fri/${reply_user_id}`).then(res => {
            if (res.data.chat) {
                navigate(`/chat/${res.data.chat}?reply_urery=${reply.substr(0, 150)}&post_url=${`/post/${props.post.id}`}`);
            }
        });
    }

    const sharePost = () => {
        if (props.post && props.post.id) {
            // if (navigator.share) {
            navigator.share({ url: `${props.post.id}`, title: 'Share', text: 'Share the post' });
            // } else setOpenShare(true);
        }
    }

    const handlePostUrlCopy = (post_id) => {
        if ('clipboard' in navigator) {
            navigator.clipboard.writeText(`${window.location.origin}/post/${post_id}`);
        } else {
            document.execCommand('copy', true, `${window.location.origin}/post/${post_id}`)
        }
        toast.success('Link coppied');
    }

    const resetPage = () => {
        props.set_post(null);
    }


    useEffect(() => {
        setPost(props.post);
        if (props.post) {
            if (props.post.supported) setSupported(true);
            else setSupported(false);

            if (props.post.comments_length) {
                setCommentLength(props.post.comments_length);
            } else {
                setCommentLength(0);
            }
        }
    }, [props.post]);

    useEffect(() => {
        props.get_post(id, props.user ? props.user.id : null);
    }, [id]);

    useEffect(() => {
        window.addEventListener('resize', (event) => {
            setPageWidth(window.innerWidth);
        });
    }, []);

    useEffect(() => {
        if (props.post) {
            set_comments_length(props.post.comments_length);
        }
    }, [props.post]);

    socket.on('revice-support', (data) => {
        if (post && post.id) {
            if (data.post_id == props.post.id) {
                if (props.user && props.user.id) {
                    if (data.user_id !== props.user.id) {
                        if (data.added) setSupports(supports + 1);
                        else setSupports(supports - 1);
                    }
                } else {
                    if (data.added) setSupports(supports + 1);
                    else setSupports(supports - 1);
                }
            }
        }
    });


    const getMoreReplies = (replies, com_id) => {
        const newComArr = [...comments]
        const comIndex = comments.findIndex(com => com.id == com_id);
        if (comIndex !== -1) {
            newComArr[comIndex].replies = [...newComArr[comIndex].replies, ...replies];
            setComments([...newComArr]);
            forceUpdate();
        }
    }

    const DeletePost = () => {
        const anwser = window.confirm('Are you sure, This will remove the post');
        if (anwser) {
            axios.delete(`/posts/${id}`).then(res => {
                setRedirectToHome(true);
            }).catch(error => {
                setRedirectToHome(true);
                toast.warn('Post deleted error! Try again');
            });
        }
    }

    // friends

    const handleAlreadyFriend = (id) => {
        if (props.user.id !== id) {
            setAlreadyFriends(null);
            axios.get(`/user/friends/${id}`).then(res => {
                if (res.data.friend) setAlreadyFriends(true);
                else if (res.data.pending) {
                    setAlreadyFriends(false);
                    setRequestPendng(true);
                }
                else setAlreadyFriends(false);
            }).catch(error => {
                setAlreadyFriends(false);
            });
        } else {
            setAlreadyFriends(false);
            setRequestPendng(false);
        }
    }

    const add_friend_request = () => {
        if (props.post && props.user && props.user.id) {
            if (props.user) {
                axios.post(`/user/request/${props.post.user.id}`, { from_username: props.user.username }).then(res => {
                    setRequestPendng(true);
                    if (res.data.notification) {
                        socket.emit('friend-request-notification', res.data.notification);
                    }
                });
            }
        }
    }

    // friends

    return (
        <div onClick={clearState} className="post-view-area">
            {openShare && <ShareDialog handleClose={() => setOpenShare(false)} postId={props.post.id} />}
            <NewUpdates resetPage={resetPage} haveUpdates={haveUpdates} setHaveUpdates={() => setHaveUpdates(false)} all={false} postId={id} />
            {reportUser && (
                <ReportUser postUser={reportUser ? reportUser : props.post && props.post.user} handleClose={() => setReportUser(null)} />
            )}
            {props.post !== null ? (
                <>
                    {props.post && props.post.id ? (
                        <div className="post-view">
                            {redirectToHome && <Navigate to={props.post.groupId ? `/groups/${props.post.groupId}` : '/'} />}
                            <div className="post">
                                {props.post.tag_people && props.post.tag_people > 0 ? (
                                    <>
                                        {showPeople && (
                                            <Tag_people post_id={props.post.id} people={props.post.tag_people} open={showPeople} handleOpen={(id) => setShowPeople(!showPeople)} />
                                        )}
                                    </>
                                ) : ''}
                                <div className="head">
                                    <img onClick={go_back} src={LeftArrow} alt="back" />
                                    <h5>Go back</h5>
                                </div>
                                <div className="user">
                                    {props.post.public ? (
                                        <section style={{ display: 'flex', alignItems: 'flex-start' }}>
                                            <Link style={line_none} to={`#`}>
                                                <div className="img" style={{ backgroundImage: `url(${props.post && (props.post.user.image ? props.post.user.image : filesUrl + '/static/user/default.png')})` }}></div>
                                                <section>
                                                    <h5 style={{ display: 'flex', alignItems: 'center' }}><Link style={{ textDecorationLine: 'none', color: '#444', "&:hover": { textDecorationLine: 'underline' } }} to={`/user/${props.post.user.id}`}>{props.post && (pageWidth > 350 ? props.post.user.name : (props.post.user.name.length > 7 ? `${props.post.user.name.substr(0, 7)}...` : props.post.user.name))}</Link> {props.post.tag_people > 0 && (
                                                        <>
                                                            <p style={{ marginLeft: '8px', marginRight: '8px' }} onClick={() => setShowPeople(true)}>is with</p> <strong onClick={() => setShowPeople(true)} className="underline">{props.post.tag_people}</strong> <p style={{ marginLeft: '8px' }}>{pageWidth > 400 ? 'people' : '...'}</p>
                                                        </>
                                                    )}
                                                    </h5>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                            <h5 style={{ color: 'rgba(0,0,0,0.5)', fontSize: '0.8rem', textDecorationLine: 'none', cursor: 'default', marginLeft: '0' }} className="none-underline">@{props.post.user.username.length > 15 ? props.post.user.username.substr(0, 15) : props.post.user.username}</h5>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                {props.post.groupId && props.post.groupName && (
                                                                    <Link style={{ textDecorationLine: 'none', color: '#444' }} to={props.post.groupId ? `/groups/${props.post.groupId}` : '#'}>
                                                                        <p style={{ marginRight: '10px', color: '#444' }} className="underline-hover"><strong>{props.post.groupName && props.post.groupName.length > 20 ? `${props.post.groupName.substr(0, 20)}...` : props.post.groupName} .</strong></p>
                                                                    </Link>
                                                                )}
                                                                <p>{format(props.post.created_at)}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </Link>
                                        </section>
                                    ) : (
                                        <Link style={line_none} to='#'>
                                            <div className="img" style={{ backgroundImage: `url(${AnonymousProfile})` }}></div>
                                            <section>
                                                <h5 style={{ display: 'flex', alignItems: 'center' }}>Anonymous</h5>
                                                <p>{format(props.post.created_at)}</p>
                                            </section>
                                        </Link>
                                    )}
                                    <div className="menu-area">
                                        <img onClick={() => {
                                            handleAlreadyFriend(props.post.user.id)
                                            handleMenu()
                                        }} className="icon" src={MenuDots} alt="menu" />
                                        <div className={openmenu ? 'menu show-menu' : 'menu'}>
                                            {alreadyFriend == null ? (
                                                <div style={{ padding: '7px 0px' }} className="center3">
                                                    <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                                                </div>
                                            ) : (
                                                <section>
                                                    <span onClick={() => handlePostUrlCopy(props.post.id)} style={{ marginLeft: '-15px' }}>
                                                        <img src={LinkIcon} alt="copy-link" />
                                                        <h6>copy link</h6>
                                                    </span>
                                                    {props.user && !(props.user.id == props.post.user.id) && (
                                                        <span onClick={() => {
                                                            setReportUser(props.post.id);
                                                            setOpenReportUser(true);
                                                        }} style={{ marginLeft: '-15px' }}>
                                                            <img src={ReportIcon} alt="report" />
                                                            <h6>Report user</h6>
                                                        </span>
                                                    )}
                                                    {props.user && ((props.user.id == props.post.user.id) || props.user.is_admin) && (
                                                        <span onClick={DeletePost} style={{ color: '#f0112b', marginLeft: '-15px' }}>
                                                            <img src={TrashIcon} alt="copy-link" />
                                                            <h6>Remove post</h6>
                                                        </span>
                                                    )}
                                                    {props.user && props.user.id && (
                                                        <>
                                                            {!alreadyFriend && (
                                                                <>
                                                                    {requestPendng ? (
                                                                        <>
                                                                            {props.user && (props.user.id !== (props.post && props.post.user.id)) && (
                                                                                <span style={{ marginLeft: '-18px' }} onClick={() => {
                                                                                    setOpenmenu(false)
                                                                                }}>
                                                                                    <img src={AddFriendIcon} alt="add-frined" />
                                                                                    <h6>request pending</h6>
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            {props.user && (props.user.id !== (props.post && props.post.user.id)) && (
                                                                                <span style={{ marginLeft: '-18px' }} onClick={() => {
                                                                                    setOpenmenu(false)
                                                                                    add_friend_request()
                                                                                }}>
                                                                                    <img src={AddFriendIcon} alt="add-frined" />
                                                                                    <h6>Add Friend</h6>
                                                                                </span>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </section>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="content">
                                    <Content long={true}>{props.post.content}</Content>
                                    <div className="tags">
                                        {props.post.tags && props.post.tags.map((tag, key) => (
                                            <Link key={key} style={line_none} to={`/tag/${tag}`}>
                                                <h5>{tag}</h5>
                                            </Link>
                                        ))}
                                    </div>
                                </div>
                                {props.post.image && (
                                    <LazyLoadImage placeholderSrc={process.env.PUBLIC_URL + 'image-placeholder.jpg'} src={props.post.image} alt="post" className="post-image" />
                                )}
                                <div className="action">
                                    <section>
                                        {props.user && props.user.id && props.user.active ? (
                                            <>
                                                <div className="support">
                                                    {supported ? (
                                                        <img className={animateIcon ? "support-icon popup" : "support-icon"} onClick={handleSupport} src={SupportIcon} alt="support" />
                                                    ) : (
                                                        <img className={animateIcon ? "support-icon popup" : "support-icon"} onClick={handleSupport} src={SupportBWIcon} alt="support" />
                                                    )}
                                                    <h4>support</h4>
                                                </div>
                                                {props.post && supports > 0 ? (
                                                    <span>{supports}</span>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className="support">
                                                    <img style={{ cursor: 'default' }} className={animateIcon ? "support-icon popup" : "support-icon"} src={SupportBWIcon} alt="support" />
                                                    <h4>support</h4>
                                                </div>
                                                {props.post && supports > 0 ? (
                                                    <span>{supports}</span>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </>
                                        )}
                                    </section>
                                    <section>
                                        <Link to={props.post && `/post/${props.post.id}`}>
                                            <img src={CommentIcon} alt="comment" />
                                        </Link>
                                        {props.post && commentsLength > 0 ? (
                                            <span>{commentsLength}</span>
                                        ) : (
                                            <span></span>
                                        )}
                                    </section>
                                    <section>
                                        <img onClick={() => sharePost()} src={ShareIcon} alt="share" />
                                    </section>
                                </div>
                                <div className="add-comment-area">
                                    {props.user && props.user.id ? (
                                        <>
                                            {!props.user.active ? (
                                                <form className="add-comment" style={{ position: 'relative' }}>
                                                    <img style={{ width: '22px', filter: 'none', cursor: 'default' }} src={FaceImage} alt="emoji" />
                                                    <TextareaAutosize disabled placeholder="Account Temporarily Blocked" />
                                                </form>
                                            ) : (
                                                <form className="add-comment" style={{ position: 'relative' }}>
                                                    {openEmoji && (
                                                        <div ref={emojiRef} style={{ position: 'absolute', bottom: '30px' }} >
                                                            <Picker data={data} onEmojiSelect={(e) => onEmojiClick(null, e)} />
                                                        </div>
                                                    )}
                                                    <img onClick={() => setOpenEmoji(!openEmoji)} style={{ width: '22px' }} src={FaceImage} alt="emoji" />
                                                    <TextareaAutosize onChange={(e) => setComment(e.target.value)} value={comment} placeholder="Enter comments" />
                                                </form>
                                            )}
                                        </>
                                    ) : (
                                        <form className="add-comment" style={{ position: 'relative' }}>
                                            <img style={{ width: '22px', filter: 'none', cursor: 'default' }} src={FaceImage} alt="emoji" />
                                            <TextareaAutosize disabled placeholder="Please login to comment" />
                                        </form>
                                    )}
                                    {props.user.active && (
                                        <div className="comment-action">
                                            {comment.length > 0 && (
                                                <>
                                                    <div className="checkbox">
                                                        <label htmlFor='anony'>
                                                            <h5>comment Anonymously</h5>
                                                        </label>
                                                        <input onChange={(e) => e.target.checked ? setComPublic(false) : setComPublic(true)} id='anony' type="checkbox" />
                                                    </div>
                                                    <button onClick={() => handleComment()}>comment</button>
                                                </>
                                            )}
                                        </div>
                                    )}
                                </div>
                                <div className="comments">
                                    <ul>
                                        {comments.map(com => (
                                            <li id={com.id} key={com.id}>
                                                {openComReport == com.id && (
                                                    <ReportUser postUser={com.user} handleClose={() => setOpenComReport(null)} />
                                                )}
                                                <section>
                                                    <div className="user">
                                                        {com.public ? (
                                                            <section>
                                                                <Link style={line_none} to={`/user/${com.user.id}`}>
                                                                    <div className="avatar" style={{ backgroundImage: `url(${com.user.image ? com.user.image : filesUrl + '/static/user/default.png'})` }}></div>
                                                                    <h5>&nbsp;&nbsp;&nbsp;{com.user.username}</h5>
                                                                </Link>
                                                            </section>
                                                        ) : (
                                                            <section>
                                                                <Link style={{ ...line_none, cursor: 'default' }} to="#">
                                                                    <div className="avatar" style={{ backgroundImage: `url(${AnonymousProfile})` }}></div>
                                                                    <h5 style={{ textDecorationLine: 'none' }}>&nbsp;&nbsp;&nbsp;Anonymous</h5>
                                                                </Link>
                                                            </section>
                                                        )}
                                                        <div className="menu-area">
                                                            <img onClick={() => handleComMenu(com.id)} className="icon" src={MenuDots} alt="menu" />
                                                            <div className={comMenu == com.id ? 'menu show-menu' : 'menu'}>
                                                                <section>
                                                                    {props.user && !(props.user.id == com.user.id) && (
                                                                        <span onClick={() => {
                                                                            setOpenComReport(com.id)
                                                                            setComMenu(null);
                                                                        }}>
                                                                            <img src={ReportIcon} alt="report" />
                                                                            <h6>Report user</h6>
                                                                        </span>
                                                                    )}
                                                                    {props.user && ((props.user.id == com.user.id) || props.user.is_admin) && (
                                                                        <span onClick={() => handleRemoveComment(com.id)} style={{ color: '#f0112b' }}>
                                                                            <img src={TrashIcon} alt="copy-link" />
                                                                            <h6>Remove comment</h6>
                                                                        </span>
                                                                    )}
                                                                </section>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                                <div className="comment">
                                                    <p style={{ marginTop: '-10px' }}>{com.comment}</p>
                                                    <span style={{ fontSize: '0.7rem', fontWeight: '400', marginLeft: '30px' }}>{format(com.created_at)}</span>
                                                    {com.posting ? (
                                                        <span style={{ fontSize: '0.7rem', marginLeft: '10px', marginRight: '0px' }}>posting...</span>
                                                    ) : (
                                                        <button className="underline" onClick={() => handleOpen(com.id)}>Reply</button>
                                                    )}
                                                    {open == com.id && (
                                                        <>
                                                            {props.user && props.user.id ? (
                                                                <>
                                                                    {!props.user.active ? (
                                                                        <div style={{ width: 'calc(100% - 35px)' }} className="reply">
                                                                            <div className="add-reply">
                                                                                <form onSubmit={(e) => e.preventDefault()} className="add-comment" style={{ position: 'relative' }}>
                                                                                    <div className="user-img" style={{ backgroundImage: `url(${(props.user && props.user.image) ? props.user.image : filesUrl + '/static/user/default.png'})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                                                                    <TextareaAutosize style={{ width: '100vw' }} disabled placeholder="Account Temporarily Blocked" />
                                                                                </form>
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div style={{ width: 'calc(100% - 35px)' }} className="reply">
                                                                            <div className="add-reply">
                                                                                <form onSubmit={handleComment} className="add-comment" style={{ position: 'relative', maxWidth: 'fit-content' }}>
                                                                                    <div className="user-img" style={{ backgroundImage: `url(${(props.user && props.user.image) ? props.user.image : filesUrl + '/static/user/default.png'})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                                                                    <TextareaAutosize style={{ width: '100vw' }} onChange={(e) => setReply(e.target.value)} value={reply} placeholder="Type here..." />
                                                                                </form>
                                                                                <div className="comment-action">
                                                                                    {reply.length > 0 && (
                                                                                        <>
                                                                                            <div className="checkbox">
                                                                                                <label htmlFor='anony'>
                                                                                                    <h5>comment Anonymously</h5>
                                                                                                </label>
                                                                                                <input onChange={(e) => setPublicReply(!e.target.checked)} id='anony' type="checkbox" />
                                                                                            </div>
                                                                                            <button onClick={() => handleReply(com.id, com.user, com.public)}>reply</button>
                                                                                        </>
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </>
                                                            ) : (
                                                                <div style={{ width: 'calc(100% - 35px)' }} className="reply">
                                                                    <div className="add-reply">
                                                                        <form onSubmit={(e) => e.preventDefault()} className="add-comment" style={{ position: 'relative' }}>
                                                                            <div className="user-img" style={{ backgroundImage: `url(${(props.user && props.user.image) ? props.user.image : filesUrl + '/static/user/default.png'})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}></div>
                                                                            <TextareaAutosize style={{ width: '100vw' }} disabled placeholder="Please login to reply" />
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                    <Replies
                                                        replies={com.replies}
                                                        getMoreReplies={getMoreReplies}
                                                        post_id={props.post.id}
                                                        setOpenReplyMenu={setOpenReplyMenu}
                                                        setReportUser={(user) => setReportUser(user)}
                                                        setOpenReplyReport={() => setOpenReportUser(true)}
                                                        com={com}
                                                        handleReplyMenu={handleReplyMenu}
                                                        commentId={com.id}
                                                        openReplyMenu={openReplyMenu}
                                                        handleRemoveReply={handleRemoveReply}
                                                        handleGoToChat={handleGoToChat}
                                                        get_profile={props.get_profile}
                                                        user={props.user}
                                                        repliesLength={com.replies_length}
                                                    />
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div>
                                    {(comments_length > comments.length) && (comments_length !== comments.length) && (
                                        <h5 onClick={() => handleGetComments()} style={{ color: '#555', fontSize: '0.8rem', marginLeft: '15px', cursor: 'pointer', marginTop: '15px' }} className="underline">View more comments</h5>
                                    )}
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {/* <h4>Post not found</h4> */}
                            <NotFound />
                        </div>
                    )}
                </>
            ) : (
                <PostViewLoading />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    post: state.one_post.data,
    user: state.user.data,
    friends: state.friends.data
});

const mapDispatchToProps = (dispatch) => ({
    get_post: (id, user_id) => dispatch(get_post(id, user_id)),
    add_comment: (data) => dispatch(add_comment(data)),
    remove_comment: (id) => dispatch(remove_comment(id)),
    handle_support: (id) => dispatch(add_remove_support(id)),
    add_reply: (data) => dispatch(add_reply(data)),
    remove_reply: (id) => dispatch(remove_reply(id)),
    set_post: (data) => dispatch(set_post_func(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(PostView);