import { SET_SEARCH, SET_GROUPS_SEARCH } from "../actions/types"

const INITIAL_STATE = {
    data: null,
    search_group: null
}

const search_reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_SEARCH:
            return {
                ...state,
                data: action.data
            }
        case SET_GROUPS_SEARCH:
            return {
                ...state,
                search_groups: action.search_groups
            }
        default:
            return state;
    }
}

export default search_reducer;