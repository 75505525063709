import React, { useRef } from "react";

const ImagePreview = (props) => {

    const scroll = useRef();

    scroll.current?.scrollIntoView({ behavior: "smooth" });

    return (
        <div className="image-popup">
            <div className="overlay" onClick={props.handleClose}></div>
            <div ref={scroll} className="modal">
                <img src={props.image} style={{ maxHeight: '400px' }} />
                <div style={{ marginTop: '10px' }} className="btns">
                    <button onClick={props.handleClose} style={{ backgroundColor: '#505152' }}>close</button>
                </div>
            </div>
        </div>
    )
}

export default ImagePreview;