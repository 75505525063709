import React, { useEffect, useRef, useState } from "react";
import BackButton from '../assets/images/angle-left.svg';
import { tags } from "../assets/data/tags";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ProfileImage from '../assets/images/profile2.jpg';
import { connect } from "react-redux";
import { get_tags } from "../store/actions/post";
import { get_groups } from "../store/actions/group";
import { clear_profile, search_users } from "../store/actions/users";
import { ReactComponent as PlusIcon } from '../assets/images/plus.svg';
import axios from "axios";
import { filesUrl } from "../App";

const Explore = (props) => {

    const [searchtxt, setSearchtxt] = useState('');
    const [redirect, setredirect] = useState(false);
    const [openres, setOpenres] = useState(false);
    const resultsRef = useRef();
    const [myGroups, setMyGroups] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfGroups, setNumberOfGroups] = useState(0);

    const get_my_groups = () => {
        axios.get(`/group/mygroups?page=${currentPage}`).then(res => {
            setNumberOfGroups(res.data.number_of_groups);
            setCurrentPage(prev => prev + 10);
            if (myGroups) setMyGroups([...myGroups, ...res.data.groups]);
            else setMyGroups([...res.data.groups]);
        });
    }

    const navigate = useNavigate();

    const go_back = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }

    const handleSearch = (event) => {
        event.preventDefault();
        if (searchtxt.length) {
            setredirect(true);
            // setredirect(false);
        }
    }

    useEffect(() => {
        props.search_users(searchtxt);
        if (searchtxt.length > 0) setOpenres(true);
    }, [searchtxt]);

    useEffect(() => {
        props.get_tags();
        props.get_groups();

        get_my_groups();

        const handleClickOutside = (event) => {
            if (!resultsRef.current.contains(event.target)) setOpenres(false);
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);

    }, []);

    return (
        <div className="explore-area">
            {redirect && (
                <Navigate to={`/search/${searchtxt}`} />
            )}
            <div className="explore">
                <div className="head">
                    <img onClick={go_back} src={BackButton} alt="back" />
                    <h4># Explore</h4>
                </div>
                <form onSubmit={(e) => handleSearch(e)} className="search">
                    <input onFocus={() => setOpenres(true)} onChange={(e) => setSearchtxt(e.target.value)} type="text" placeholder="search..." />
                    <div ref={resultsRef} className={openres ? 'results active' : 'results'}>
                        <p>Hit enter to start the search or choose a result from the list</p>
                        <ul>
                            {props.search !== null ? (
                                <>
                                    {props.search && props.search.length > 0 ? (
                                        <>
                                            {props.search.map((user, key) => (
                                                <Link key={key} style={{ color: '#444', textDecorationLine: 'none' }} to={`/user/${user.id}`}>
                                                    <li>
                                                        <section>
                                                            <div className="img" style={{ backgroundImage: `url(${user.image ? user.image : filesUrl + '/static/user/default.png'})` }}></div>
                                                            <h5>{user.username}</h5>
                                                        </section>
                                                    </li>
                                                </Link>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            {searchtxt.length > 0 ? (
                                                <span></span>
                                            ) : (
                                                <span>search users by username</span>
                                            )}
                                        </>
                                    )}
                                </>
                            ) : (
                                <span></span>
                            )}
                        </ul>
                    </div>
                </form>
                <div className="tags">
                    {props.tags && props.tags.map((tag, key) => (
                        <Link style={{ textDecoration: 'none', color: '#000' }} to={`/tag/${tag.name}`}>
                            <h5 key={key}>{tag.name}</h5>
                        </Link>
                    ))}
                </div>
                <div className="service-links">
                    <a href="https://happyfacess.com" target="_blank" style={{ width: 'fit-content' }}><h6 style={{ width: 'fit-content' }}>Connect With Our Volunteers</h6></a>
                    <a href="https://companion.happyfacess.com" target="_blank" style={{ width: 'fit-content' }}><h6 style={{ width: 'fit-content' }}>Companion Services</h6></a>
                </div>
                {props.user && props.user.id && (
                    <h3>My Groups</h3>
                )}
                <div className="groups">
                    {myGroups && myGroups.map((grou, key) => (
                        <Link key={key} style={{ textDecoration: 'none', color: '#000' }} to={`/groups/${grou.id}`}>
                            <section>
                                <div className="img" style={{ backgroundImage: `url(${grou.image})` }}></div>
                                <h4>{grou.name.length > 12 ? `${grou.name.substr(0, 11)}...` : grou.name}</h4>
                            </section>
                        </Link>
                    ))}
                    {myGroups && numberOfGroups > myGroups.length && (
                        <Link style={{ textDecoration: 'none', color: '#000' }} to="#">
                            <section onClick={get_my_groups}>
                                <div style={{ border: '1px solid rgba(0,0,0,0.2)', display: 'flex', justifyContent: 'center', alignItems: 'center' }} className="img svg-width-20">
                                    <PlusIcon />
                                </div>
                                <h4>more</h4>
                            </section>
                        </Link>
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    tags: state.tags.data,
    groups: state.groups.data,
    search: state.search.data
});

const mapDispatchToProps = (dispatch) => ({
    get_tags: () => dispatch(get_tags()),
    get_groups: () => dispatch(get_groups()),
    search_users: (username) => dispatch(search_users(username)),
    clear_profile: () => dispatch(clear_profile())
});

export default connect(mapStateToProps, mapDispatchToProps)(Explore);