import React from "react";
import { ReactComponent as CloseIcon } from '../../assets/images/plus.svg';

const UserView = (props) => {
    return (
        <div className="admin-user-view-area">
            <div className="overlay"></div>
            <div className="modal">
                <div className="head">
                    <h5>Nirmal salinda</h5>
                    <CloseIcon onClick={() => props.handleOpen()} />
                </div>
                <div className="body">
                    {props.user !== null ? (
                        <>
                            {props.user.id ? (
                                <>
                                    <div className="cover-img" style={{ backgroundImage: `url(${props.user.cover})` }}>
                                        <div className="img" style={{ backgroundImage: `url(${props.user.image})` }}></div>
                                    </div>
                                    <h3>{props.user.name}</h3>
                                    <p style={{ fontSize: '0.8rem', fontWeight: '300' }}>{props.user.email}</p>
                                    {props.user.active ? (
                                        <p style={{ fontSize: '0.8rem', fontWeight: '300', color: 'blue' }}>Active user</p>
                                    ) : (
                                        <p style={{ fontSize: '0.8rem', fontWeight: '300', color: 'red' }}>Blocked user</p>
                                    )}
                                    {props.user.admin && (
                                        <p style={{ fontSize: '0.8rem', fontWeight: '300', color: 'blue' }}>Admin user</p>
                                    )}
                                    <textarea disabled value={props.user.about ? props.user.about : 'No about'} />
                                </>
                            ) : (
                                <h5>Not found</h5>
                            )}
                        </>
                    ) : (
                        <h5>Loading...</h5>
                    )}
                </div>
            </div>
        </div>
    )
}

export default UserView;