import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, Navigate } from "react-router-dom";
import TestImage from '../assets/images/test.jpg';
import ProfileImage from '../assets/images/profile.jpg';
import BackBtn from '../assets/images/angle-left.svg';
import Posts from './Post/Posts';
import SettingsIcon from '../assets/images/settings.svg';
import EducationIcon from '../assets/images/graduation-cap.svg';
import JobsIcon from '../assets/images/briefcase.svg';
import LocationIcon from '../assets/images/location.svg';
import PencilIcon from '../assets/images/pencil.svg';
import PencilLightIcon from '../assets/images/pencil-light.svg';
import AllFriends from "./AllFriends";
import CreatePost from "./Post/create";
import { set_group, set_group_posts_func } from "../store/actions/group";
import { connect } from "react-redux";
import { clear_profile } from "../store/actions/users";
import { socket } from "../App";
import { toast } from "react-toastify";
import NewUpdates from "./Post/NewUpdates";
import GroupLoading from "./Loading/GroupLoading";
import PostsLoading from "./Loading/PostLoading";
import axios from "axios";
import { Spinner } from "loading-animations-react";
import IsLoggedIn from "./IsLoggedIn";

const Group = (props) => {

    const [open, setOpen] = useState(false);
    const [openfri, setOpenfri] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [groupPosts, setGroupPosts] = useState([]);
    const [haveUpdates, setHaveUpdates] = useState(false);
    const [newUpdatesData, setNewUpdatesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isMember, setIsMember] = useState(null);
    const [posts, setPosts] = useState([]);
    const [loadingPosts, setLoadingPosts] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [postsLoading, setPostsLoading] = useState(false);
    const [noMore, setNoMore] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const scroll = useRef();

    const get_posts = () => {
        setPostsLoading(true);
        axios.get(`/group/posts/${id}?page=${currentPage * 5}`).then(res => {
            if (res.data.number_of_posts_pages >= currentPage) {
                setTotalPages(res.data.number_of_posts_pages);
                props.set_group_posts([...posts, ...res.data.posts]);
                setLoadingPosts(false);
                setPostsLoading(false);
            } else {
                setNoMore(true);
                setPostsLoading(false);
                setLoadingPosts(false);
            }
        });
    }

    useEffect(() => {

        get_posts();

        const onScroll = (event) => {
            const { scrollHeight, scrollTop } = document.documentElement;
            if (!postsLoading && !noMore && (window.innerHeight + scrollTop + 1 >= scrollHeight)) {
                setCurrentPage(prev => prev + 1);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);

    }, []);

    useEffect(() => {
        get_posts();
    }, [currentPage]);

    useEffect(() => {
        if (props.group_posts) setPosts(props.group_posts);
    }, [props.group_posts]);

    const resetPage = () => {
        setCurrentPage(0);
        setLoadingPosts(true);
        props.set_group_posts([]);
        setPosts([]);
        // get_posts();
        axios.get(`/group/posts/${id}`).then(res => {
            props.set_group_posts(res.data.posts);
            setLoading(false);
            setPostsLoading(false);
            setLoadingPosts(false);
        }).catch(error => {
            props.set_group_posts([]);
            setLoading(false);
            setPostsLoading(false);
            setLoadingPosts(false);
        });
        scroll.current?.scrollIntoView({ behavior: "smooth" });
    }

    const { id } = useParams();

    const handleOpenfri = () => {
        if (openfri) setOpenfri(false);
        else setOpenfri(true);
    }

    const handleOpen = () => {
        if (open) setOpen(false);
        else setOpen(true);
    }

    const navigate = useNavigate();

    const go_back = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/groups', { replace: true });
        }
    }

    const get_group = () => {
        setLoading(true);
        axios.get(`/group/${id}`).then(res => {
            if (res.data.group) props.set_group(res.data.group);
            else props.set_group({ new: true });
            setLoading(false);
        }).catch(error => {
            setLoading(false);
        });
    }

    const get_is_group_member = () => {
        setLoading(true);
        axios.get(`/group/member/${id}`).then(res => {
            setLoading(false);
            setIsMember(res.data.member);
        });
    }

    const join_to_group = () => {
        if (id) {
            setLoading(true);
            axios.post(`/group/member/${id}`, { username: props.user.username }).then(res => {
                setIsMember(true);
                get_group();
            }).catch(error => {
                setIsMember(false);
                setLoading(false);
                toast.warn('servers are busy!');
            });
        }
    }

    const remove_from_group = () => {
        if (id) {
            setLoading(true);
            axios.delete(`/group/member/${id}`).then(res => {
                setIsMember(false);
                setLoading(false);
                get_group();
            }).catch(error => {
                setIsMember(false);
                setLoading(false);
                toast.warn('Group remove error!');
            });
        }
    }

    useEffect(() => {
        get_group();
        get_is_group_member();
        props.clear_profile();
    }, [id]);

    socket.on('recive-group-post', (data) => {
        if (props.user && props.user.id) {
            if (data.user.id !== props.user.id) setHaveUpdates(true);
        } else setHaveUpdates(true);
    });

    useEffect(() => {
        if (props.user && props.user.id) {
            socket.emit('add-new-user', props.user.id);
        }
    }, [props.user]);

    useEffect(() => {
        const handleSetWidth = (event) => {
            setWindowWidth(window.innerWidth);
        }

        window.addEventListener('resize', handleSetWidth);
        return () => window.removeEventListener('resize', handleSetWidth);

    }, []);

    return (
        <IsLoggedIn>
            <div className="group-area">
                {props.group !== null && !loading ? (
                    <>
                        {props.group ? (
                            <>
                                {isMember == false || props.group.new ? (
                                    <div className="center">
                                        {redirect && <Navigate to='/' />}
                                        <section>
                                            <h4>This is a private group</h4>
                                            <p>Join to unlock a world of community and support</p>
                                            <button onClick={() => join_to_group()}>Join</button>
                                        </section>
                                    </div>
                                ) : (
                                    <>
                                        {openfri && (
                                            <AllFriends handleOpen={handleOpenfri} />
                                        )}
                                        {open && (
                                            <CreatePost resetPage={resetPage} groupId={props.group.id} groupName={props.group.name} group={true} handleOpen={handleOpen} open={open} />
                                        )}
                                        <NewUpdates resetPage={resetPage} data={newUpdatesData} haveUpdates={haveUpdates} setHaveUpdates={() => setHaveUpdates(false)} groupId={props.group.id} />
                                        <div onClick={go_back} className="back-btn">
                                            <img src={BackBtn} alt="back" />
                                        </div>
                                        <div className="cover-image" style={{ backgroundImage: `url(${props.group && props.group.image})` }}>
                                            <div className="overlay">
                                                <input type="file" id="cover-img" hidden />
                                                <div className="user">
                                                    <div className="sec1">
                                                        <section>
                                                            <h3>{props.group && props.group.name}</h3>
                                                        </section>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="second-area">
                                            <section>
                                                <p>Posts {props.group.posts ? props.group.posts : 'No posts in this group'}</p>
                                                <p>Members {props.group.members}</p>
                                            </section>
                                            <section>
                                                <button onClick={() => remove_from_group()}>Leave group</button>
                                            </section>
                                        </div>
                                        <div className="user-about">
                                            <div className="bio">
                                                <div className="about">
                                                    <p>
                                                        <strong>About: &nbsp;</strong>
                                                        {props.group && props.group.about}
                                                    </p>
                                                </div>
                                                <h4>Tags</h4>
                                                <div className="hobbies">
                                                    {props.group.tags && props.group.tags.split(',').map((tag, key) => (
                                                        <h5 key={key}>{tag}</h5>
                                                    ))}
                                                </div>
                                            </div>
                                            <section ref={scroll} className="group-posts-section">
                                                {props.user && props.user.id && props.user.active && (
                                                    <div onClick={handleOpen} className="create-top">
                                                        <div className="img" style={{ backgroundImage: `url(${props.user.image})` }}></div>
                                                        <h5>{windowWidth > 359 ? 'Write something inside group' : 'Write something inside...'}</h5>
                                                    </div>
                                                )}
                                                {!loadingPosts ? (
                                                    <>
                                                        {posts.length > 0 ? (
                                                            <>
                                                                <div className="group-posts">
                                                                    <Posts group={id} posts={posts} />
                                                                </div>
                                                                {postsLoading && posts.length > 4 && (
                                                                    <div style={{ marginTop: '15px', marginBottom: '15px' }}>
                                                                        <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <div style={{ width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                <h4>No posts found</h4>
                                                            </div>
                                                        )}
                                                    </>
                                                ) : (
                                                    <PostsLoading />
                                                )}
                                            </section>
                                        </div>
                                    </>
                                )}
                            </>
                        ) : (
                            <h4>Group not found</h4>
                        )}
                    </>
                ) : (
                    <GroupLoading />
                )}
            </div>
        </IsLoggedIn>
    );
}

const mapStateToProps = (state) => ({
    group: state.groups.group,
    group_posts: state.group_posts.posts,
    user: state.user.data
});

const mapDispatchToProps = (dispatch) => ({
    set_group: (data) => dispatch(set_group(data)),
    clear_profile: () => dispatch(clear_profile()),
    set_group_posts: (data) => dispatch(set_group_posts_func(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Group);