import axios from "axios";
import { toast } from "react-toastify";
import { upload_image } from "../../assets/utils/upload";
import { set_tags } from "./post";
import { SET_ADMIN_GROUPS, SET_ADMIN_USER, SET_REPORTS } from "./types";

export const set_admin_user = (data) => (dispatch) => {
    dispatch({ type: SET_ADMIN_USER, data: data });
}

const set_reports = (data) => ({
    type: SET_REPORTS,
    reports: data
});

export const get_admin_user = () => (dispatch) => {
    axios.get('/admin/super').then(res => {
        dispatch(set_admin_user(res.data.user));
    }).catch(error => {
        dispatch(set_admin_user({}));
    });
}

const set_groups = (data) => ({
    type: SET_ADMIN_GROUPS,
    groups: data
});

export const set_reports_func = (data) => (dispatch) => {
    dispatch(set_reports(data));
}

export const get_reports = () => (dispatch) => {
    axios.get('/admin/reports').then(res => {
        dispatch(set_reports(res.data.reports));
    }).catch(error => {
    });
}

export const get_groups = () => (dispatch) => {
    axios.get('/admin/groups').then(res => {
        dispatch(set_groups(res.data.groups));
    }).catch(error => {
    });
}

export const create_group = (data) => async (dispatch) => {
    dispatch(set_groups(data));
}

export const set_groups_func = (data) => (dispatch) => {
    dispatch(set_groups(data))
}

export const set_tags_func = (data) => (dispatch) => {
    dispatch(set_tags(data));
}

// export const create_tag = (name) => (dispatch => {
// });
