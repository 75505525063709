import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ProfileLoading = () => {
    return (
        <div className="profile-loading-view">
            <SkeletonTheme baseColor="#edebeb" highlightColor="#e0dede">
                <div style={{ position: 'relative' }} className="profile-image">
                    <Skeleton style={{ width: '100vw', maxWidth: '900px', height: '300px' }} />
                </div>
            </SkeletonTheme>
        </div>
    )
}

export default ProfileLoading;