import React, { useEffect, useState, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import { ReactComponent as ImagesIcon } from "../../assets/images/images.svg";
import AsyncSelect from 'react-select/async';
import Compressor from 'compressorjs';
import { toast } from "react-toastify";

const ImageUpload = (props) => {

    const [zoom, setZoom] = useState();
    const [image, setImage] = useState();
    const [aspect, setAspect] = useState(1);
    const [croppedPixelArea, setCroppedPixelArea] = useState(null);
    const [fileName, setFileName] = useState('');
    const [crop, setCrop] = useState({ x: 0, y: 0 });

    const onFileChange = async (file) => {
        const imageData = await readFile(file)
        if ((imageData.size / (1024 * 1024)) < 1.5) {
            setImage(imageData);
        } else toast.warn('Image too large!');
    }

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles && acceptedFiles[0]) {
            onFileChange(acceptedFiles[0])
        }
    }, [])

    const { getRootProps, getInputProps } = useDropzone({ onDrop })

    const AspectRatios = [
        { id: 1, label: 'Normal', value: 1.3 },
        { id: 2, label: '1:1', value: 1 },
        { id: 3, label: '4:5', value: 4 / 5 },
        { id: 4, label: '16:9', value: 16 / 9 }
    ]

    const handleCropComplete = (_croppedArea, croppedAreaPixel) => {
        if (croppedAreaPixel !== croppedPixelArea) setCroppedPixelArea(croppedAreaPixel);
    }

    const handleUploadImage = async () => {
        croppedPixelArea.x = croppedPixelArea.x ? croppedPixelArea.x : 0;
        croppedPixelArea.y = croppedPixelArea.y ? croppedPixelArea.y : 0;
        const url = await getCroppedImg(URL.createObjectURL(image), croppedPixelArea);
        fetch(url)
            .then(response => response.blob())
            .then(blobData => {
                blobData.name = fileName;
                props.handleCroppedImage(blobData);
            });
    }

    const readFile = useCallback(file => {
        return new Promise((resolve, reject) => {
            try {
                getNormalizedFile(file)
                    .then(normalizedFile => resolve(normalizedFile))
                    .catch(error => reject(error))
            } catch (error) {
                reject(error)
            }
        })
    }, [])

    const getNormalizedFile = file => {
        return new Promise((resolve, reject) => {
            new Compressor(file, {
                maxWidth: 1000,
                maxHeight: 1000,
                success(normalizedFile) {
                    resolve(normalizedFile)
                },
                error(error) {
                    reject(error)
                },
            })
        })
    }

    useEffect(() => {
        if (image) setFileName(image.name)
    }, [image])

    return (
        <div className={props.open ? 'tags-area tags-active' : 'tags-area'}>
            <div className="head">
                <h3 onClick={() => {
                    if (image) {
                        handleUploadImage()
                        props.imageLoading(true)
                    }
                    props.handleOpen()
                    setCroppedPixelArea(null)
                }}>Done</h3>
                <AsyncSelect onChange={(e) => setAspect(e.value)} className="select-aspect" cacheOptions={AspectRatios} loadOptions={AspectRatios} defaultOptions={AspectRatios} />
            </div>
            <div className="img-crop">
                {image ? (
                    <>
                        <Cropper
                            image={image ? URL.createObjectURL(image) : ''}
                            aspect={aspect}
                            zoom={zoom}
                            onZoomChange={setZoom}
                            crop={crop}
                            onCropChange={setCrop}
                            onCropComplete={handleCropComplete}
                        />
                    </>
                ) : (
                    <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div className="drop-files-area">
                            <ImagesIcon />
                            <strong>Drop the images here...</strong>
                            <button><strong style={{ color: '#fff', fontFamily: '"Poppins", sans-serif' }}>Select Image</strong></button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default ImageUpload;