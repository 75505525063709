import React from "react";
import { connect } from "react-redux";
import CloseIcon from "../assets/images/plus.svg";
import Profile from "../assets/images/test.jpg";
import { create_chat } from "../store/actions/chat";
import { filesUrl } from "../App";

const ChatFrieds = (props) => {
    return (
        <div className={props.open ? "chat-friends" : "none"}>
            <div className="overlay"></div>
            <div className="modal">
                <div className="head">
                    <img onClick={() => props.handleOpen()} src={CloseIcon} alt="close" />
                </div>
                <div className="body">
                    <ul>
                        {props.user.friends.map((fri, key) => (
                            <li onClick={() => props.create_chat(fri.id)} key={key}>
                                <div className="img" style={{ backgroundImage: `url(${fri.image ? fri.image : filesUrl + '/static/user/default.png'})` }}></div>
                                <h4>{fri.username}</h4>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data
});

const mapDispatchToProps = (dispatch) => ({
    create_chat: (id) => dispatch(create_chat(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ChatFrieds);