import { SET_POST } from "../actions/types"

const INITIAL_STATE = {
    post: {}
}

const one_post_reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_POST:
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}

export default one_post_reducer;