import { SET_TAGS } from "../actions/types";

const INITIAL_STATE = {
    data: []
}

const tags_reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_TAGS:
            return {
                ...state,
                data: action.data
            }    
        default:
            return state
    }
}

export default tags_reducer;
