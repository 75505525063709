import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as NavIcon } from '../../assets/images/menu-burger.svg';
import { ReactComponent as TagsIcon } from '../../assets/images/bookmark.svg';
import { ReactComponent as GroupIcon } from '../../assets/images/users-alt.svg';
import { ReactComponent as HomeIcon } from '../../assets/images/home.svg';
import { ReactComponent as BanIcon } from '../../assets/images/ban.svg';
import { ReactComponent as PeopleIcon } from '../../assets/images/groups_nav.svg';
import { ReactComponent as ManageIcon } from '../../assets/images/settings-sliders.svg';
import { Link, Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { ToastContainer, toast, Flip } from "react-toastify";
import { set_user_func } from "../../store/actions/users";
import axios from "axios";
import { set_admin_user } from "../../store/actions/admin";
import { filesUrl } from "../../App";

const Appbar = (props) => {

    const [open, setOpen] = useState(false);
    const [openProfile, setOpenProfile] = useState(false);
    const profileDropDownRef = useRef();
    const [redirect, setRedirect] = useState('');

    const nav_data = [
        {
            name: 'Overview',
            icon: <HomeIcon />,
            url: '/admin'
        },
        {
            name: 'Groups',
            icon: <GroupIcon />,
            url: '/admin/groups'
        },
        {
            name: 'Tags',
            icon: <TagsIcon />,
            url: '/admin/tags'
        },
        {
            name: 'Blocked users',
            icon: <BanIcon />,
            url: '/admin/blocked'
        },
        {
            name: 'Users',
            icon: <PeopleIcon />,
            url: '/admin/users'
        }
    ];

    const handleLogout = () => {
        axios.get('/user/logout').then(_res => {
            props.set_user({});
            props.set_admin({});
            setRedirect('/admin/login');
        }).then(_error => {
            toast.warn('server error!');
        });
    }

    useEffect(() => {
    }, [props.user, props.admin_user]);

    useEffect(() => {
        const handleClose = (event) => {
            if (profileDropDownRef.current && !profileDropDownRef.current.contains(event.target)) setOpenProfile(false);
        }

        document.addEventListener('mousedown', handleClose);
        return () => document.removeEventListener('mousedown', handleClose);
    }, []);

    return (
        <div>
            <div className="hide-lg">
                <ToastContainer
                    transition={Flip}
                    position={toast.POSITION.BOTTOM_LEFT}
                    hideProgressBar={true}
                />
            </div>
            {redirect.length > 2 && (
                <Navigate to={redirect} />
            )}
            {props.admin_user !== null || props.user !== null ? (
                <>
                    {((props.user && props.user.id && props.user.is_admin) || (props.admin_user && props.admin_user.id)) ? (
                        <>
                            <div className="appbar">
                                <div onClick={() => setOpen(!open)}>
                                    <NavIcon />
                                </div>
                                <div ref={profileDropDownRef} className="admin-profile-drop">
                                    <div onClick={() => setOpenProfile(!openProfile)} className="img" style={{ backgroundImage: `url(${props.user.image ? props.user.image : filesUrl + '/static/user/default.png'})` }}></div>
                                    <section className={openProfile && 'active'}>
                                        <div className="profile-view">
                                            <Link to={`/user/${props.user.id}`}>
                                                <div className="pro-img" style={{ backgroundImage: `url(${props.user.image ? props.user.image : filesUrl + '/static/user/default.png'})` }}></div>
                                            </Link>
                                            <h5>{props.user.name}</h5>
                                            <p>{props.admin_user && props.admin_user.id ? 'super user' : 'admin'}</p>
                                            <span onClick={() => handleLogout()}>Logout</span>
                                        </div>
                                        <ul></ul>
                                    </section>
                                </div>
                            </div>
                            <div className={open ? 'admin-drawer active' : 'admin-drawer'}>
                                <div className="items">
                                    <ul>
                                        {nav_data.map((item, index) => (
                                            <Link key={index} style={{ textDecorationLine: 'none' }} to={item.url}>
                                                <li>{item.icon} {item.name}</li>
                                            </Link>
                                        ))}
                                        {props.admin_user && props.admin_user.id && (
                                            <Link style={{ textDecorationLine: 'none' }} to='/admin/manage'>
                                                <li><ManageIcon /> Manage admins</li>
                                            </Link>
                                        )}
                                    </ul>
                                </div>
                            </div>
                        </>
                    ) : (
                        <Navigate to='/admin/login' />
                    )}
                </>
            ) : (
                <h4>Loading...</h4>
            )}
            <Outlet />
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    admin_user: state.admin_user.data
});

const mapDispatchToProps = (dispatch) => ({
    set_user: (data) => dispatch(set_user_func(data)),
    set_admin: (data) => dispatch(set_admin_user(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Appbar);