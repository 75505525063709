import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Explore from "./components/Explore";
import Home from "./components/Home";
import Navbar from "./components/navbar";
import PostView from "./components/Post/view";
import Profile from "./components/Profile";
import Sidebar from "./components/Sidebar";
import SearchPage from "./components/Search";
import Chat from "./components/Chat";
import TagsPage from "./components/Post/TagsPage";
import Group from "./components/Group";
import { connect } from "react-redux";
import SignIn from "./components/Auth/SignIn";
import axios from "axios";
import { ToastContainer, toast, Flip } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { get_friends, get_friend_requests, get_user } from "./store/actions/users";
import { io } from "socket.io-client";
import Feed from "./components/Feed";
import Admin from "./components/Admin";
import AdminGroups from "./components/Admin/Groups";
import { get_notifications } from "./store/actions/notification";
import Requests from "./components/Requests";
import AllTags from "./components/Admin/Tags";
import { clear_group_state, set_group_posts_func } from "./store/actions/group";
import { get_muted_chats } from "./store/actions/chat";
import Friends from "./components/Friends";
import Blocked from "./components/Admin/Blocked";
import GroupOverview from "./components/Admin/GroupOverview";
import AllUsers from "./components/Admin/AllUsers";
import Admins from "./components/Admin/Admins";
import Login from "./components/Admin/Login";
import { get_admin_user } from "./store/actions/admin";
import Logo from './assets/images/logo.png';
import AddUsername from "./components/AddUsername";
import NotFound from "./components/NotFound";
import { set_post_func } from "./store/actions/post";
import { play_audio } from "./assets/utils/notify_sound";

// export const socket = io('http://localhost:8000');
export const socket = io('http://social.happyfacess.com');
export const filesUrl = 'https://storage.happyfacess.com';
// export const filesUrl = 'http://localhost:9000';

const App = (props) => {

    const [notifications, setNotifications] = useState([]);

    // axios.defaults.baseURL = 'http://localhost:8000/api';
    axios.defaults.baseURL = '/api';
    axios.defaults.withCredentials = true;
    axios.defaults.headers = {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true
    }

    useEffect(() => {
        props.get_user();
        props.get_muted_chats();
        props.get_admin_user();

        socket.connect();

    }, []);

    useEffect(() => {
        if (!window.location.pathname.includes('/groups')) {
            props.clear_group_state();
            props.set_group_posts(null);
        }
        if (!window.location.pathname.includes('/groups/')) {
            props.clear_group_state();
            props.set_group_posts(null);
        }

        if (!window.location.pathname.includes('/groups')) {
            props.clear_group_state();
            props.set_group_posts(null);
        }
        if (!window.location.pathname.includes('/groups/')) {
            props.clear_group_state();
            props.set_group_posts(null);
        }
    });

    window.onpopstate = (event) => {
        if (document.location.pathname.split('/')[1] == 'post') props.set_post(null);
    }

    useEffect(() => {
        if (document.location.pathname.split('/')[1]) props.set_post(null);
    }, [document.location.pathname]);

    useEffect(() => {
        if (props.user && props.user.id) {
            props.get_friends();
            props.get_friend_requests();
            socket.emit('add-new-user', props.user.id);
            socket.emit('add-new-user', props.user.id);
        }
    }, [props.user]);

    useEffect(() => {
        if (props.admin_user && props.admin_user.id) sessionStorage.setItem('super_user', true);
    }, [props.user, props.admin_user]);


    useEffect(() => {

        socket.on('recive-comment-notifi', (data) => {
            if (!notifications.some(notifi => notifi.id == data.id)) {
                setNotifications([...notifications, data])
                toast(`${data.from_username} commented to your post`);
                play_audio();
            }
        });

        socket.on('recive-reply-notifi', (data) => {
            if (!notifications.some(notifi => notifi.id == data.id)) {
                setNotifications([...notifications, data])
                toast(`${data.from_username} replied to your comment`);
                play_audio();
            }
        });

        socket.on('recive-tag-post-notifi', (data) => {
            if (!notifications.some(notifi => notifi.id == data.id)) {
                setNotifications([...notifications, data])
                toast(`${data.from_username} ${data.notification}`);
                play_audio();
            }
        });

        socket.on('recive-support-notification', (data) => {
            if (!notifications.some(notifi => notifi.id == data.id)) {
                setNotifications([...notifications, data])
                toast(`${data.from_username} ${data.notification}`);
                play_audio();
            }
        });

        socket.on('recive-sub-reply-notifi', (data) => {
            if (!notifications.some(notifi => notifi.id == data.id)) {
                setNotifications([...notifications, data])
                toast(`${data.from_username} ${data.notification}`);
                play_audio();
            }
        });

        socket.on('recive-block-notification', (data) => {
            if (!notifications.some(notifi => notifi.id == data.id)) {
                setNotifications([...notifications, data])
                play_audio();
                toast(`${data.from_username} blocked you`);
            }
        });

        socket.on('recive-unblock-notification', (data) => {
            if (!notifications.some(notifi => notifi.id == data.id)) {
                setNotifications([...notifications, data])
                play_audio();
                toast(`${data.from_username} unblocked you`);
            }
        });

        socket.on('recive-friend-request', (data) => {
            if (!notifications.some(notifi => notifi.id == data.id)) {
                setNotifications([...notifications, data])
                play_audio();
                toast(`${data.from_username} ${data.notification}`);
            }
        });

        socket.on('friend-request-accept-recive', (data) => {
            if (!notifications.some(notifi => notifi.id == data.id)) {
                setNotifications([...notifications, data])
                play_audio();
                toast(`${data.from_username} ${data.notification}`);
            }
        });

        socket.on('recive-notification', (data) => {
            if (!notifications.some(msg => msg.id == data.result.id)) {
                play_audio();
                toast(data.message);
                setNotifications([...notifications, { id: data.result.id }]);
            }
        });

    }, []);

    return (
        <div>
            {props.user == null ? (
                <div style={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img src={Logo} alt="happyfaces" style={{ width: '150px' }} />
                </div>
            ) : (
                <BrowserRouter>
                    {props.user && props.user.id && !props.user.username && (
                        <AddUsername />
                    )}
                    <div className="hide-md">
                        <ToastContainer
                            transition={Flip}
                            position={toast.POSITION.BOTTOM_LEFT}
                            hideProgressBar={true}
                        />
                    </div>
                    <Routes>
                        <Route path="/*" element={<NotFound />} />
                        <Route path="/" exact element={<><Navbar /><div className="app"><Sidebar /><Home /></div></>} />
                        <Route path="/user/:id" element={<><Navbar /><Profile /></>} />
                        <Route path="/post/:id" element={<><Navbar /><div className="app"><Sidebar /><PostView /></div></>} />
                        <Route path="/explore" element={<><Navbar /><div className="app"><Sidebar /><Explore /></div></>} />
                        <Route path="/search/:name" element={<><Navbar /><div className="app"><Sidebar /><SearchPage /></div></>} />
                        <Route path="/requests" element={<><Navbar /><div className="app"><Sidebar /><Requests /></div></>} />
                        <Route path="/friends" element={<><Navbar /><div className="app"><Sidebar /><Friends /></div></>} />
                        <Route path="/chat" element={<div><Chat /></div>} />
                        <Route path="/chat/:id" element={<div><Chat /></div>} />
                        <Route path="/tag/:name" element={<><Navbar /><div className="app"><TagsPage /></div></>} />
                        <Route path="/groups/:id" element={<><Navbar /><div className="app"><Group /></div></>} />
                        <Route path="/groups" element={<><Navbar /><Feed /></>} />
                        <Route path="/signin" element={<SignIn />} />
                        <Route path="admin/">
                            <Route path="" element={<Admin />} />
                            <Route path="groups" element={<><AdminGroups /></>} />
                            <Route path="groups/:groupId" element={<><GroupOverview /></>} />
                            <Route path="tags" element={<><AllTags /></>} />
                            <Route path="blocked" element={<><Blocked /></>} />
                            <Route path="users" element={<><AllUsers /></>} />
                            <Route path="manage" element={<><Admins /></>} />
                            <Route path="login" element={<><Login /></>} />
                        </Route>
                    </Routes>
                </BrowserRouter>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    notify: state.notify.data,
    user: state.user.data,
    friend_requests: state.friend_requests.data,
    admin_user: state.admin_user.data
});

const mapDispatchToProps = (dispatch) => ({
    get_user: () => dispatch(get_user()),
    get_notifications: () => dispatch(get_notifications()),
    clear_group_state: () => dispatch(clear_group_state()),
    get_friends: () => dispatch(get_friends()),
    get_friend_requests: () => dispatch(get_friend_requests()),
    get_muted_chats: () => dispatch(get_muted_chats()),
    get_admin_user: () => dispatch(get_admin_user()),
    set_group_posts: (data) => dispatch(set_group_posts_func(data)),
    set_post: (data) => dispatch(set_post_func(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(App);