import { filesUrl } from "../../App";
import axios from "axios";

export const upload_image = (file, folder, token) => {
    return new Promise((resolve, reject) => {
        const formdata = new FormData();
        formdata.append('image', file);
        formdata.append('token', token);
        formdata.append('name', file.name);
        axios.defaults.baseURL = filesUrl;
        axios.post(`/${folder}`, formdata).then(async (res) => {
            resolve(res.data.image);
        }).catch(error => {
            console.log({ upload: error })
            reject(error);
        });
        axios.defaults.baseURL = '/api';
    })
}