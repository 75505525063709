const { SET_ADMIN_USER } = require("../actions/types")

const INTIAL_STATE = {
    data: null
}

const admin_user_reducer = (state = INTIAL_STATE, action) => {
    switch (action.type) {
        case SET_ADMIN_USER:
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}

export default admin_user_reducer;