import { SET_MUTED_CHATS } from "../actions/types";

const INITIAL_STATE = {
    data: []
}

const muted_reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_MUTED_CHATS:
            return {
                ...state,
                data: action.data
            }
        default:
            return state
    }
}

export default muted_reducer;