import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const GroupSideLoading = () => {
    return (
        <div>
            {[1,2,3,4].map((_, key) => (
                <section key={key} style={{ width: '100%', maxWidth: '200px', display: 'flex', alignItems: 'center', marginLeft: '15px', marginTop: '15px' }}>
                    <SkeletonTheme baseColor="#edebeb" highlightColor="#e0dede">
                        <Skeleton circle style={{ width: '35px', height: '35px' }} />
                        <Skeleton style={{ width: '120px', height: '20px', marginLeft: '10px' }} />
                    </SkeletonTheme>
                </section>
            ))}
        </div>
    )
}

export default GroupSideLoading;