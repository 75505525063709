import { SET_CHATS } from "../actions/types"

const INITIAL_STATE = {
    chats: null
}

const chat_reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_CHATS:
            return {
                ...state,
                chats: action.chats
            }
        default:
            return state;
    }
}

export default chat_reducer;