import { SET_USER_POSTS } from "../actions/types";

const INITIAL_STATE = {
    data: null
}

const user_posts_reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER_POSTS:
            return {
                ...state,
                data: action.data
            }    
        default:
            return state
    }
}

export default user_posts_reducer;
