import React, { useEffect, useState } from "react";
import Appbar from "./Appbar";
import { ReactComponent as TrashIcon } from '../../assets/images/trash.svg';
import { ReactComponent as BanIcon } from '../../assets/images/ban.svg';
import axios from "axios";
import { get_reports, set_reports_func } from "../../store/actions/admin";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { upload_image } from "../../assets/utils/upload";
import { format } from "timeago.js";
import NotFound from "../NotFound";

const GroupOverview = (props) => {

    const [overview, setOverview] = useState(null);
    const [members, setMembers] = useState(null);
    const [image, setImage] = useState(null);
    const [about, setAbout] = useState('');
    const [aboutText, setAboutText] = useState(about);
    const [title, setTitle] = useState('');
    const [tags, setTags] = useState('');
    const [searchText, setSearchText] = useState('');
    const [searchResults, setSearchResults] = useState([]);

    const { groupId } = useParams();


    const getGroupOverview = () => {
        if (groupId) {
            axios.get(`/admin/overview/group/${groupId}`).then(res => {
                if (res.data.group) {
                    setOverview(res.data.group);
                    if (res.data.group && res.data.group.image) setImage(res.data.group.image);
                    if (res.data.group && res.data.group.name) setTitle(res.data.group.name);
                    if (res.data.group && res.data.group.tags) setTags(res.data.group.tags);
                    if (res.data.group && res.data.group.about) {
                        setAbout(res.data.group.about);
                        setAboutText(res.data.group.about);
                    }
                } else setOverview(undefined);
            }).catch(_error => {
                setOverview(undefined);
            });
        }
    }

    const handleBlock = (id, username) => {
        const anwser = window.confirm('Are you sure ? This will block user');
        if (anwser) {
            axios.post(`/admin/ban/${id}`, { username }).then(_res => {
                toast.success('blocked');
            }).catch(error => {
                if (error) {
                    if (error.response.status == 400) {
                        toast.warn('This user is already bocked');
                    }
                } else {
                    toast.warn('User credentials error!');
                }
            });
        }
    }

    const handleRemoveMember = (id) => {
        const anwser = window.confirm('Are you sure ? This will remove member from this group');
        if (anwser) {
            axios.delete(`/admin/group/member/${groupId}/${id}`).then(res => {
                toast.success(res.data.message);
                const newMembersArr = [...members];
                const memberIndex = newMembersArr.findIndex((member) => member.id == id);
                if (memberIndex !== -1) {
                    newMembersArr.splice(memberIndex, 1);
                    setMembers([...newMembersArr]);
                }
            }).catch(_error => {
                toast.warn('User credentials error!');
            });
        }
    }

    const handleUpdateImage = async (event) => {
        if (event.target.files[0]) {
            const uploaded_image = await upload_image(event.target.files[0], 'group', props.user.token);
            if (upload_image) {
                axios.put(`/admin/group/image/${groupId}`, { image: uploaded_image }).then(_res => {
                    setImage(URL.createObjectURL(event.target.files[0]));
                }).catch(_error => {
                    toast.warn('Group details updating error!');
                });
            } else toast.warn('Image upload error, try again');
        }
    }

    const handleUpdateAbout = () => {
        if (aboutText.length > 0) {
            axios.put(`/admin/group/about/${groupId}`, { about: aboutText }).then(_res => {
                setAbout(aboutText);
                setAboutText('');
                toast.success('updated');
            }).catch(_error => {
                toast.warn('Group details updating error!');
            });
        }
    }

    const handleUpdateName = () => {
        if (aboutText.length > 0) {
            axios.put(`/admin/group/name/${groupId}`, { name: title }).then(_res => {
                setTitle(title);
                toast.success('updated');
            }).catch(_error => {
                toast.warn('Group details updating error!');
            });
        }
    }

    const handleUpdateTags = () => {
        if (aboutText.length > 0) {
            axios.put(`/admin/group/tags/${groupId}`, { tags }).then(_res => {
                setTags(tags);
                toast.success('updated');
            }).catch(_error => {
                toast.warn('Group details updating error!');
            });
        }
    }


    useEffect(() => {
        axios.get(`/admin/group/member/${groupId}?username=${searchText}`).then(res => {
            setMembers(res.data.users);
        })
    }, [searchText]);

    useEffect(() => {
        getGroupOverview();
        axios.get(`/admin/members/group/${groupId}`).then(res => {
            setMembers(res.data.members);
            if (res.data.group && res.data.group.image) setImage(res.data.group.image);
            if (res.data.group && res.data.group.about) {
                setAbout(res.data.group.about);
                setAboutText(res.data.group.about);
            }
        });
    }, []);

    return (
        <div className="admin-area">
            {overview !== undefined ? (
                <>
                    <Appbar />
                    <div className="overview">
                        <div className="head">
                            <section>
                                <h4>Overview</h4>
                                {overview && (
                                    <ul>
                                        <li>
                                            <h5>Posts</h5>
                                            <p>{overview.number_of_posts}</p>
                                        </li>
                                        <li>
                                            <h5>Users</h5>
                                            <p>{overview.members}</p>
                                        </li>
                                    </ul>
                                )}
                            </section>
                            <input type="file" id='image' onChange={(event) => handleUpdateImage(event)} hidden />
                            <label htmlFor='image'>
                                <section className="hover-overlay-1" style={{ backgroundImage: `url(${image && image})`, backgroundSize: 'cover', backgroundPosition: 'center', cursor: 'pointer' }}>
                                    <h5>Update group image</h5>
                                </section>
                            </label>
                        </div>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} className="update-group-about">
                            <section style={{ display: 'flex', flexDirection: 'column', width: 'calc(100% - 20px)', maxWidth: '700px' }}>
                                {title && (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <input defaultValue={title} onChange={(e) => setTitle(e.target.value)} style={{ fontFamily: '"Poppins", sans-serif' }} />
                                        <button onClick={() => handleUpdateName()} style={{ marginLeft: '10px', width: 'fit-content' }}>update</button>
                                    </div>
                                )}
                                {/* {about && ( */}
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <textarea defaultValue={about} onChange={(e) => setAboutText(e.target.value)} style={{ fontFamily: '"Poppins", sans-serif' }}></textarea>
                                    <button onClick={() => handleUpdateAbout()} style={{ marginLeft: '10px', width: 'fit-content' }}>update</button>
                                </div>
                                {/* )} */}
                                {tags && (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <input defaultValue={tags} onChange={(e) => setTags(e.target.value)} style={{ fontFamily: '"Poppins", sans-serif', marginTop: '15px' }} />
                                        <button onClick={() => handleUpdateTags()} style={{ marginLeft: '10px', width: 'fit-content' }}>update</button>
                                    </div>
                                )}
                            </section>
                        </div>
                        <div className="reports">
                            <div className="top">
                                <h4>Group members</h4>
                            </div>
                            <div className="search">
                                <input onChange={(e) => setSearchText(e.target.value)} type="text" placeholder="Search users" />
                            </div>
                            <ul>
                                {members !== null ? (
                                    <>
                                        {members && members.map(member => (
                                            <li key={member.id}>
                                                <section>
                                                    <p><strong>username:</strong> {member.username}</p>
                                                    <p><strong>name:</strong> {member.name}</p>
                                                    <p><strong>email:</strong> {member.email}</p>
                                                    <p><strong>joined date:</strong> {format(member.created_at)}</p>
                                                </section>
                                                <div className="btns">
                                                    <acronym title="clear user data">
                                                        <button><TrashIcon onClick={() => handleRemoveMember(member.id)} /></button>
                                                    </acronym>
                                                    <acronym title="block user">
                                                        <button onClick={() => handleBlock(member.id, member.username)}><BanIcon /></button>
                                                    </acronym>
                                                </div>
                                            </li>
                                        ))}
                                    </>
                                ) : (
                                    <h4>Loading...</h4>
                                )}
                            </ul>
                        </div>
                    </div>
                </>
            ) : (
                <NotFound />
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    reports: state.admin.reports,
    user: state.user.data
});

const mapDispatchToProps = (dispatch) => ({
    get_reports: () => dispatch(get_reports()),
    set_reports: (data) => dispatch(set_reports_func(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupOverview);