import { SET_USER } from "../actions/types"

const INITIAL_STATE = {
    data: null,
    realtime: null
}

const user_reducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SET_USER:
            return {
                ...state,
                data: action.data
            }
        default:
            return state;
    }
}

export default user_reducer;