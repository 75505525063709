import React, { useEffect, useState } from "react";
import Posts from './Post/Posts';
import SettingsIcon from '../assets/images/settings.svg';
import EducationIcon from '../assets/images/graduation-cap.svg';
import JobsIcon from '../assets/images/briefcase.svg';
import LocationIcon from '../assets/images/location.svg';
import EditeProfile from "./EditeProfile";
import PencilIcon from '../assets/images/pencil.svg';
import PencilLightIcon from '../assets/images/pencil-light.svg';
import AllFriends from "./AllFriends";
import BackBtn from '../assets/images/angle-left.svg';
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { change_user_image, get_friends, get_friend_requests, get_profile, get_user, get_user_posts, set_friends_func, set_friend_requests_func, set_user_posts_func, update_cover } from "../store/actions/users";
import { connect } from "react-redux";
import { create_chat } from "../store/actions/chat";
import { filesUrl, socket } from "../App";
import 'react-loading-skeleton/dist/skeleton.css';
import CreatePost from "./Post/create";
import { toast } from "react-toastify";
import { SpinnerCircularFixed } from "spinners-react";
import axios from "axios";
import { get_notifications } from "../store/actions/notification";
import { ReactComponent as WarnIcon } from '../assets/images/warn.svg';
import { Spinner } from "loading-animations-react";
import { upload_image } from "../assets/utils/upload";
import PostsLoading from "./Loading/PostLoading";
import ProfileLoading from "./Loading/ProfileLoading";
import AcceptPosts from "./Post/AcceptPosts";
import IsLoggedIn from "./IsLoggedIn";
import NotFound from "./NotFound";

const Profile = (props) => {

    const [open, setOpen] = useState(false);
    const [openfri, setOpenfri] = useState(false);
    const [profileimage, setProfileimage] = useState(null);
    const [coverImage, setCoverImage] = useState(null);
    const [afri, setAfri] = useState(false);
    const [redirectToChat, setRedirectToChat] = useState(false);
    const [redirect, setRedirect] = useState(false);
    const [createOpen, setCreateOpen] = useState(false);
    const [request, setRequest] = useState(false);
    const [tempNotifi, setTempNotifi] = useState([]);
    const [loadinBtn, setLoadingBtn] = useState(false);
    const [chatUrl, setChatUrl] = useState('');
    const [loadingCover, setLoadingCover] = useState(false);
    const [loadingImage, setLoadinImage] = useState(false);
    const [taggedPosts, setTaggedPosts] = useState([]);
    const [alreadyFriends, setAlreadyFriends] = useState(false);
    const [pendingUser, setPendingUser] = useState(false);
    const [recivedRequest, setRecivedRequest] = useState(false);

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentTagPage, setCurrentTagPage] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [postsLoading, setPostsLoading] = useState(false);
    const [noMore, setNoMore] = useState(false);
    const [pendingPosts, setPendingPosts] = useState(null);
    const [postType, setPostType] = useState('my');
    const [number_of_tag_posts, set_number_of_tag_posts] = useState(0);

    const get_posts = () => {
        setPostsLoading(true);
        axios.get(`/posts/user/${id}?page=${posts.length > 0 ? currentPage * 5 : 0}`).then(async (res) => {
            if (res.data.number_of_posts_pages >= currentPage) {
                setTotalPages(res.data.number_of_posts_pages);
                setPosts([...posts, ...res.data.posts]);
                setLoading(false);
                setPostsLoading(false);
            } else {
                setNoMore(true);
                setPostsLoading(false);
                setLoading(false);
            }
        });
    }


    const get_tag_posts = () => {
        setPostsLoading(true);
        axios.get(`/posts/user/tagged?user_id=${id}&page=${currentPage * 5}`).then(async (res) => {
            if (res.data.number_of_posts_pages >= currentPage) {
                setTotalPages(res.data.number_of_posts_pages);
                setTaggedPosts([...taggedPosts, ...res.data.posts]);
                setLoading(false);
                setPostsLoading(false);
                setNoMore(false);
            } else {
                setNoMore(true);
                setPostsLoading(false);
                setLoading(false);
            }
        });
    }

    useEffect(() => {
        if (currentPage > 0) {
            if (postType == 'my') get_posts();
            else get_tag_posts();
        }
    }, [currentPage, currentTagPage]);

    const { id } = useParams();

    useEffect(() => {
        props.get_profile(id)
    }, []);

    const handleMessageUrl = () => {
        axios.get(`/chat/fri/${props.profile.id}`).then(res => {
            if (res.data.chat) {
                return navigate(`/chat/${res.data.chat}`);
            }
        });
    }

    const handleAlreadyFriend = (id) => {
        if (props.user.id !== id) {
            setLoadingBtn(true);
            axios.get(`/user/friends/${id}`).then(res => {
                if (res.data.friend) setAlreadyFriends(true);
                else if (res.data.pending) setPendingUser(true);
                else if (res.data.recive) setRecivedRequest(true);
                setLoadingBtn(false);
            }).catch(error => {
                setLoadingBtn(false);
            });
        }
    }

    useEffect(() => {
        if (chatUrl.length > 0) {
            navigate(`/chat/${chatUrl}`);
        }
    }, [chatUrl]);

    useEffect(() => {
        props.get_profile(id)
    }, [id]);

    useEffect(() => {
        if (props.profile && props.profile.id) {
            setProfileimage(props.profile.image);
            setCoverImage(props.profile.cover);
            axios.get(`/posts/notaccepted/${props.user.id}`).then(res => {
                // setTaggedPosts(res.data.posts);
            });
            if (props.user && props.user.id && props.profile.id !== props.user.id) {
                handleAlreadyFriend(props.profile.id);
            }
        }
    }, [props.profile]);

    const get_tagged_posts = () => {
        setLoading(true);
        setPostsLoading(true);
        axios.get(`/posts/user/tagged?user_id=${id}`).then(res => {
            setTaggedPosts(res.data.posts);
            setLoading(false);
            setPostsLoading(false);
        });
    }

    const handleOpen = () => {
        if (open) setOpen(false);
        else setOpen(true);
    }

    const handleOpenfri = () => {
        if (openfri) setOpenfri(false);
        else setOpenfri(true);
    }

    const navigate = useNavigate();

    const go_back = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }

    const handleProfileImage = async (image) => {
        const tmpImage = image;
        if (image) {
            const imageType = image.type.split('/');
            if (imageType[0] == 'image') {
                if (image.size <= 8388608) {
                    setLoadinImage(true);
                    upload_image(image, 'user', props.user.token).then(response => {
                        image = response;
                        axios.put('/user/image', { image }).then(res => {
                            setLoadinImage(false);
                            setProfileimage(URL.createObjectURL(tmpImage));
                        }).catch(_error => {
                            setLoadinImage(false);
                            toast.warn('Your details updating error!');
                        });
                    }).catch(_error => {
                        setLoadinImage(false);
                        toast.warn('Your details updating error!');
                    });
                } else toast.warn('Image too large!');
            } else toast.warn('Invalid file type!');
        }
    }

    const handleCoverImage = async (image) => {
        if (image) {
            const imageType = image.type.split('/');
            if (imageType[0] == 'image') {
                if (image.size <= 8388608) {
                    setLoadingCover(true);
                    const tmpImage = image;
                    upload_image(image, 'user', props.user.token).then(response => {
                        image = response;
                        axios.put('/user/cover', { image }).then(res => {
                            setLoadingCover(false);
                            setCoverImage(URL.createObjectURL(tmpImage));
                        }).catch(_error => {
                            setLoadingCover(false);
                            toast.warn('Your details updating error!');
                        });
                    }).catch(_error => {
                        setLoadingCover(false);
                        toast.warn('Your details updating error!');
                    });
                } else toast.warn('Image too large!');
            } else toast.warn('Invalid file type!');
        }
    }

    const handleAcceptPost = (post_id) => {
        axios.post(`/user/tag/posts/${post_id}`).then(res => {
            if (res.data.post && pendingPosts !== null) {
                const newArr = [...pendingPosts];
                const postIndex = newArr.findIndex(post => post.id == post_id);
                if (postIndex !== -1) newArr.splice(postIndex, 1);
                setPendingPosts(newArr);
                if (taggedPosts) {
                    set_number_of_tag_posts(prev => prev + 1);
                    setTaggedPosts(prev => [res.data.post, ...prev]);
                }
            }
        }).catch(error => {
            toast.warn('Post accept error!');
        });
    }

    const handleRemovePost = (post_id) => {
        axios.delete(`/user/tag/posts/${post_id}`).then(res => {
            if (res.data.removed && pendingPosts !== null) {
                const newArr = [...pendingPosts];
                const postIndex = newArr.findIndex(post => post.id == post_id);
                if (postIndex !== -1) newArr.splice(postIndex, 1);
                setPendingPosts(newArr);
            }
        }).catch(error => {
            toast.warn('Post remove error!');
        });
    }

    const getPendingPosts = () => {
        axios.get('/user/posts/pending').then(res => {
            setPendingPosts(res.data);
        }).catch(error => {
        });
    }


    useEffect(() => {
        if (props.user && props.profile && props.friends) {
            const friendExites = props.friends.find((fri) => fri.id == props.profile.id);
            if (friendExites) setAfri(true);
            else setAfri(false);
        }
    }, [props.friends]);

    useEffect(() => {
        if (props.user && props.profile && props.friends) {
            const friendExites = props.friends.find((fri) => fri.id == props.profile.id);
            if (friendExites) setAfri(true);
            else setAfri(false);
        }

        if (props.user && props.user.id && props.profile) {
            const already_friends = props.user && props.user.friends.find((fri) => fri.id == props.profile.id);
            if (already_friends) setAfri(true);
            else setAfri(false);
        }

        socket.on('recive-profile-post', (new_post) => {
            if (new_post.user.id == props.user.id) {
                props.user_posts && props.user_posts.unshift(new_post);
            }
        });

        if (props.profile && props.profile.id) {
            props.get_user_posts(props.profile.id);
        }

    }, []);

    useEffect(() => {
        if (props.user && props.user.id && props.profile) {
            set_number_of_tag_posts(props.profile.number_of_tag_posts);
            const already_friends = props.user && props.user.friends.find((fri) => fri.id == props.profile.id);
            if (already_friends) setAfri(true);
            else setAfri(false);
        }
        getPendingPosts();
    }, [props.profile, props.user]);

    useEffect(() => {
        props.user && props.user.id && setRedirect(true);
        if (props.user && props.user.id) {
            socket.emit('add-new-user', props.user.id);
        }
    }, [props.user]);

    const add_friend_request = () => {
        setLoadingBtn(true);
        if (props.user) {
            axios.post(`/user/request/${id}`, { from_username: props.user.username }).then(res => {
                setPendingUser(true);
                setLoadingBtn(false);
                if (res.data.notification) {
                    socket.emit('friend-request-notification', res.data.notification);
                }
            }).catch(error => {
                setLoadingBtn(false);
                toast.warn('Friend request not sent!');
            });
        }
    }

    const remove_friend_request = () => {
        setLoadingBtn(true);
        axios.delete(`/user/request/${id}`).then(res => {
            setPendingUser(false);
            setLoadingBtn(false);
        }).catch(error => {
            setLoadingBtn(false);
            toast.warn('Friend request not removed!');
        });
    }

    const remove_friend = (fri_id, user_id) => {
        setLoadingBtn(true);
        axios.delete(`/user/friends/${id}`).then(res => {
            setLoadingBtn(false);
            setAlreadyFriends(false);
        }).catch(error => {
            toast.warn('Friend is not removed!');
            setLoadingBtn(false);
        });
    }

    socket.on('friend-request-notifi', (data) => {
        props.get_notifications();
        setLoadingBtn(false);
        const ExitsNotifi = tempNotifi.find((no) => no.id == data.id);
        if (!data.success) toast.warn(data.message);
        else {
            if (props.friend_requests) {
                if (data.add) setPendingUser(true);
                else setPendingUser(false);
            }
        }
    });

    socket.on('recive-addfriend-notifi-friend', (data) => {
        setLoadingBtn(false);
        if (data.success) {
            if (data.type == 'add') {
                if (props.friends) {
                    props.set_friends([data.friend[0], ...props.friends]);
                }
                if (props.friend_requests) {
                    const friIndex = props.friend_requests.pending.find((fri) => fri.id == data.friend[0].id);
                    if (friIndex !== -1) {
                        props.friend_requests.pending.splice(friIndex, 1);
                        props.set_friend_requests(props.friend_requests);
                    }
                }
            }
        }
    });

    socket.on('recive-friend-notifi', (data) => {
        setLoadingBtn(false);
        if (props.friends) {
            const friArr = [...props.friends];
            const friIndex = friArr.findIndex((fri) => fri.id == data.fri_id);
            if (friIndex !== -1) {
                friArr.splice(friIndex, 1);
                props.set_friends([...friArr]);
            }
        }
    });

    useEffect(() => {

        socket.on('recive-tag-post-notifi', (data) => {
            if (data.success) {
                toast.success(data.message);
            }
            else toast.warn(data.message);
        });

        socket.on('recive-tag-post-accept', (data) => {
            if (props.profile && props.profile.id) {
                props.get_user_posts(props.profile.id);
            }
        });

        getPendingPosts();

        props.get_friend_requests();
        props.get_friends();

    }, []);

    useEffect(() => {
        if (tempNotifi.length > 0) {
            tempNotifi.map(no => {
                if (no.success) toast.success(no.message);
            })
        }
    }, [tempNotifi]);


    useEffect(() => {

        get_posts();

        const onScroll = (event) => {
            const { scrollHeight, scrollTop } = document.documentElement;
            if (!postsLoading && !noMore && (postType === 'my') && (window.innerHeight + scrollTop + 1 >= scrollHeight)) {
                setCurrentPage(prev => prev + 1);
                setCurrentTagPage(prev => prev + 1);
            }
        }

        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);

    }, []);


    return (
        <IsLoggedIn>
            <div className="profile">
                <CreatePost open={createOpen} setOpen={setCreateOpen} handleOpen={() => setCreateOpen(!createOpen)} />
                <div onClick={go_back} className="back-btn">
                    <img src={BackBtn} alt="back" />
                </div>
                {!props.user && props.user.id ? <Navigate to='/' /> : (
                    <>
                        {props.profile !== null ? (
                            <>
                                {props.profile.name ? (
                                    <>
                                        {props.user && (props.user.id == props.profile.id) && (
                                            <EditeProfile user={props.user && props.user} open={open} handleOpen={handleOpen} />
                                        )}
                                        {openfri && (
                                            <AllFriends handleOpen={handleOpenfri} />
                                        )}
                                        <div className="cover-image" style={{ backgroundImage: `url(${coverImage})` }}>
                                            {redirectToChat && <Navigate to='/chat' />}
                                            <div className="overlay">
                                                {loadingCover ? (
                                                    <div className="center3">
                                                        <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                                                    </div>
                                                ) : (
                                                    <>
                                                        {(props.user && props.user.id == props.profile.id) && (
                                                            <>
                                                                <input onChange={(e) => handleCoverImage(e.target.files[0])} type="file" id="cover-img" hidden />
                                                                <label htmlFor="cover-img">
                                                                    <img src={PencilIcon} alt="edite" />
                                                                </label>
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="user">
                                            <div className="sec1">
                                                <div className="profile-image" style={{ backgroundImage: `url(${props.profile && profileimage ? profileimage : filesUrl + '/static/user/default.png'})` }}>
                                                    {loadingImage ? (
                                                        <div className="center3">
                                                            <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {(props.user && props.user.id == props.profile.id) && (
                                                                <>
                                                                    <input onChange={(e) => handleProfileImage(e.target.files[0])} hidden type="file" id="upload-user-image" />
                                                                    <label htmlFor="upload-user-image" className="overlay2">
                                                                        <img src={PencilLightIcon} alt="edite" />
                                                                    </label>
                                                                </>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                                <section>
                                                    <h3>{props.profile.name}</h3>
                                                    <h5>@{props.profile.username}</h5>
                                                </section>
                                            </div>
                                            <div className="btns">
                                                {props.user && props.user.id && !props.user.active ? (
                                                    <>
                                                        {props.user.id == props.profile.id && (
                                                            <h4 style={{ color: '#cc3300', fontSize: '0.8rem', display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center', flexWrap: 'wrap' }}><WarnIcon style={{ fill: '#cc3300', width: '25px', height: 'fit-content' }} />&nbsp; Your account has been temporarily blocked</h4>
                                                        )}
                                                    </>
                                                ) : (
                                                    <>
                                                        {loadinBtn ? (
                                                            <button className="btn-primary">
                                                                <SpinnerCircularFixed size={20} thickness={200} style={{ cursor: 'default' }} speed={100} color="rgba(2, 69, 245, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
                                                            </button>
                                                        ) : (
                                                            <>
                                                                {(id == props.user.id) ? (
                                                                    <>
                                                                        <img onClick={handleOpen} src={SettingsIcon} alt="setting" />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        {pendingUser ? (
                                                                            <button className="btn-gray" onClick={() => {
                                                                                remove_friend_request();
                                                                            }}>cancel request</button>
                                                                        ) : (
                                                                            <>
                                                                                {recivedRequest ? (
                                                                                    <button onClick={() => navigate('/requests')} className="btn-gray">Accept request</button>
                                                                                ) : (
                                                                                    <>
                                                                                        {alreadyFriends ? (
                                                                                            <>
                                                                                                <button className="btn-primary" onClick={() => {
                                                                                                    handleMessageUrl();
                                                                                                }}>Message</button>
                                                                                                <button className="btn-gray" style={{ marginLeft: '10px' }} onClick={() => {
                                                                                                    remove_friend(props.profile.id, props.user.id);
                                                                                                }}>Un Friend</button>
                                                                                            </>
                                                                                        ) : (
                                                                                            <button className="btn-primary" onClick={() => {
                                                                                                add_friend_request();
                                                                                            }}>
                                                                                                Add friend
                                                                                            </button>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="user-about">
                                            <div className="user-bio">
                                                <div className="bio">
                                                    <div className="about">
                                                        <p>
                                                            <strong>About: &nbsp;</strong>
                                                            {props.profile.about ? props.profile.about : 'No about'}
                                                        </p>
                                                    </div>
                                                    <h4>Hobbies</h4>
                                                    <div className="hobbies">
                                                        {props.profile && props.profile.hobbies && props.profile.hobbies.length > 0 && (
                                                            <>
                                                                {props.profile.hobbies.split(',').map((hob, key) => (
                                                                    <h5 key={key}>{hob}</h5>
                                                                ))}
                                                            </>
                                                        )}
                                                    </div>
                                                    <div className="more">
                                                        <p>
                                                            <img src={EducationIcon} alt="education" />
                                                            <div className="section">
                                                                <strong>Education: </strong>
                                                                {props.profile.education ? props.profile.education : 'No education details'}
                                                            </div>
                                                        </p>
                                                        <p>
                                                            <img src={JobsIcon} alt="jobs" />
                                                            <div className="section">
                                                                <strong>Jobs: </strong>
                                                                {props.profile.job ? props.profile.job : 'No job details'}
                                                            </div>
                                                        </p>
                                                        <p>
                                                            <img src={LocationIcon} alt="location" />
                                                            <div className="section">
                                                                <strong>Location: </strong>
                                                                {props.profile.location ? props.profile.location : 'No location details'}
                                                            </div>
                                                        </p>
                                                    </div>
                                                </div>
                                                {props.profile.activities.length > 0 && (
                                                    <>
                                                        <h4 style={{ marginLeft: '10px' }}>Recent Activities</h4>
                                                        <div className="activities">
                                                            <ul>
                                                                {props.profile.activities.map((activity, key) => (
                                                                    <Link key={key} style={{ textDecorationLine: 'none', color: '#444' }} to={activity.url ? activity.url : '#'}>
                                                                        <li>
                                                                            <div className="img" style={{ backgroundImage: `url(${props.profile.image ? props.profile.image : filesUrl + '/static/user/default.png'})` }}></div>
                                                                            <p>
                                                                                <strong>{props.profile.username}</strong>
                                                                                {activity.activity}
                                                                            </p>
                                                                        </li>
                                                                    </Link>
                                                                ))}
                                                            </ul>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <div className="profile_user_posts" style={{ width: '100%', maxWidth: '600px' }}>
                                                {props.profile.id == props.user.id && (
                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                        <AcceptPosts posts={pendingPosts} handleAcceptPost={handleAcceptPost} handleRemovePost={handleRemovePost} />
                                                    </div>
                                                )}
                                                <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start', marginTop: '25px' }}>
                                                    <div className="profile-select-posts-type">
                                                        {number_of_tag_posts > 0 ? (
                                                            <>
                                                                <button onClick={() => {
                                                                    setPostType('my');
                                                                    setTotalPages(0);
                                                                    setLoading(true);
                                                                    setPostsLoading(true);
                                                                    setPosts([]);
                                                                    setCurrentPage(0);
                                                                    setCurrentTagPage(0);
                                                                    get_posts();
                                                                }} style={{ backgroundColor: postType == 'my' ? 'rgba(0,0,0,0.1)' : '#fff' }}>{props.user.id == props.profile.id ? 'My Posts' : `${props.profile.username.length > 9 ? props.profile.username.substr(0, 9) + '...' : props.profile.username}'s posts`}</button>
                                                                <button onClick={() => {
                                                                    setPostType('other');
                                                                    setTotalPages(0);
                                                                    setPosts([]);
                                                                    setLoading(true);
                                                                    setPostsLoading(true);
                                                                    setCurrentPage(0);
                                                                    setCurrentTagPage(0);
                                                                    get_tagged_posts();
                                                                }} style={{ backgroundColor: postType == 'other' ? 'rgba(0,0,0,0.1)' : '#fff' }}>Other's Posts</button>
                                                            </>
                                                        ) : ''}
                                                    </div>
                                                </div>
                                                {loading ? (
                                                    <PostsLoading />
                                                ) : (
                                                    <>
                                                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: `${props.profile.number_of_tag_posts > 0 ? '-15px' : '-35px'}` }}>
                                                            {postType == 'my' ? (
                                                                <Posts profile={true} posts={posts} />
                                                            ) : (
                                                                <Posts profile={true} posts={taggedPosts} />
                                                            )}
                                                        </div>
                                                        {postsLoading && (
                                                            <div style={{ marginTop: '15px', marginBottom: '15px', width: '100%', display: 'flex', justifyContent: 'center' }}>
                                                                <Spinner color1="#1b3af7" color2="#1b3af7" className="spinner-profile" textColor='rgba(0,0,0,0)' />
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <ProfileLoading />
                                )}
                            </>
                        ) : (
                            <NotFound />
                        )}
                    </>
                )}
            </div>
        </IsLoggedIn>
    )
}

const mapStateToprops = (state) => ({
    profile: state.profile.data,
    user_posts: state.user_posts.data,
    user: state.user.data,
    success: state.success.data,
    friends: state.friends.data,
    friend_requests: state.friend_requests.data
});

const mapDispatchToProps = (dispatch) => ({
    get_profile: (username) => dispatch(get_profile(username)),
    get_user_posts: (id) => dispatch(get_user_posts(id)),
    change_image: (image) => dispatch(change_user_image(image)),
    create_chat: (id) => dispatch(create_chat(id)),
    update_cover: (image) => dispatch(update_cover(image)),
    get_user: () => dispatch(get_user()),
    get_friend_requests: () => dispatch(get_friend_requests()),
    set_friend_requests: (data) => dispatch(set_friend_requests_func(data)),
    get_notifications: () => dispatch(get_notifications()),
    set_friends: (data) => dispatch(set_friends_func(data)),
    get_friends: () => dispatch(get_friends()),
    set_profile_posts: (data) => dispatch(set_user_posts_func(data))
});

export default connect(mapStateToprops, mapDispatchToProps)(Profile);