import React from "react";
import ProfileImage from '../assets/images/profile.jpg';
import CloseIcon from '../assets/images/plus.svg';
import { Link } from "react-router-dom";

const AllFriends = (props) => {
    return (
        <div className="friends-area">
            <div className="overlay">
                <div className="modal">
                    <div className="head">
                        <div className="head-user">
                            <div className="img" style={{ backgroundImage: `url(${ProfileImage})` }}></div>
                            <h5>Friends</h5>
                        </div>
                        <section>
                            <img onClick={props.handleOpen} src={CloseIcon} alt="close" />
                        </section>
                    </div>
                    <div className="find">
                        <input type="text" placeholder="search friends" />
                        <div className="body">
                            <ul>
                                {[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39].map((_, index) => (
                                    <Link style={{ color: '#000', textDecorationLine:'none' }} key={index} to="/user/username">
                                        <li>
                                            <div style={{ backgroundImage: `url(${ProfileImage})` }}></div>
                                            <h5>@adithya</h5>
                                        </li>
                                    </Link>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AllFriends;