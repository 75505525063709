import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
// import { get_groups } from "../store/actions/group";
import { connect } from "react-redux";
import { ReactComponent as AngleIcon } from '../assets/images/angle-down.svg';
import GroupSideLoading from "./Loading/GroupSideLoading";
import axios from "axios";
import { filesUrl } from "../App";

const Sidebar = (props) => {

    const [myGroups, setMyGroups] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [currentGroupPage, setCurrentGroupPage] = useState(0);
    const [numberOfGroups, setNumberOfGroups] = useState(0);
    const [numberOfmyGroups, setNumberOfmyGroups] = useState(0);
    const [groups, setGroups] = useState(null);

    const styles = {
        textDecorationLine: 'none',
        color: '#000',
        width: '100%'
    }

    const get_my_groups = () => {
        axios.get(`/group/mygroups?page=${currentPage}`).then(res => {
            setCurrentPage(prev => prev + 10);
            setNumberOfmyGroups(res.data.number_of_groups);
            if (myGroups) setMyGroups([...myGroups, ...res.data.groups]);
            else setMyGroups([...res.data.groups]);
        });
    }

    const get_groups = () => {
        axios.get(`/group?page=${currentGroupPage}`).then(res => {
            setCurrentGroupPage(prev => prev + 10);
            setNumberOfGroups(res.data.number_of_groups);
            if (groups) setGroups([...groups, ...res.data.results])
            else setGroups([...res.data.results])
        });
    }

    useEffect(() => {
        get_my_groups();
        get_groups();
    }, []);

    return (
        <div className="sidebar">
            <div className="nav-profile-image">
                <div className="img" style={{ backgroundImage: `url(${props.user && props.user.id ? (props.user.image ? props.user.image : filesUrl + '/static/user/default.png') : filesUrl + '/static/user/default.png'})` }}></div>
                <h3 style={{ color: '#0245f5' }}>{props.user && props.user.name ? props.user.name : 'Guest'}</h3>
            </div>

            <div className="groups">
                <ul>
                    <a href="https://happyfacess.com" target="_blank" style={styles}>
                        <li style={{ padding: '15px 0px' }}>
                            <h6 style={{ color: '#0245f5' }}>Connect With Our Volunteers</h6>
                        </li>
                    </a>

                    <a href="https://companion.happyfacess.com" target="_blank" style={styles}>
                        <li style={{ padding: '15px 0px' }}>
                            <h6 style={{ color: '#0245f5' }}>Companion Services</h6>
                        </li>
                    </a>
                </ul>
            </div>

            {props.user && props.user.id ? (
                <>
                    {groups && props.user.my_groups_length > 0 ? (
                        <div className="groups padding-bottom-80">
                            {props.user && props.user.id && (
                                <>
                                    {groups && groups.length > 0 && (
                                        <Link style={{ textDecorationLine: 'none' }} to='/explore'>
                                            <h5>Groups</h5>
                                        </Link>
                                    )}
                                    {props.user && props.user.id && groups && (
                                        <>
                                            {myGroups !== null ? (
                                                <>
                                                    {myGroups.length > 0 && (
                                                        <ul>
                                                            {myGroups && myGroups.map(grou => (
                                                                <Link key={grou.id} style={styles} to={`/groups/${grou.id}`}>
                                                                    <li>
                                                                        <div className="img" style={{ backgroundImage: `url(${grou.image})` }}></div>
                                                                        <h6>{grou.name.length > 18 ? `${grou.name.substr(0, 18)}...` : grou.name}</h6>
                                                                    </li>
                                                                </Link>
                                                            ))}
                                                            {numberOfmyGroups > myGroups.length && (
                                                                <li onClick={get_my_groups} className="svg-div-size-20">
                                                                    <AngleIcon style={{ width: '20px', fill: '#777', height: '20px', marginLeft: '25px', paddingTop: '8px', paddingBottom: '8px', marginRight: '5px' }} />
                                                                    <h6>see more</h6>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    )}
                                                </>
                                            ) : (
                                                <GroupSideLoading />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    ) : (
                        <div className="groups padding-bottom-80">
                            {props.user && props.user.id && (
                                <>
                                    {groups && groups.length > 0 && (
                                        <Link style={{ textDecorationLine: 'none' }} to=''>
                                            <h5>Groups</h5>
                                        </Link>
                                    )}
                                    {props.user && props.user.id && groups && (
                                        <>
                                            {groups !== null ? (
                                                <>
                                                    {groups.length > 0 && (
                                                        <ul>
                                                            {props.user && groups && groups.map(grou => (
                                                                <Link key={grou.id} style={styles} to={`/groups/${grou.id}`}>
                                                                    <li>
                                                                        <div className="img" style={{ backgroundImage: `url(${grou.image})` }}></div>
                                                                        <h6>{grou.name.length > 15 ? `${grou.name.substr(0, 15)}...` : grou.name}</h6>
                                                                    </li>
                                                                </Link>
                                                            ))}
                                                            {numberOfGroups > groups.length && numberOfGroups !== groups.length && (
                                                                <li onClick={get_groups} className="svg-div-size-20">
                                                                    <AngleIcon style={{ width: '20px', fill: '#777', height: '20px', marginLeft: '25px', paddingTop: '8px', paddingBottom: '8px', marginRight: '5px' }} />
                                                                    <h6>see more</h6>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    )}
                                                </>
                                            ) : (
                                                <GroupSideLoading />
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    )}
                </>
            ) : (
                <div style={{ paddingBottom: '80px' }} className="groups">
                    <>
                        {groups && groups.length > 0 && (
                            <Link style={{ textDecorationLine: 'none' }} to='/groups'>
                                <h5>Groups</h5>
                            </Link>
                        )}
                        {groups && (
                            <>
                                {groups !== null ? (
                                    <>
                                        {groups.length > 0 && (
                                            <ul>
                                                {props.user && groups && groups.map(grou => (
                                                    <Link key={grou.id} style={styles} to={`/groups/${grou.id}`}>
                                                        <li>
                                                            <div className="img" style={{ backgroundImage: `url(${grou.image})` }}></div>
                                                            <h6>{grou.name.length > 15 ? `${grou.name.substr(0, 15)}...` : grou.name}</h6>
                                                        </li>
                                                    </Link>
                                                ))}
                                                {numberOfGroups > groups.length && (
                                                    <li onClick={get_groups} className="svg-div-size-20">
                                                        <AngleIcon style={{ width: '20px', fill: '#777', height: '20px', marginLeft: '25px', paddingTop: '8px', paddingBottom: '8px', marginRight: '5px' }} />
                                                        <h6>see more</h6>
                                                    </li>
                                                )}
                                            </ul>
                                        )}
                                    </>
                                ) : (
                                    <GroupSideLoading />
                                )}
                            </>
                        )}
                    </>
                </div>
            )}
        </div>
    )
}



const mapStateToProps = (state) => ({
    groups: state.groups.data,
    user: state.user.data
});

export default connect(mapStateToProps)(Sidebar);