import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const GroupLoading = () => {
    return (
        <div className="group-loading-view">
            <SkeletonTheme baseColor="#edebeb" highlightColor="#e0dede">
                <div className="group-image">
                    <Skeleton style={{ width: '100vw', maxWidth: '900px', height: '300px' }} />
                    <section style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div>
                            <Skeleton style={{ width: '150px', marginTop: '20px' }} />
                            <Skeleton style={{ width: '100px' }} />
                        </div>
                        <Skeleton style={{ width: '85px', height: '30px' }} />
                    </section>
                </div>
            </SkeletonTheme>
        </div>
    )
}

export default GroupLoading;