import axios from "axios";
import { toast } from "react-toastify";
import { filesUrl, socket } from "../../App";
import { upload_image } from "../../assets/utils/upload";
import { get_group_posts } from "./group";
import { set_notification } from "./notification";
import { SET_POST, SET_POSTS, SET_TAGS, SET_TAG_POSTS } from "./types";

export const set_posts = (posts) => ({
    type: SET_POSTS,
    data: posts
});

export const set_tags = (data) => ({
    type: SET_TAGS,
    data: data
});

export const set_posts_func = (posts) => (dispatch) => {
    dispatch(set_posts(posts));
}

let allposts = [];

export const set_post = (post) => ({
    type: SET_POST,
    data: post
});

export const set_post_func = (data) => (dispatch) => {
    dispatch(set_post(data));
}

export const get_posts = (id) => (dispatch) => {
    axios.get(`/posts?id=${id}`).then(res => {
        dispatch(set_posts([...res.data.posts]));
    });
}

export const get_new_post = () => (dispatch) => {
}

// const upload_img = async (image, token) => {
//     return new Promise(async (resolve) => {
//         if (image) {
//             const uploadImage = await upload_image(image, 'posts');
//             image = uploadImage;
//             resolve(image);
//             // const formdata = new FormData();
//             // formdata.append('image', image);
//             // formdata.append('token', token);
//             // formdata.append('name', image.name);
//             // fetch(`${filesUrl}/post`, { method: 'POST', body: formdata }).then(async (res) => {
//             //     const response = await res.json();
//             //     resolve(response.image);
//             // }).catch(error => {
//             // });
//         } else {
//             resolve(null);
//         }
//     })
// }

export const create_post = (data) => (dispatch) => {
    dispatch(set_posts([...data]));
}

export const create_post_func = async (data, posts) => {
    return new Promise(async (resolve) => {
        if (data.image) {
            upload_image(data.image, 'post', data.token).then(response => {
                data.image = response;
                axios.post('/posts', data).then(res => {
                    socket.emit('send-post', res.data.post);
                    resolve(res.data);
                }).catch(_error => {
                    toast.warn('servers are busy!');
                    resolve(true);
                });
            }).catch(_error => {
                toast.warn('servres are busy!');
                resolve(true);
            });
        } else {
            data.image = null;
            axios.post('/posts', data).then(res => {
                socket.emit('send-post', res.data.post);
                resolve(res.data);
            }).catch(_error => {
                toast.warn('servers are busy!');
                resolve(true);
            });
        }
    });
}

export const get_tags = () => (dispatch) => {
    axios.get('/posts/tags').then(res => {
        dispatch(set_tags(res.data.tags));
    });
}

export const get_post = (id, user_id) => (dispatch) => {
    axios.get(`/posts/${id}?id=${user_id}&page=${0}`).then(res => {
        if (res.data.success) dispatch(set_post(res.data.post));
        else dispatch(set_post({}));
    })
        .catch(error => {
            dispatch(set_post({}));
        });
}

export const delete_post = (data, posts) => (dispatch) => {
    axios.delete(`/posts/${data.postId}`).then(res => {
        dispatch(set_notification({ message: 'Post deleted!', success: true }));
        // dispatch(get_posts());
        if (data.groupId) dispatch(get_group_posts(data.groupId));
    }).catch(error => {
        dispatch(set_notification({ message: 'Post deleted error! Try again', success: false }));
    });
}

export const add_comment = (data) => (dispatch) => {
    axios.post(`/posts/comment/${data.post_id}`, { comment: data.comment, public: data.public }).then(res => {
        dispatch(get_posts())
        dispatch(get_post(data.post_id))
    })
        .catch(error => {
        });
}

export const remove_comment = (data) => (dispatch) => {
    axios.delete(`/posts/comment/${data.commentId}`).then(res => {
    })
        .catch(error => {
        });
}

export const add_remove_support = (id) => (dispatch) => {
    axios.put(`/posts/support/${id}`).then(res => {
    });
}

export const add_reply = (data) => (dispatch) => {
    axios.post(`/posts/reply/${data.id}`, { reply: data.reply }).then(res => {
        // dispatch(get_posts())
        // dispatch(get_post(data.post_id));
    }).catch(error => {
    });
}

export const remove_reply = ({ id }) => (dispatch) => {
    axios.delete(`/posts/reply/${id}`).then(res => {
        // dispatch(get_posts())
        // dispatch(get_post(post_id));
    }).catch(error => {
    })
}

export const tag_filter = (tag) => (dispatch) => {
    axios.get(`/posts/tags/${tag}`).then(res => {
        dispatch({ type: SET_TAG_POSTS, data: res.data.posts })
    }).catch(error => {
        toast.warn('Please try again with correct data');
    })
}

export const set_replies = (replies) => (dispatch) => {

}