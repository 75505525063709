import axios from "axios";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "loading-animations-react";

const AddUsername = () => {

    const [username, setUsername] = useState('');
    const [loading, setLoading] = useState(false);
    const [exits, setExits] = useState(true);

    const checkUsername = () => {
        setLoading(true);
        axios.get(`/user/username/${username}`).then(res => {
            if (res.data.username) setExits(true);
            else setExits(false);
            setLoading(false);
        })
    }

    const handleAddUsername = (event) => {
        event.preventDefault();
        if (username.length > 0) {
            axios.put('/user/username', { username: (username.replace(/\s/g, "")).toLowerCase() }).then(res => {
                window.location.reload();
            }).catch(_error => {
                toast.warn('Your details updating error!');
            });
        }
    }

    useEffect(() => {
        if (username.length > 0 && !loading) checkUsername();
    }, [username]);

    return (
        <div className="add-username-area">
            <form onSubmit={handleAddUsername}>
                <h4 style={{ color: '#777', fontSize: '0.9rem' }}>Add username</h4>
                <section>
                    <input style={{ width: 'calc(100% - 20px)' }} type="text" onChange={(e) => setUsername(e.target.value)} placeholder="enter username" />
                </section>
                <p style={{ fontSize: '0.7rem', fontWeight: '400', color: 'rgba(0,0,0,0.9)', marginTop: '10px', color: 'blue' }}>Don't use capital letters and space between letters</p>
                <span style={{ fontSize: '0.8rem', color: 'rgba(0,0,0,0.9)', marginTop: '10px', textAlign: 'center', fontWeight: '400' }}>Choose your username wisely! Once set, it cannot be changed, so make sure it represents you well</span>
                <section>
                    {username.length > 0 && (
                        <>
                            {loading ? (
                                <button style={{ display: 'flex', justifyContent: 'center', backgroundColor: '#1c68ff' }} onClick={handleAddUsername} type="submit">
                                    <Spinner color1="#fff" color2="#fff" className="spinner-username" textColor='rgba(0,0,0,0)' />
                                </button>
                            ) : (
                                <>
                                    {exits ? (
                                        <button style={{ cursor: 'default', backgroundColor: 'rgba(0,0,0,0.3)' }} disabled>username already taken</button>
                                    ) : (
                                        <>
                                            {username.length > 18 ? (
                                                <button style={{ cursor: 'default', backgroundColor: 'rgba(0,0,0,0.3)' }} disabled>The username is too long</button>
                                            ) : (
                                                <button onClick={handleAddUsername} style={{ backgroundColor: '#1c68ff' }} type="submit">set username</button>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </>
                    )}
                </section>
            </form>
        </div>
    )
}

export default AddUsername;