import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import BackBtn from '../assets/images/angle-left.svg';
import { connect } from "react-redux";
import { socket } from "../App";
import { get_user, get_friend_requests, set_friends_func, get_friends, set_friend_requests_func } from "../store/actions/users";
import { toast } from "react-toastify";
import { ReactComponent as PeopleIcon } from '../assets/images/people.svg';
import { ReactComponent as PlusIcon } from '../assets/images/plus.svg';
import { SpinnerCircularFixed } from "spinners-react";
import FriendsLoading from "./Loading/FriendsLoading";
import axios from "axios";
import IsLoggedIn from "./IsLoggedIn";
import { filesUrl } from "../App";

const Requests = (props) => {

    const navigate = useNavigate();
    const [accepted, setAccepted] = useState([]);
    const [removed, setRemoved] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [requests, setRequests] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfRequests, setNumberOfRequests] = useState(0);

    const go_back = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }

    const get_friend_requests = () => {
        axios.get(`/user/requests?page=${currentPage}`).then(res => {
            setCurrentPage(prev => prev + 20);
            setNumberOfRequests(res.data.number_of_friends_requests);
            if (requests) setRequests([...requests, ...res.data.requests.recived]);
            else setRequests([...res.data.requests.recived]);
        });
    }

    const acceptRequest = (fri_id, fri_name) => {
        axios.post(`/user/friends/${fri_id}`, { from_username: props.user.username, name: fri_name, username: props.user.username }).then(res => {
            setAccepted([...accepted, fri_id]);
            if (res.data.notification) socket.emit('friend-request-accept-send', res.data.notification);
        }).catch(_error => {
            toast.warn('Request accept error!');
        });
    }

    const remove_friend_request = (fri_id) => {
        axios.delete(`/user/friend/request/${fri_id}`).then(_res => {
            setRemoved([...removed, fri_id]);
        }).catch(_error => {
            toast.warn('Friend request is not removed');
        });
    }

    socket.on('friend-added', (data) => {
        setBtnLoading(false);
        if (data.success) {
            if (data.type == 'add') props.set_friends([...props.friends, data.friend[0]]);
            else {
                const newFriendArr = [...props.friends];
                const friendExits = newFriendArr.findIndex((fri) => fri.id == data.id);
                if (friendExits !== -1) {
                    newFriendArr.splice(friendExits, 1);
                    props.set_friends([...newFriendArr]);
                }
            }
        }
    });

    socket.on('recive-friend-notifi', (data) => {
        if (!data.success) toast.warn(data.message);
    });


    socket.on('recive-friend-request', (data) => {
        props.get_friend_requests();
        props.set_friend_requests([...props.friend_requests, data]);
    });

    useEffect(() => {
        get_friend_requests();
    }, []);

    return (
        <IsLoggedIn>
            <div className="requests-area">
                {props.user !== null ? (
                    <>
                        {(props.user && props.user.id) ? (
                            <>
                                <div onClick={go_back} className="back-btn">
                                    <img src={BackBtn} alt="back" />
                                </div>
                                {requests !== null ? (
                                    <>
                                        {requests.length > 0 ? (
                                            <div className="requests">
                                                <h4>Friend request</h4>
                                                <div style={{ marginTop: '20px' }} className="users">
                                                    <ul>
                                                        {requests.map((user, key) => (
                                                            <li key={key}>
                                                                <section>
                                                                    <div className="img" style={{ backgroundImage: `url(${user.image ? user.image : filesUrl + '/static/user/default.png'})` }}>
                                                                        <div className="overlay"></div>
                                                                    </div>
                                                                    <h5 style={{ width: '100%', textAlign: 'center', marginLeft: '0px' }}>{user.username}</h5>
                                                                </section>
                                                                <div className="bottom">
                                                                    <div className="btns">
                                                                        {btnLoading ? (
                                                                            <button className="btn-primary">
                                                                                <SpinnerCircularFixed size={20} thickness={200} style={{ cursor: 'default' }} speed={100} color="rgba(2, 69, 245, 1)" secondaryColor="rgba(0, 0, 0, 0.44)" />
                                                                            </button>
                                                                        ) : (
                                                                            <>
                                                                                {accepted.some(fri => fri == user.id) ? (
                                                                                    <button>Accepted</button>
                                                                                ) : (
                                                                                    <>
                                                                                        {removed.some(fri => fri == user.id) ? (
                                                                                            <button>Removed</button>
                                                                                        ) : (
                                                                                            <>
                                                                                                <button onClick={() => acceptRequest(user.id, user.username)}>
                                                                                                    Accept
                                                                                                </button>
                                                                                                <button onClick={() => remove_friend_request(user.id)}>
                                                                                                    Remove
                                                                                                </button>
                                                                                            </>
                                                                                        )}
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        ))}
                                                        {numberOfRequests > requests.length && (
                                                            <li style={{ height: 'calc(100% - 20px)', border: '2px solid rgba(0,0,0,0.2)', padding: '10px 0px', justifyContent: 'center' }}>
                                                                <div onClick={() => get_friend_requests()} style={{ width: '50px', height: '50px', borderRadius: '50%', cursor: 'pointer', backgroundColor: 'rgba(0,0,0,0.1)', border: '1px solid rgba(0,0,0,0.2)', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                                    <PlusIcon style={{ width: '30px', height: '30px' }} />
                                                                </div>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </div>
                                        ) : (
                                            <div className="center-requests">
                                                <PeopleIcon />
                                                <h5>No friend requests</h5>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <FriendsLoading />
                                )}
                            </>
                        ) : (
                            <Navigate to="/" />
                        )}
                    </>
                ) : (
                    <h5>Loading...</h5>
                )}
            </div>
        </IsLoggedIn>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    friend_requests: state.friend_requests.data,
    friends: state.friends.data
});

const mapDispatchToProps = (dispatch) => ({
    get_user: () => dispatch(get_user()),
    set_friends: (data) => dispatch(set_friends_func(data)),
    set_friend_requests: (data) => dispatch(set_friend_requests_func(data)),
    get_friends: () => dispatch(get_friends()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Requests);