import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import FriendSideLoading from "./Loading/FriendSideLoading";
import { ReactComponent as AngleIcon } from '../assets/images/angle-down.svg';
import { filesUrl } from "../App";

const Desc = (props) => {

    const navigate = useNavigate();

    const line_none = {
        textDecorationLine: 'none',
        color: 'black',
        display: 'flex',
        alignItems: 'center'
    }

    return (
        <div className="desc">
            <div className="scroll">
                <section>
                    <div style={{ marginTop: '1px' }} className="con">
                        <div className="top">
                            <h3>Do you wish to express your emotions, feelings, opinions, views, or moral and ethical dilemmas? <br />
                                You have arrived at the correct platform</h3>
                            <p>Vent Anonymously <br />
                                Connect with like minded people <br />
                                Join vibrant support groups <br />
                                Make new friends</p>
                            <Link style={{ width: 'fit-content' }} to='/explore'>
                                <button># Explore</button>
                            </Link>
                            <div className="line"></div>
                        </div>
                        {props.user && props.user.id && (
                            <>
                                <h3 style={{ marginTop: '15px' }}>Friends</h3>
                                <div className="bottom">
                                    {props.user && props.user.id && props.friends !== null ? (
                                        <>
                                            {props.user && props.user.id && props.friends ? (
                                                <>
                                                    {props.friends.length > 0 ? (
                                                        <ul>
                                                            {props.friends.map((fri, key) => (
                                                                <li style={{ paddingLeft: '10px' }} key={key}>
                                                                    <Link style={line_none} to={`/user/${fri.id}`}>
                                                                        <div className="img" style={{ backgroundImage: `url(${fri.image ? fri.image : filesUrl + '/static/user/default.png'})` }}></div>
                                                                        <h6>{fri.username}</h6>
                                                                    </Link>
                                                                </li>
                                                            ))}
                                                            {props.friends.length > 20 && (
                                                                <li onClick={() => navigate('/friends')} style={{ backgroundColor: 'rgba(0,0,0,0.1)', paddingLeft: '10px' }}>
                                                                    <div style={{ width: '35px', height: '35px', borderRadius: '50%', backgroundColor: 'rgba(0,0,0,0.1)', border: '1px solid rgba(0,0,0,0.2)', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                        <AngleIcon style={{ width: '15px', fill: '#777', height: '15px' }} />
                                                                    </div>
                                                                    <h5 style={{ marginLeft: '10px', fontWeight: '300' }}>see more</h5>
                                                                </li>
                                                            )}
                                                        </ul>
                                                    ) : (
                                                        <FriendSideLoading enableAnimation={false} />
                                                    )}
                                                    {/* <h6 style={{ color: '#444', fontWeight: '500' }}>You haven't added any friends yet</h6> */}
                                                </>
                                            ) : (
                                                <h4>Please login to see this option</h4>
                                            )}
                                        </>
                                    ) : (
                                        <FriendSideLoading enableAnimation={true} />
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </section>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data,
    friends: state.friends.data
})

export default connect(mapStateToProps)(Desc);