import React from "react";
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const PostViewLoading = () => {
    return (
        <SkeletonTheme baseColor="#edebeb" highlightColor="#e0dede">
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} className="post-loading">
                <section style={{ width: '100%', maxWidth: '700px' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }} className="head">
                            <Skeleton count={1} circle style={{ width: '50px', height: '50px' }} />
                            <div>
                                <Skeleton count={1} style={{ width: '150px', marginLeft: '10px' }} />
                                <Skeleton count={1} style={{ width: '100px', marginLeft: '10px' }} />
                            </div>
                        </div>
                    </div>
                    <div className="description" style={{ marginTop: '15px' }}>
                        <Skeleton count={2} style={{ width: '100%', maxWidth: '600px' }} />
                        <Skeleton count={1} style={{ width: '100%', maxWidth: '200px' }} />
                    </div>
                    <div className="img" style={{ marginTop: '15px' }}>
                        <Skeleton count={1} style={{ width: '100%', height: '300px' }} />
                    </div>
                    <div className="comment">
                        <Skeleton count={1} style={{ width: '100%', height: '35px' }} />
                    </div>
                </section>
            </div>
        </SkeletonTheme>
    )
}

export default PostViewLoading;