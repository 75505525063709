import axios from "axios";
import { SET_CHATS, SET_MESSAGES, SET_MUTED_CHATS } from "./types";
import { io } from "socket.io-client";
import { toast } from "react-toastify";

const set_chats = (data) => ({
    type: SET_CHATS,
    chats: data
});

export const get_chats = () => (dispatch) => {
    axios.get(`/chat`).then(res => {
        dispatch(set_chats(res.data.chats));
    }).catch(error => {
    });
}

export const get_chat = (id) => (dispatch) => {
    axios.get(`/chat/${id}`).then(res => {
        dispatch({ type: SET_MESSAGES, data: { messages: res.data.chat, number_of_messages: res.data.number_of_messages } });
    }).catch(error => {
    });
}

export const create_chat = (id) => (dispatch) => {
    axios.post(`/chat/${id}`).then(res => {
        dispatch(get_chats());
    }).catch(error => {
        toast.warn("This chat was created already")
    });
}

export const create_message = (data) => (dispatch) => {
    axios.post(`/chat/message/${data.chat_id}`, data).then(res => {
    }).catch(error => {
        toast.warn('Message not delivered')
    });
}

const set_muted_chat = (data) => ({
    type: SET_MUTED_CHATS,
    data: data
});

export const set_muted_chat_func = (data) => (dispatch) => {
    dispatch(set_muted_chat(data));
}

export const get_muted_chats = () => (dispatch) => {
    axios.get('/user/muted/chats').then(res => {
        dispatch(set_muted_chat(res.data.chats));
    });
}

export const connect_socket = () => {
    io('http://localhost:8000');
}
