import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/styles/index.css';
import App from './App';
import { Provider } from "react-redux";
import store from './store';
import Logo from './assets/images/logo.png';
import { GoogleOAuthProvider } from '@react-oauth/google';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <GoogleOAuthProvider clientId='602585278095-ta43sb3ksacrv74tkr896l5isv1rvd41.apps.googleusercontent.com'>
            <App />
        </GoogleOAuthProvider>
    </Provider>
);
