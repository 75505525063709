import axios from "axios";
import React, { useState } from "react";
import { connect } from "react-redux";
import { Flip, toast, ToastContainer } from "react-toastify";
import CloseIcon from '../assets/images/plus.svg';
import { report_to_admin } from "../store/actions/users";

const ReportUser = (props) => {

    const [desc, setDesc] = useState('');
    const [success, setSuccess] = useState(false);

    const handleReport = () => {
        if (desc) {
            axios.post('/user/report', { from_username: props.user.username, about_id: props.postUser.id, about_username: props.postUser.username, text: desc }).then(res => {
                toast.success('Report sent!');
                setSuccess(true);
                setTimeout(() => {
                    props.handleClose();
                }, [2000]);
            });
            // props.report_user({ from_username: props.user.username, about_id: props.postUser.id, about_username: props.postUser.username, text: desc });
        }
    }

    return (
        <div className="report-user">
            <div className="overlay" onClick={() => props.handleClose()}></div>
            <div className="modal">
                <div className="head">
                    <div className="user">
                        <h5>Report user</h5>
                    </div>
                    <img style={{ width: '15px' }} onClick={() => props.handleClose()} src={CloseIcon} alt="close" />
                </div>
                <div className="body">
                    <textarea onChange={(e) => setDesc(e.target.value)} placeholder="Enter description"></textarea>
                    <button style={{ ...props.btnStyle, backgroundColor: `${success ? '#71d93d' : 'rgba(2, 71, 245, 0.768627451)'}` }} onClick={() => handleReport()}>{success ? 'submitted' : 'submit report'}</button>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    user: state.user.data
});

const mapDispatchToProps = (dispatch) => ({
    report_user: (data) => dispatch(report_to_admin(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportUser);