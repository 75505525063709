import React, { useState } from "react";
import Image from "../../assets/images/login.jpg";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { Navigate } from "react-router-dom";
import { set_title } from "../../assets/utils/title";
import { login_user, set_user_func } from "../../store/actions/users";
import { connect } from "react-redux";
import { set_notification } from "../../store/actions/notification";
import axios from "axios";

const SignIn = (props) => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [redirect, setRedirect] = useState(false);

    set_title('Sign In');

    const handleSubmit = (event) => {
        event.preventDefault();
        if (!email) toast.warn('Email address is required!');
        else if (!password) toast.warn('Password is required!');
        else {
            axios.post('/user/login', { email, password }).then(res => {
                props.set_user(res.data.user);
                window.location.reload();
            }).catch(error => {
                toast.warn(error.response.data.errors.message);
            });
        }
    }

    return (
        <div>
            {redirect && <Navigate to='/' />}
            {props.user && props.user.id ? (
                <Navigate to='/' />
            ) : (
                <>
                    {sessionStorage.getItem('super_user') ? (
                        <Navigate to='/admin/login' />
                    ) : (
                        <div className="form-area">
                            <div className="image">
                                <img src={Image} alt="account" />
                            </div>
                            <form onSubmit={handleSubmit}>
                                <h4>SignIn Account</h4>
                                <section>
                                    <input type="email" onChange={(e) => setEmail(e.target.value)} placeholder="Enter email address" />
                                    <span>Enter account email address</span>
                                </section>
                                <section>
                                    <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="Enter password" />
                                    <span>Enter account password</span>
                                </section>
                                <div className="bottom">
                                    {/* <p><Link to='/signup' style={{ textDecoration: 'none', color: '#000' }} >I haven't accoutn | Sign up</Link></p>
                                    <p>Forget password</p> */}
                                    <p><a href='https://happyfacess.com/register' style={{ textDecoration: 'none', color: '#000' }} >I haven't accoutn | Sign up</a></p>
                                    <p><a href='https://happyfacess.com/login' style={{ textDecoration: 'none', color: '#000', color: 'blue' }} >&nbsp; | Forget password</a></p>
                                </div>
                                <section>
                                    <button onClick={handleSubmit} type="submit">Sign In</button>
                                </section>
                            </form>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

const mapStateToProps = (state) => ({
    notify: state.notify.data,
    user: state.user.data
})

const mapDispatchToProps = (dispatch) => ({
    login_user: (data) => dispatch(login_user(data)),
    setNotification: (data) => dispatch(set_notification(data)),
    set_user: (data) => dispatch(set_user_func(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);