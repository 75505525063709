import { ReactComponent as FriendsIcon } from '../images/friends_nav.svg';
import { ReactComponent as CommentIcon } from '../images/chat_nav.svg';
import { ReactComponent as SearchIcon } from '../images/hashtag.svg';
import { ReactComponent as PeopleIcon } from '../images/groups_nav.svg';
import { ReactComponent as RequestIcon } from '../images/add-user.svg';

export const nav_links = [
    {
        name: 'Chat',
        path: '/chat',
        image: <CommentIcon />,
        classes: 'nav_item'
    },
    {
        name: 'Explore',
        path: '/explore',
        image: <SearchIcon />,
        classes: 'nav_item'
    },
    {
        name: 'Groups',
        path: '/groups',
        image: <PeopleIcon />,
        classes: 'nav_item'
    },
    {
        name: 'Requests',
        path: '/requests',
        image: <RequestIcon />,
        classes: 'nav_item'
    },
    {
        name: 'Friends',
        path: '/friends',
        image: <FriendsIcon />,
        classes: 'nav_item'
    }
]
