import React, { useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import MenuDots from "../../assets/images/menu-dots.svg";
import TrashIcon from "../../assets/images/trash.svg";
import ReportIcon from "../../assets/images/remove-user.svg";
import AnonymousProfile from "../../assets/images/anonymous.svg";
import axios from "axios";
import { format } from "timeago.js";
import SubReplies from "./SubReplies";
import TextareaAutosize from "react-textarea-autosize";
import { toast } from "react-toastify";
import Content from "./Content";
import { socket } from "../../App";
import { filesUrl } from "../../App";
import ReportUser from "../ReportUser";

const Replies = (props) => {
    const [replies, setReplies] = useState([]);
    const [replieCount, setRepliesCount] = useState(0);
    const [openReply, setOpenReply] = useState(null);
    const [subReply, setSubReply] = useState("");
    const [publicReply, setPublicReply] = useState(true);
    const [repliesLength, setRepliesLength] = useState(0)
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [openReportUser, setOpenReportUser] = useState(null);
    const [openReport, setOpenreport] = useState({});

    const line_none = {
        textDecorationLine: "none",
        color: "black",
        display: "flex",
        alignItems: "center",
    };

    const handleSubReply = (reply_id, reply_user_id, reply_public, reply_username) => {
        if (subReply.length > 0 && props.user && props.user.id) {
            axios.post(`/posts/sub-replies/${reply_id}`, {
                post_id: props.post_id,
                comment_id: props.com.id,
                reply: subReply,
                public: publicReply,
                from_username: props.user.username,
                reply_user: reply_user_id,
                reply_public,
                reply_username,
            }).then((res) => {
                setOpenReply(null);
                setSubReply("");
                if (res.data.reply) {
                    const newReplyArr = [...replies];
                    const replyIndex = newReplyArr.findIndex((repl_index) => repl_index.id == reply_id);
                    if (replyIndex !== -1) {
                        newReplyArr[replyIndex].replies.unshift(res.data.reply);
                        newReplyArr[replyIndex].replies_length++
                        setReplies([...newReplyArr]);
                    }
                    socket.emit("send-sub-reply", { notification: res.data.notification, reply: res.data.reply, });
                }
                setPublicReply(true);
            }).catch((error) => {
                setPublicReply(true);
                toast.warn('Request timed out!');
            });
        }
    };

    const handleOpenReply = (reply_id) => {
        if (openReply) setOpenReply(null);
        else setOpenReply(reply_id);
    };

    const handleGetReplies = () => {
        axios.get(`/posts/replies/${props.commentId}?count=${replies.length == 0 ? 0 : (replies.length == 1 ? 1 : replieCount)}`).then((res) => {
            props.getMoreReplies([...res.data.replies], props.commentId);
            setRepliesCount(replieCount + 3);
            setRepliesLength(res.data.number_of_replies);
            setReplies([...replies, ...res.data.replies]);
        });
    };

    const handleClickReplyBtn = () => {
        setReplies(replies);
    }

    const getMoreSubReplies = (sub_replies, reply_id) => {
        const newReplyArr = [...replies]
        const replyIndex = replies.findIndex(repl => repl.id == reply_id);
        if (replyIndex !== -1) {
            newReplyArr[replyIndex].replies = [...sub_replies];
            setReplies([...newReplyArr]);
        }
    }

    const handleRemoveReply = (comment_id, reply_id) => {
        props.handleRemoveReply(comment_id, reply_id);
        const newRepliesArr = [...replies];
        const index = newRepliesArr.findIndex(repl => repl.id == reply_id);
        if (index !== -1) {
            setRepliesLength(prev => prev - 1);
            newRepliesArr.splice(index, 1);
            setReplies([...newRepliesArr]);
        }
    }


    const handleSubRemoveReply = (reply_id, sub_reply_id) => {
        const newRepliesArr = [...replies];
        const index = newRepliesArr.findIndex(repl => repl.id == reply_id);
        if (index !== -1) {
            const sub_reply_index = newRepliesArr[index].replies.findIndex(repl => repl == sub_reply_id);
            newRepliesArr[index].replies.splice(sub_reply_index, 1);
            setReplies([...newRepliesArr]);
        }
    }

    useEffect(() => {
        socket.on('recive-reply', (data) => {
            if (data.post_id == props.post_id) {
                if (props.user && props.user.id) {
                    if (props.user.id !== data.user.id) {
                        const newReplyArr = [...replies];
                        if (!newReplyArr.some((repl) => repl.id == data.reply_id)) {
                            if (window.location.pathname !== `/post/${props.post_id}`) {
                                newReplyArr.unshift(data);
                                setReplies([...newReplyArr]);
                            }
                        }
                    } else {
                        const newReplyArr = [...replies];
                        if (!newReplyArr.some((repl) => repl.id == data.reply_id)) {
                            if (window.location.pathname !== `/post/${props.post_id}`) {
                                newReplyArr.unshift(data);
                                setReplies([...newReplyArr]);
                            }
                        }
                    }
                }
            }
        });
    }, []);

    useEffect(() => {
        if (props.replies) {
            setReplies([...props.replies]);
        }
    }, [props.replies]);

    useEffect(() => {
        setRepliesLength(props.repliesLength);
    }, [props.repliesLength]);

    return (
        <div>
            <div className="replies">
                <ul>
                    {replies.map((reply, index) => (
                        <li style={{ borderLeft: '1px solid #c4c4c4', marginLeft: '-25px', paddingLeft: '10px' }} key={index}>
                            {/* {openReport.reply_id == reply.id && openReport.post_id == props.post_id && (
                                <ReportUser postUser={props.post && props.post.user} handleClose={() => setOpenReportUser(null)} />
                            )} */}
                            {/* <div className="line"></div> */}
                            <div className="repl">
                                <div
                                    style={{ marginBottom: "10px" }}
                                    className="user"
                                >
                                    {reply.public ? (
                                        <section onClick={() => props.get_profile(reply.user.id)}>
                                            <Link style={line_none} to={`/user/${reply.user.id}`}>
                                                <div className="img" style={{ backgroundImage: `url(${reply.user.image ? reply.user.image : filesUrl + '/static/user/default.png'})`, }} ></div>
                                                <h5 className="underline-hover">
                                                    &nbsp;&nbsp;&nbsp;
                                                    {reply.user.username}
                                                </h5>
                                            </Link>
                                        </section>
                                    ) : (
                                        <section>
                                            <Link style={{ ...line_none, cursor: "default", }} to="#">
                                                <div className="img" style={{ backgroundImage: `url(${AnonymousProfile})`, }}></div>
                                                <h5 style={{ textDecorationLine: "none", }}>
                                                    &nbsp;&nbsp;&nbsp;Anonymous
                                                </h5>
                                            </Link>
                                        </section>
                                    )}
                                    <div className="menu-area">
                                        <img onClick={() => props.handleReplyMenu(reply.id)} className="icon" src={MenuDots} alt="menu" />
                                        <div className={props.openReplyMenu == reply.id ? "menu show-menu" : "menu"}>
                                            <section>
                                                {props.user && reply.user.id !== props.user.id && (
                                                    <span style={{ padding: "10px", }} onClick={() => {
                                                        props.setOpenReplyReport();
                                                        props.setReportUser(props.post_id);
                                                        props.setReportUserData(reply.user);
                                                        props.setOpenReplyMenu(null);
                                                    }}>
                                                        <img src={ReportIcon} alt="report" />
                                                        <h6>Report user</h6>
                                                    </span>
                                                )}
                                                {props.user &&
                                                    (reply.user.id == props.user.id || props.user.is_admin) && (
                                                        <>
                                                            <span style={{ padding: "10px", }} onClick={() => {
                                                                const anwser = window.confirm('Are you sure, This will remove the reply');
                                                                if (anwser) {
                                                                    handleRemoveReply(props.com.id, reply.id)
                                                                }
                                                            }}>
                                                                <img src={TrashIcon} alt="delete" />
                                                                <h6 style={{ fontSize: '0.7rem' }}>
                                                                    Remove reply
                                                                </h6>
                                                            </span>
                                                        </>
                                                    )}
                                            </section>
                                        </div>
                                    </div>
                                </div>
                                <div className="repl-text">
                                    <Content newStyle={{ width: "fit-content" }} >
                                        {reply.reply}
                                    </Content>
                                    <div style={{ display: "flex" }}>
                                        <span style={{ fontSize: "0.7rem", fontWeight: "400", }}>
                                            {format(reply.created_at)}
                                        </span>
                                        {props.user && props.user.id && (
                                            <>
                                                {reply.posting ? (
                                                    <span style={{ fontSize: "0.7rem", }}>
                                                        posting...
                                                    </span>
                                                ) : (
                                                    <h6 onClick={() => setOpenReply(reply.id)}>
                                                        Reply
                                                    </h6>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    {openReply == reply.id && (
                                        <>
                                            {props.user && props.user.id ? (
                                                <>
                                                    {!props.user.active ? (
                                                        <div
                                                            style={{ width: "calc(100% - 35px)", }} className="reply">
                                                            <div className="add-reply">
                                                                <form onSubmit={(event) => event.preventDefault()} className="add-comment" style={{ position: "relative", }}>
                                                                    <div className="user-img" style={{ backgroundImage: `url(${props.user && props.user.image ? props.user.image : filesUrl + '/static/user/default.png'})`, }}></div>
                                                                    <TextareaAutosize disabled placeholder="Account Temporarily Blocked" />
                                                                </form>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div style={{ width: "calc(100% - 35px)", }} className="reply" >
                                                            <div className="add-reply">
                                                                <form onSubmit={(event) => event.preventDefault()} className="add-comment" style={{ position: "relative", }}>
                                                                    <div className="user-img" style={{ backgroundImage: `url(${props.user && props.user.image ? props.user.image : filesUrl + '/static/user/default.png'})`, backgroundSize: "cover", backgroundPosition: "center", backgroundRepeat: "no-repeat", }}></div>
                                                                    <TextareaAutosize onChange={(e) => setSubReply(e.target.value)} value={subReply} placeholder="Type here..." />
                                                                </form>
                                                                <div className="comment-action">
                                                                    {subReply.length > 0 && (
                                                                        <>
                                                                            <div className="checkbox">
                                                                                <label htmlFor="anony">
                                                                                    <h5>
                                                                                        reply
                                                                                        Anonymously
                                                                                    </h5>
                                                                                </label>
                                                                                <input onChange={(e) => setPublicReply(!e.target.checked)} id="anony" type="checkbox" />
                                                                            </div>
                                                                            <button onClick={() => handleSubReply(reply.id, reply.user.id, reply.public, reply.user.username)}>
                                                                                reply
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                <div
                                                    style={{
                                                        width: "calc(100% - 35px)",
                                                    }}
                                                    className="reply"
                                                >
                                                    <div className="add-reply">
                                                        <form
                                                            onSubmit={(event) =>
                                                                event.preventDefault()
                                                            }
                                                            className="add-comment"
                                                            style={{
                                                                position:
                                                                    "relative",
                                                            }}
                                                        >
                                                            <div
                                                                className="user-img"
                                                                style={{
                                                                    backgroundImage: `url(${props.user &&
                                                                        props
                                                                            .user
                                                                            .image
                                                                        ? props
                                                                            .user
                                                                            .image
                                                                        : filesUrl + '/static/user/default.png'
                                                                        })`,
                                                                }}
                                                            ></div>
                                                            <TextareaAutosize
                                                                disabled
                                                                placeholder="Please login to reply"
                                                            />
                                                        </form>
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )}
                                    <SubReplies
                                        post_id={props.post_id}
                                        replies_length={reply.replies_length}
                                        comment_id={props.com.id}
                                        reply_id={reply.id}
                                        reply_user_id={reply.user.id}
                                        replies={reply.replies}
                                        setOpenReplyMenu={props.setOpenReplyMenu}
                                        com={props.com}
                                        handleReplyMenu={props.handleReplyMenu}
                                        commentId={props.commentId}
                                        openReplyMenu={props.openReplyMenu}
                                        handleRemoveReply={handleSubRemoveReply}
                                        handleGoToChat={props.handleGoToChat}
                                        get_profile={props.get_profile}
                                        user={props.user}
                                        repliesLength={props.repliesLength}
                                        getMoreSubReplies={getMoreSubReplies}
                                        setOpenReplyReport={props.setOpenReplyReport}
                                        setReportUserData={props.setReportUserData}
                                        setReportUser={props.setReportUser}
                                    />
                                </div>
                            </div>
                        </li>
                    ))}
                    {repliesLength > replies.length && (
                        <div>
                            <h5 onClick={() => handleGetReplies()} style={{ color: "#555", fontSize: "0.8rem", marginLeft: "0px", cursor: "pointer", marginTop: "15px", }} className="underline">
                                View more replies
                            </h5>
                        </div>
                    )}
                </ul>
            </div>
        </div>
    );
};

export default Replies;
