import React from "react";
import HumanWorking from "../../assets/images/human-working.svg";

const NotPostsFound = () => {
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img src={HumanWorking} alt="human" style={{ width: '280px' }} />
            <p className="no-feed-text" style={{ fontSize: '0.8rem' }}>Not any group update</p>
        </div>
    )
}

export default NotPostsFound;