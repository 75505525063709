import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import TestImage from "../assets/images/profile2.jpg";
import { get_group_feeds, search_groups, set_group_feed } from "../store/actions/group";
import Posts from "./Post/Posts";
import { Link, useNavigate } from "react-router-dom";
import { ReactComponent as ArrowBack } from "../assets/images/arrow-left-no-border.svg";
import { ReactComponent as AngleIcon } from '../assets/images/angle-down.svg';
import CorrectIcon from "../assets/images/correct.png";
import PostsLoading from "./Loading/PostLoading";
import SearchPageLoading from "./Loading/SearchPageLoading";
import FeedGroupsLoading from "./Loading/FeedGroupsLoading";
import NotPostsFound from "./Post/NotPostsFound";
import NewUpdates from "./Post/NewUpdates";
import { socket } from "../App";
import { filesUrl } from "../App";
import axios from "axios";

const Feed = (props) => {

    const [searchtxt, setSearchtxt] = useState('');
    const [openres, setOpenres] = useState(false);
    const [haveUpdates, setHaveUpdates] = useState(false);
    const [newUpdatesData, setNewUpdatesData] = useState([]);
    const [groups, setGroups] = useState(null);
    const [currentPage, setCurrentPage] = useState(0);
    const [numberOfGroups, setNumberOfGroups] = useState(0);
    const resultsRef = useRef();
    const scroll = useRef();

    const get_groups = () => {
        axios.get(`/group?page=${currentPage}`).then(res => {
            setCurrentPage(prev => prev + 10);
            setNumberOfGroups(res.data.number_of_groups);
            if (groups) setGroups([...groups, ...res.data.results])
            else setGroups([...res.data.results])
        });
    }

    useEffect(() => {
        props.get_group_feeds(props.user.id);
    }, []);

    useEffect(() => {
        props.search_groups(searchtxt);
        if (searchtxt.length > 0) setOpenres(true);
    }, [searchtxt]);

    const navigate = useNavigate();

    const resetPage = () => {
        props.set_group_feed(null);
        props.get_group_feeds(props.user.id);
        scroll.current?.scrollIntoView({ behavior: "smooth" });
    }

    const go_back = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            navigate('/', { replace: true });
        }
    }

    const handleGroupSearch = (event) => {
        event.preventDefault();
        if (searchtxt) {
            props.search_groups(searchtxt);
        }
    }

    useEffect(() => {

        const handleClickOutside = (event) => {
            if (!resultsRef.current.contains(event.target)) setOpenres(false);
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => document.removeEventListener('mousedown', handleClickOutside);

    }, []);

    useEffect(() => {
        get_groups();
    }, []);

    socket.on('recive-group-post', (data) => {
        if (props.user && props.user.id) {
            if (data.user.id !== props.user.id) setHaveUpdates(true);
        } else setHaveUpdates(true);
    });

    return (
        <div className="groups-feed-area">
            <NewUpdates resetPage={resetPage} data={newUpdatesData} haveUpdates={haveUpdates} setHaveUpdates={() => setHaveUpdates(false)} />
            <div className="groups-feed">
                <div className="sidebar-feed">
                    <form onSubmit={handleGroupSearch} style={{ top: '-20px' }} className="head">
                        <ArrowBack onClick={() => go_back()} />
                        <input onChange={(e) => {
                            setSearchtxt(e.target.value)
                            props.search_groups(e.target.value)
                        }} type="text" placeholder="search groups" />
                        <div ref={resultsRef} className={openres ? 'results results-active' : 'results'}>
                            <p>Search groups</p>
                            <ul>
                                {props.search && props.search !== null ? (
                                    <>
                                        {props.search.length > 0 ? (
                                            <>
                                                {props.search.map((group, key) => (
                                                    <Link key={key} style={{ color: '#444', textDecorationLine: 'none' }} to={`/groups/${group.id}`}>
                                                        <li>
                                                            <section>
                                                                <div className="img" style={{ backgroundImage: `url(${group.image ? group.image : filesUrl + '/static/user/default.png'})` }}></div>
                                                                <h5>{group.name}</h5>
                                                            </section>
                                                        </li>
                                                    </Link>
                                                ))}
                                            </>
                                        ) : (
                                            <>
                                                {searchtxt.length > 0 ? (
                                                    <span></span>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </>
                                        )}
                                    </>
                                ) : (
                                    <span>Loading...</span>
                                )}
                            </ul>
                        </div>
                    </form>
                    <div>
                        <div className="group-list">
                            {groups !== null ? (
                                <>
                                    {groups.length > 0 ? (
                                        <ul style={{ overflowY: 'auto' }}>
                                            {groups.map((group, key) => (
                                                <Link key={key} style={{ textDecorationLine: 'none', color: '#444', height: '70px' }} to={`/groups/${group.id}`}>
                                                    <li>
                                                        <div className="img" style={{ backgroundImage: `url(${group.image})` }}></div>
                                                        <section>
                                                            <h5>{group.name.length > 25 ? `${group.name.substr(0, 25)}...` : group.name}</h5>
                                                            <p>
                                                                {group.members_length > 1 ? group.members_length + ' members' : group.members_length + ' member'}
                                                            </p>
                                                        </section>
                                                    </li>
                                                </Link>
                                            ))}
                                            {groups.length < numberOfGroups ? (
                                                <Link style={{ textDecorationLine: 'none', color: '#444', height: '70px', paddingBottom: '15px' }} to="#">
                                                    <li onClick={() => get_groups()} style={{ display: 'flex', alignItems: 'center' }}>
                                                        <div className="angle-icon">
                                                            <AngleIcon />
                                                        </div>
                                                        <h5 style={{ marginLeft: '10px' }}>see more</h5>
                                                    </li>
                                                </Link>
                                            ) : ''}
                                        </ul>
                                    ) : (
                                        <div style={{ marginLeft: '-40px' }}>
                                            <FeedGroupsLoading enableAnimation={false} />
                                        </div>
                                    )}
                                </>
                            ) : (
                                <div style={{ marginLeft: '-40px' }}>
                                    <FeedGroupsLoading enableAnimation={true} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div ref={scroll} className="posts-area">
                    {props.posts !== null ? (
                        <>
                            {props.posts.length > 0 ? (
                                <div className="group-posts">
                                    <Posts posts={props.posts} />
                                </div>
                            ) : (
                                <NotPostsFound />
                            )}
                        </>
                    ) : (
                        <PostsLoading />
                    )}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    posts: state.group_feed.data,
    groups: state.groups.data,
    search: state.search.search_groups,
    user: state.user.data
});

const mapDispatchToProps = (dispatch) => ({
    get_group_feeds: (user_id) => dispatch(get_group_feeds(user_id)),
    search_groups: (text) => dispatch(search_groups(text)),
    set_group_feed: (data) => dispatch(set_group_feed(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Feed);